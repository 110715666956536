<template>
  <div>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Registrasi Ulang</v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-container fluid class="pa-10">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row align="center">
            <v-col cols="12">
              <v-select
                v-model="filterJalur"
                :items="jalurItems"
                :rules="rules"
                dense
                item-value="id"
                item-text="NAME"
                label="Pilih Jalur"
                v-on:change="jalurChange"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="filterSekolah"
                :items="sekolahItems"
                :rules="rules"
                dense
                item-value="SCHOOL_ID"
                item-text="NAME"
                label="Pilih Sekolah"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <div class="text-center">
                <v-btn color="success" :disabled="!valid" @click="submit">
                  <v-icon left dark>mdi-text-search</v-icon>
                  Check
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
    <br />
    <v-data-table
      :headers="headers"
      :items="admission"
      class="elevation-1"
      item-key="name"
      :search="search"
    >
      <template v-slot:item.nomor="{ item }">
        {{ admission.indexOf(item) + 1 }}
      </template>
      <!-- <template v-slot:item.buktiregistrasi="{ item }">
      <v-btn
        x-small
        class="mr-1"
        rounded
        color="info"
        dark
        @click="reqList(item)"
      >
      <v-icon left>mdi-printer</v-icon>
        Bukti Registrasi Ulang
      </v-btn>
    </template> -->
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Peserta Didik</v-toolbar-title>
          </v-toolbar>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <!-- <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                <v-icon>mdi-text-search</v-icon>
              </v-btn>
            </template> -->
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-form ref="form" v-model="valid" lazy-validation>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="noreg"
                          :rules="rules"
                          label="Nomor Pendaftaran"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Batal
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    :disabled="!valid"
                    text
                    @click="save"
                  >
                    Cek
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Anda yakin ingin menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Batal</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-text-field v-model="search" label="Cari" class="mx-4"></v-text-field>
      </template>
      <template v-slot:item.actions="{}">
        <!-- <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
      <template v-slot:no-data> Tidak ada data </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "RegisUlang",
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    menu2: false,
    dialog: false,
    dialogDelete: false,
    search: "",
    noreg: "",
    valid: true,
    jalurItems: [],
    sekolahItems: [],
    filterJalur: null,
    filterSekolah: null,
    headers: [
      { text: "No", value: "nomor" },
      {
        text: "Nomor Pendaftaran",
        align: "left",
        value: "REGNUMBER",
      },
      { text: "Peserta Didik", value: "student.NAME" },
      // { text: "Bukti Registrasi", value: "buktiregistrasi" },
    ],
    admission: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    statusItems: [
      { id: 1, name: "Dibuka" },
      { id: 0, name: "Ditutup" },
    ],
    rules: [(v) => !!v || "Form ini wajib di isi"],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Cek Data Peserta Didik" : "Edit Data";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
    }),

    initialize() {
      this.getJalur();
    },

    // getRegistrationList() {
    //   this.$vloading.show();
    //   let config = {
    //     headers: {
    //       Authorization: "Bearer " + this.user.token,
    //     },
    //   };
    //   this.axios.post("/api/registrationList", {}, config).then((response) => {
    //     this.admission = response.data.data;
    //     //console.log(this.info);
    //     this.$vloading.hide();
    //   });
    // },

    getJalur() {
      this.$vloading.show();
      let config = {
        headers: {},
      };
      this.axios.post("/api/getAdmission", {}, config).then((response) => {
        this.jalurItems = response.data;
        // console.log(this.jalurItems);
        this.$vloading.hide();
      });
    },
    getSekolah(e) {
      this.$vloading.show();
      let config = {
        headers: {},
      };
      let formData = new FormData();
      formData.set("ADMISSIONID", e);
      this.axios
        .post("/api/getActiveSchool", formData, config)
        .then((response) => {
          this.sekolahItems = response.data.data;
          // console.log(this.sekolahItems);
          this.$vloading.hide();
        });
    },
    jalurChange: function (e) {
      // console.log(e);
      this.getSekolah(e);
    },

    editItem(item) {
      this.editedIndex = this.admission.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.admission.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      // console.log("deleted data", this.editedItem.id);
      // this.admission.splice(this.editedIndex, 1);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post("/api/destroyBank/" + this.editedItem.id, {}, config)
        .then((response) => {
          // let { data } = response.data;
          //this.setAuth(data);
          console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Data Berhasil Dihapus!",
          });
          this.close();
          this.getRegistrationList();
        })
        .catch((error) => {
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data,
          });
          this.close();
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.$vloading.show();
        let config = {
          headers: { Authorization: "Bearer " + this.user.token },
        };
        let formData = new FormData();
        formData.set("npsn", this.filterSekolah);
        formData.set("admission", this.filterJalur);
        this.axios
          .post("/api/registrationList", formData, config)
          .then((response) => {
            this.admission = response.data.data;
            //console.log(this.info);
            this.$vloading.hide();
          })
          .catch((error) => {
            console.log(error.response.data);
            this.$vloading.hide();
            this.admission = [];
            this.status = [];
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.message,
            });
            this.close();
          });
      }
      this.closes();
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          //   // UPDATE DATA
          //   // console.log("edited data");
          //   // Object.assign(this.admission[this.editedIndex], this.editedItem);
          //   let config = {
          //     headers: {
          //       Authorization: "Bearer " + this.user.token,
          //     },
          //   };
          //   let formData = new FormData();
          //   formData.set("NAME", this.editedItem.NAME);
          //   formData.set("STATUS", this.editedItem.STATUS);
          //   // console.log(formData.getAll("STATUS"));
          //   this.axios
          //     .post("/api/saveBank/"+this.editedItem.id, formData, config)
          //     .then(() => {
          //       // let { data } = response.data;
          //       //this.setAuth(data);
          //       // console.log(response);
          //       this.setAlert({
          //         status: true,
          //         color: "success",
          //         text: "Data Berhasil Diubah!",
          //       });
          //       this.close();
          //       this.getRegistrationList();
          //     })
          //     .catch((error) => {
          //       let responses = error;
          //       // console.log(responses);
          //       // let { data } = error.response;
          //       this.setAlert({
          //         status: true,
          //         color: "error",
          //         text: responses.data.errors,
          //       });
          //     });
        } else {
          // CREATE DATA
          // console.log("created data");
          // this.admission.push(this.editedItem);
          // console.log(this.editedItem.STATUS);
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.token,
            },
          };
          let formData = new FormData();
          formData.set("noreg", this.noreg);
          this.axios
            .post("/api/registrationCheck", formData, config)
            .then(() => {
              // let { data } = response.data;
              //this.setAuth(data);
              // console.log(response);
              this.setAlert({
                status: true,
                color: "success",
                text: "Nomor Pendaftaran Ditemukan",
              });
              this.close();
              // this.getRegistrationList();
              localStorage.noreg = this.noreg;
              this.$router.replace({ name: "Regis Check" });
            })
            .catch((error) => {
              // let responses = error;
              // console.log(responses);
              // let { data } = error.response;
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data.message,
              });
            });
        }
        this.closes();
      }
    },

    reqList(item) {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("noreg", item.REGNUMBER);
      this.axios
        .post("/api/registrationCheck", formData, config)
        .then(() => {
          // let { data } = response.data;
          //this.setAuth(data);
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Nomor Pendaftaran Ditemukan",
          });
          this.close();
          // this.getRegistrationList();
          localStorage.noreg = this.noreg;
          this.$router.replace({ name: "Regis Check" });
        })
        .catch(() => {
          // let responses = error;
          // console.log(responses);
          // let { data } = error.response;
          // this.setAlert({
          //   status: true,
          //   color: "error",
          //   text: error.response.data.message,
          // });
          localStorage.noreg = item.REGNUMBER;
          this.$router.replace({ name: "Regis Check" });
        });
    },

    closes() {
      this.$emit("closed", false);
    },
  },
};
</script>