<template>
  <div>
    <v-container class="ma-0 pa-1" grid-list-sm>
      <div class="text-left">
        <v-btn small text to="/pilih-jalur" class="blue--text">
          <v-icon>mdi-chevron-left</v-icon> Kembali
        </v-btn>
      </div>
    </v-container>
    <v-data-table
      :headers="headers"
      :items="requirements"
      sort-by="calories"
      class="elevation-1"
    >
      <!-- <template #item.NAME="{ value }">
        <a target="_blank" href="http://103.26.13.16:8181/api/getFile/0057534901/1">
          {{ value }}
        </a>
      </template> -->
      <template v-slot:item.nomor="{ item }">
        {{ requirements.indexOf(item) + 1 }}
      </template>
      <template #item.requirement_check="{ item }">
        <span v-for="dt in item.requirement_check" :key="dt.id">
          <v-btn x-small outlined color="primary" dark @click="viewer(dt)">
            Lihat
          </v-btn>
        </span>
      </template>

      <template #item.status="{ item }">
        <span v-for="dt in item.requirement_check" :key="dt.id">
          <v-chip small rounded color="orange" dark v-if="dt.STATUS == 0">
            Proses Verifikasi
          </v-chip>
          <v-chip small rounded color="green" dark v-if="dt.STATUS == 1">
            Diverifikasi
          </v-chip>
          <v-chip small rounded color="error" dark v-if="dt.STATUS == 2">
            Ditolak
          </v-chip>
        </span>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar dark color="primary">
            <v-toolbar-title
              >Upload Persyaratan {{ admissionNAME }}</v-toolbar-title
            >
          </v-toolbar>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="720px">
            <!-- <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                New Item
              </v-btn>
            </template> -->
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <input type="hidden" v-model="editedItem.id" />
                    <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        v-model="editedItem.NAME"
                        label="NAMA"
                        readonly
                        rows="2"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.FILE_TYPE"
                        label="TIPE FILE"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <input
                        type="file"
                        id="file"
                        ref="file"
                        v-on:change="handleFileUpload()"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close"> Batal </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Upload </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <span v-if="item.requirement_check.length == 0">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-cloud-upload
          </v-icon>
        </span>
        <span v-else>
          <span v-for="dt in item.requirement_check" :key="dt.id">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-cloud-upload
            </v-icon>
          </span>
        </span>
        <!-- {{ item.requirement_check.length }} -->
        <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
      <template v-slot:no-data> Tidak ada data </template>
    </v-data-table>

    <!-- PDF Viewer -->
    <v-dialog v-model="dialogViewer" scrollable width="80%">
      <v-card>
        <v-card-title class="primary">
          <span class="headline white--text">Dokumen Persyaratan</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <!-- {{ editedItem }} -->
                <iframe
                  :src="
                    baseUrl +
                    url +
                    user.username +
                    '/documents/' +
                    editedItem.FILE_NAME
                  "
                  width="100%"
                  height="500"
                  frameborder="0"
                ></iframe>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogViewer = false">
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Persyaratan",
  data: () => ({
    baseUrl: process.env.VUE_APP_API_URL,
    url: "/storage/",
    dialog: false,
    dialogDelete: false,
    dialogViewer: false,
    headers: [
      {
        text: "No",
        align: "left",
        sortable: false,
        value: "nomor",
      },
      { text: "NAME", value: "NAME" },
      { text: "FILE", value: "requirement_check" },
      { text: "STATUS", value: "status" },
      { text: "UPLOAD", value: "actions", sortable: false },
    ],
    requirements: [],
    admissionID: localStorage.getItem("admissionID"),
    admissionNAME: localStorage.getItem("admissionNAME"),
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
    }),

    initialize() {
      this.getRequirementList();
    },

    getRequirementList() {
      this.$vloading.show();
      // console.log("getKabkot");
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("admissionid", localStorage.getItem("admissionID"));
      this.axios
        .post("/api/requirementList", formData, config)
        .then((response) => {
          this.$vloading.hide();
          console.log(response.data);
          // let { data } = response.data;
          // console.log(data.requirement_check);
          let { data } = response.data;
          this.requirements = data;
          // let data = response.data.data[3].requirement_check[0].FILE_NAME;
          // console.log(data[0].NAME);
          // console.log(data);
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log("error" + error);
        });
    },

    viewer(item) {
      // this.editedIndex = this.student.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogViewer = true;
    },

    editItem(item) {
      this.editedIndex = this.requirements.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      // console.log("deleted data");
      this.editedIndex = this.requirements.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.requirements.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        this.$vloading.show();
        // console.log("edited data");
        // Object.assign(this.requirements[this.editedIndex], this.editedItem);
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.token,
            "Content-Type": "multipart/form-data",
          },
        };

        let formData = new FormData();
        formData.set("FILE", this.file);
        formData.set("REQUIREMENT_ID", this.editedItem.id);
        // console.log(formData.getAll("FILE"));
        // console.log(formData.getAll("REQUIREMENT_ID"));
        this.axios
          .post("/api/uploadRequirement", formData, config)
          .then(() => {
            this.$vloading.hide();
            // let { data } = response.data;
            // console.log(response.data);
            this.setAlert({
              status: true,
              color: "success",
              text: "Berhasil diupload",
            });
            this.close();
            this.getRequirementList();
          })
          .catch((error) => {
            this.$vloading.hide();
            let responses = error;
            console.log(responses);
            let { data } = error.response;
            // console.log(data.errors.FILE);
            this.setAlert({
              status: true,
              color: "error",
              text: data.errors.FILE,
            });
          });
      } else {
        // console.log("created data");
        this.requirements.push(this.editedItem);
      }
      this.close();
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },

    closes() {
      this.$emit("closed", false);
    },
  },
};
// import { mapActions, mapGetters } from "vuex";
// export default {
//   data() {
//     return {
//       valid: true,
//       simpan: true,
//       file: "",
//       pilihan1: null,
//       pilihan2: null,
//       pilihan3: null,
//       req_id: null,
//       pilihanItems: [{ NPSN: "", NAME: "" }],
//       Rules: [(v) => !!v || "Form ini wajib di isi"],
//       pilRules: [(v) => !!v || "Form ini wajib di isi"],
//       req: [],
//     };
//   },
//   mounted() {
//     this.getRequirementList();
//   },
//   computed: {
//     ...mapGetters({
//       user: "auth/user",
//     }),
//     pilConfirmationRule() {
//       return () =>
//         (this.pilihan1 != this.pilihan2 &&
//           this.pilihan1 != this.pilihan3 &&
//           this.pilihan2 != this.pilihan3) ||
//         "Pilihan sekolah tidak bisa sama";
//     },
//   },
//   methods: {
//     ...mapActions({
//       setAlert: "alert/set",
//     }),

//     getRequirementList() {
//       // console.log("getKabkot");
//       let config = {
//         headers: {
//           Authorization: "Bearer " + this.user.token,
//         },
//       };
//       this.axios
//         .post("/api/requirementList", {}, config)
//         .then((response) => {
//           // console.log(response);
//           let { data } = response.data;
//           this.req = data;
//         })
//         .catch((error) => {
//           console.log("error" + error);
//         });
//     },

//     /* Submits the file to the server */
//     submitFile() {
//       /* Initialize the form data */
//       // let formData = new FormData();

//       /* Add the form data we need to submit */
//       // formData.append("file", this.file);
//       // formData.set("FILE", this.file);
//       // console.log(formData.getAll("FILE"));

//       // let config = {
//       //   headers: {
//       //     Authorization: "Bearer " + this.user.token,
//       //     "Content-Type": "multipart/form-data",
//       //   },
//       // };
//       console.log(this.$refs.myTestField.value);

//       let formData = new FormData();
//       formData.set("FILE", this.file);
//       formData.set("REQUIREMENT_ID", this.req_id);
//       // console.log(formData.getAll("FILE"));
//       // console.log(formData.getAll("REQUIREMENT_ID"));
//       // this.axios
//       //   .post("/api/uploadRequirement", formData, config)
//       //   .then((response) => {
//       //     // let { data } = response.data;
//       //     //this.setAuth(data);
//       //     console.log(response.data);
//       //     this.setAlert({
//       //       status: true,
//       //       color: "success",
//       //       text: "Berhasil disimpan",
//       //     });
//       //     this.close();
//       //     // this.getSelectedSekolah();
//       //     // this.$router.replace({ name: "register" });
//       //   })
//       //   .catch((error) => {
//       //     let responses = error;
//       //     console.log(responses);
//       //     //let { data } = error.response;
//       //     this.setAlert({
//       //       status: true,
//       //       color: "error",
//       //       // text: responses.data.message,
//       //       text: responses,
//       //     });
//       //   });

//       /* Make the request to the POST /single-file URL */
//       // this.axios
//       //   .post("/single-file", formData, {
//       //     headers: {
//       //       "Content-Type": "multipart/form-data",
//       //     },
//       //   })
//       //   .then(function () {
//       //     console.log("SUCCESS!!");
//       //   })
//       //   .catch(function () {
//       //     console.log("FAILURE!!");
//       //   });
//     },

//     /* Handles a change on the file upload */
//     handleFileUpload() {
//       this.file = this.$refs.file.files[0];
//     },

//     close() {
//       this.$emit("closed", false);
//     },
//   },
// };
</script>