<template>
  <div>
    <v-container class="ma-0 pa-1" grid-list-sm>
      <div class="text-left">
        <v-btn small text to="/" class="blue--text">
          <v-icon>mdi-chevron-left</v-icon> Kembali
        </v-btn>
      </div>
    </v-container>
    <v-card elevation="1" class="pa-3">
      <div id="print" v-if="peserta.student[0].school_selected.length != 0">
        <center>
          <table
            border="0"
            width="100%"
            style="
              border-collapse: collapse;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 11px;
            "
            align="center"
            cellpadding="5px"
          >
            <tr>
              <td style="padding: 10px; width: 15%" align="center">
                <img height="60px" width="60px" src="/img/bg/kota.png" />
              </td>
              <td style="font-size: 12px">
                <strong>PANITIA PENERIMAAN PESERTA DIDIK BARU</strong><br />
                SEKOLAH DASAR – SEKOLAH MENENGAH PERTAMA<br />
                <strong>KOTA GORONTALO TP. 2024 – 2025</strong>
              </td>
              <td style="padding-right: 5px; color: gray" align="right">
                <small
                  ><i
                    >Dokumen ini <br />resmi dicetak melalui<br />
                    https://ppdb.gorontalokota.go.id</i
                  ></small
                >
              </td>
            </tr>
          </table>

          <table
            border="1"
            width="100%"
            style="
              border-collapse: collapse;
              border-color: black;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 11px;
            "
            align="center"
            cellpadding="5px"
          >
            <tr>
              <th style="font-size: 12px; padding: 5px">BUKTI PENDAFTARAN</th>
            </tr>
          </table>

          <table
            border="1"
            width="100%"
            style="
              border-collapse: collapse;
              margin-top: 5px;
              border-color: black;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 11px;
            "
            align="center"
            cellpadding="5px"
          >
            <tr>
              <td colspan="2" style="padding: 5px">
                <strong>Info Verifikasi Pendaftaran</strong>
              </td>
            </tr>
            <tr>
              <td style="padding: 5px; background-color: #dddddd" width="30%">
                Nomor Pendaftaran
              </td>
              <td style="padding: 5px">{{ peserta.REGNUMBER }}</td>
            </tr>
            <tr>
              <td style="padding: 5px; background-color: #dddddd">
                Jalur Masuk
              </td>
              <td style="padding: 5px">{{ admissionNAME }}</td>
            </tr>
          </table>

          <table
            border="1"
            width="100%"
            style="
              border-collapse: collapse;
              margin-top: 5px;
              border-color: black;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 11px;
            "
            align="center"
            cellpadding="5px"
          >
            <tr>
              <td colspan="2" style="padding: 5px">
                <strong>Biodata Siswa</strong>
              </td>
            </tr>
            <tr>
              <td style="padding: 5px; background-color: #dddddd" width="30%">
                NISN
              </td>
              <td style="padding: 5px">{{ peserta.student[0].NISN }}</td>
            </tr>
            <tr>
              <td style="padding: 5px; background-color: #dddddd">
                Nama Lengkap
              </td>
              <td style="padding: 5px">{{ peserta.student[0].NAME }}</td>
            </tr>
            <tr>
              <td style="padding: 5px; background-color: #dddddd">
                Jenis Kelamin
              </td>
              <td style="padding: 5px">
                <span v-if="peserta.student[0].SEX == 'F'"> Perempuan </span>
                <span v-else> Laki-laki </span>
              </td>
            </tr>
            <tr>
              <td style="padding: 5px; background-color: #dddddd">
                Tempat & Tgl Lahir
              </td>
              <td style="padding: 5px">
                {{ peserta.student[0].BIRTHPLACE }},
                {{ peserta.student[0].DOB }}
              </td>
            </tr>
            <tr>
              <td style="padding: 5px; background-color: #dddddd">Email</td>
              <td style="padding: 5px">{{ this.user.email }}</td>
            </tr>
            <tr>
              <td style="padding: 5px; background-color: #dddddd">Alamat</td>
              <td style="padding: 5px">{{ peserta.student[0].ADDRESS }}</td>
            </tr>
          </table>

          <table
            border="1"
            width="100%"
            style="
              border-collapse: collapse;
              margin-top: 5px;
              border-color: black;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 11px;
            "
            align="center"
            cellpadding="5px"
          >
            <tr>
              <td colspan="2" style="padding: 5px">
                <strong>Daftar Pilihan Sekolah</strong>
              </td>
            </tr>
            <tr>
              <td style="padding: 5px; background-color: #dddddd" width="30%">
                Pilihan 1
              </td>
              <td style="padding: 5px">
                {{ peserta.student[0].school_selected[0].school_data.NAME }}
              </td>
            </tr>
            <tr>
              <td style="padding: 5px; background-color: #dddddd">Pilihan 2</td>
              <td style="padding: 5px">
                {{ peserta.student[0].school_selected[1].school_data.NAME }}
              </td>
            </tr>
            <tr>
              <td style="padding: 5px; background-color: #dddddd">Pilihan 3</td>
              <td style="padding: 5px">
                {{ peserta.student[0].school_selected[2].school_data.NAME }}
              </td>
            </tr>
          </table>

          <table
            border="1"
            width="100%"
            style="
              border-collapse: collapse;
              margin-top: 5px;
              border-color: black;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 11px;
            "
            align="center"
            cellpadding="5px"
          >
            <tr>
              <th style="padding: 10px">
                Telah terdaftar pada Penerimaan Peserta Didik Baru Tahun
                Pelajaran 2024-2025.
              </th>
              <!-- <td style="padding: 5px 5px 5px 15px">
              <strong>Untuk diperhatikan :</strong><br>
              <ol>
                <li>asdasd</li>
                <li>asdasd</li>
                <li>asdasd</li>
              </ol>
            </td> -->
            </tr>
          </table>

          <table
            border="1"
            width="100%"
            style="
              border-collapse: collapse;
              margin-top: 5px;
              border-color: black;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 11px;
            "
            align="center"
            cellpadding="5px"
          >
            <tr>
              <td width="10%" valign="top" style="padding: 5px; color: gray">
                <small>** Harap tulis nama terang dibawah tanda tangan.</small>
              </td>
              <td style="padding: 5px" align="center" width="30%">
                a/n <strong>{{ peserta.student[0].NAME }}</strong
                ><br />
                Menyetujui data diatas,<br />
                <strong>Ortu / Wali Siswa terdaftar **)</strong>
                <br /><br /><br /><br /><br /><br />
                (.................................)
              </td>
              <td width="30%"></td>
              <td style="padding: 5px" align="left" width="30%">
                <div align="right">
                  Kota Gorontalo, {{ new Date().getDate() }}/{{
                    new Date().getMonth() + 1
                  }}/{{ new Date().getFullYear() }}
                </div>
                Menyetujui data diatas,<br />
                <strong>Siswa terdaftar</strong>
                <br /><br /><br /><br /><br /><br />
                <strong>{{ peserta.student[0].NAME }}</strong>
              </td>
            </tr>
          </table>

          <!-- <table
          border="1"
          width="100%"
          style="border-collapse: collapse; margin-top: 5px"
          align="center"
          cellpadding="5px"
        >
          <tr>
            <td>
              <center>
                <table
                  border="1"
                  width="95%"
                  style="border-collapse: collapse; border-color: #ebedef"
                  align="center"
                  cellpadding="5px"
                >
                  <tr>
                    <td width="30%" style="padding: 5px">
                      <b>Nomor Pendaftaran</b>
                    </td>
                    <td style="padding: 5px">{{ peserta[0].REGNUMBER }}</td>
                  </tr>
                  <tr>
                    <td width="30%" style="padding: 5px">
                      <b>Jalur Penerimaan</b>
                    </td>
                    <td style="padding: 5px">
                      {{ peserta[0].ADMISSION_NAME }}
                    </td>
                  </tr>
                  <tr>
                    <td width="30%" style="padding: 5px">
                      <b>Nama Calon Peserta Didik</b>
                    </td>
                    <td style="padding: 5px">{{ peserta[0].NAME }}</td>
                  </tr>
                  <tr>
                    <td width="30%" style="padding: 5px">
                      <b>Tempat, Tanggal Lahir</b>
                    </td>
                    <td style="padding: 5px">
                      {{ peserta[0].BIRTHPLACE }}, {{ peserta[0].DOB }}
                    </td>
                  </tr>
                  <tr>
                    <td width="30%" style="padding: 5px">
                      <b>Alamat</b>
                    </td>
                    <td style="padding: 5px">
                      {{ peserta[0].ADDRESS }}
                    </td>
                  </tr>
                  <tr>
                    <td width="30%" style="padding: 5px">
                      <b>Sekolah Pilihan</b>
                    </td>
                    <td style="padding: 5px 5px 5px 15px">
                      <ul>
                        <span
                          v-for="dt in peserta[0].SCHOOL_SELECTED"
                          :key="dt.id"
                        >
                          <li>{{ dt.SCHOOL_NAME }}</li>
                        </span>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="padding: 5px">
                      <b
                        >Telah menyerahkan dan melakukan verifikasi untuk
                        dokumen-dokumen sebagai berikut:</b
                      >
                    </td>
                  </tr>
                  <tr v-for="dt in peserta[0].REQUIREMENT_LIST" :key="dt.id">
                    <td width="30%" style="padding: 5px">{{ dt.FILE_NAME }}</td>
                    <td style="padding: 5px">
                      Diverifikasi oleh {{ dt.VERIFYBY }} pada
                      {{ dt.updated_at }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="padding: 5px">
                      <b
                        ><small
                          >Dengan demikian kepada calon peserta didik yang
                          bersangkutan berhak meneruskan ke tahapan PPDB
                          selanjutnya.</small
                        ></b
                      >
                    </td>
                  </tr>
                </table>
              </center>
            </td>
          </tr>
        </table> -->
        </center>
      </div>
      <br />
      <center>
        <v-btn
          medium
          color="success"
          dark
          @click="makePDF"
          v-if="peserta.student[0].school_selected.length != 0"
        >
          <v-icon left>mdi-printer</v-icon>
          Cetak
        </v-btn>
        <v-alert color="error" dark prominent width="80%" v-else>
          Silahkan memilih sekolah terlebih dahulu.
        </v-alert>
      </center>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
export default {
  name: "BuktiVerifikasi",
  data: () => ({
    peserta: [],
    admissionID: localStorage.getItem("admissionID"),
    admissionNAME: localStorage.getItem("admissionNAME"),
  }),
  computed: {
    isHome() {
      return this.$route.path === "/";
    },
    ...mapGetters({
      countCart: "cart/count",
      guest: "auth/guest",
      user: "auth/user",
      dialogStatus: "dialog/status",
      currentComponent: "dialog/component",
    }),
    dialog: {
      get() {
        return this.dialogStatus;
      },
      set(value) {
        this.setDialogStatus(value);
      },
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getPendaftaran();
    },

    ...mapActions({
      setDialogStatus: "dialog/setStatus",
      setDialogComponent: "dialog/setComponent",
      setAuth: "auth/set", // <=
      setAlert: "alert/set", // <=
    }),

    getPendaftaran() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("ADMISSIONID", this.admissionID);
      formData.set("USERID", this.user.id);
      this.axios
        .post("/api/document/registered", formData, config)
        .then((response) => {
          this.$vloading.hide();
          let data = response.data.data;
          //   item.samples = data;
          this.peserta = data;
          console.log(data);
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data,
          });
          this.close();
        });
    },

    makePDF() {
      // window.html2canvas = html2canvas;
      // // var w = document.getElementById("print").offsetWidth;
      // // var h = document.getElementById("print").offsetHeight;
      // var doc = new jsPDF("p", "pt", "a4");
      // doc.html(document.getElementById("print"), {
      //   callback: function (pdf) {
      //     pdf.save("mypdf.pdf");
      //   },
      // });
      var prtContent = document.getElementById("print");
      var WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(prtContent.innerHTML);
      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(function() {
        WinPrint.print();
        WinPrint.close();
      }, 500);
    },

    // makePDF() {
    //   var w = document.getElementById("print").offsetWidth;
    //   var h = document.getElementById("print").offsetHeight;
    //   html2canvas(document.getElementById("print"), {
    //     dpi: 300, // Set to 300 DPI
    //     scale: 3, // Adjusts your resolution
    //     onrendered: function () {
    //       var doc = new jsPDF("p", "px", [w, h]);
    //       doc.save("sample-file.pdf");
    //     },
    //   });
    // },
  },
};
</script>

<style scoped>
/* #print {
  font-family: "Arial";
  font-size: 11px;
  margin-top: 10px;
  margin-left: 15px;
  width: 100%;
} */
</style>
