<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="admission"
      class="elevation-1"
      item-key="name"
      :search="search"
    >
      <template v-slot:item.nomor="{ item }">
        {{ admission.indexOf(item) + 1 }}
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Daftar Akun Siswa</v-toolbar-title>
          </v-toolbar>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }" v-if="user.role != 6">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-form ref="form" v-model="valid" lazy-validation>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.username"
                          :rules="usernameRules"
                          type="number"
                          label="Username/NISN"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.email"
                          :rules="rules"
                          label="Email"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Batal
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    :disabled="!valid || loading"
                    text
                    @click="save"
                    :loading="loading"
                  >
                    Simpan
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Anda yakin ingin menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Batal</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogReset" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Reset password user ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeReset"
                  >Batal</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  @click="resetItemConfirm(editedItem.id)"
                  :disabled="loading"
                  :loading="loading"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogResetPass" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Reset password berhasil!</v-card-title
              >
              <v-card-text>
                <h3>Password baru : {{ newpass }}</h3>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeResetPass"
                  >Tutup</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-text-field v-model="search" label="Cari" class="mx-4"></v-text-field>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="resetItem(item)">
          mdi-lock-reset
        </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data> Tidak ada data </template>
    </v-data-table>
    <!-- LOADER -->
    <v-dialog v-model="dialogLoading" persistent width="300">
      <v-card>
        <v-card-text>
          Sedang mengambil data...
          <v-progress-linear
            indeterminate
            color="primary"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "AkunOperator",
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    newpass: "",
    menu: false,
    menu2: false,
    dialog: false,
    dialogDelete: false,
    dialogReset: false,
    dialogResetPass: false,
    dialogLoading: false,
    loading: false,
    valid: true,
    search: "",
    username: "",
    usernameRules: [(v) => (v && v.length >= 6) || "Minimal 6 karakter"],
    showPassword: false,
    password: "",
    passwordRules: [
      (v) => !!v || "Password wajib di isi",
      (v) => (v && v.length >= 8) || "Minimal 8 karakter",
    ],
    showconfPassword: false,
    confpassword: "",
    confpasswordRules: [
      (v) => !!v || "Konfirmasi Password wajib di isi",
      (v) => (v && v.length >= 8) || "Minimal 8 karakter",
    ],
    headers: [
      { text: "No", value: "nomor" },
      {
        text: "Username/NISN",
        align: "left",
        value: "username",
      },
      { text: "Email", value: "email" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    admission: [],
    sekolah: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    statusItems: [
      { id: 1, name: "Dibuka" },
      { id: 0, name: "Ditutup" },
    ],
    rules: [(v) => !!v || "Form ini wajib di isi"],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Data" : "Edit Data";
    },
    passwordConfirmationRule() {
      return () => this.password === this.confpassword || "Password harus sama";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogReset(val) {
      val || this.closeReset();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
    }),

    initialize() {
      this.getAkunSiswa();
    },

    getAkunSiswa() {
      this.dialogLoading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post("/api/auth/studentList", {}, config)
        .then((response) => {
          this.admission = response.data.data;
          console.log(this.admission);
          this.dialogLoading = false;
        })
        .catch((error) => {
          this.dialogLoading = false;
          console.log("error : " + error);
          let { data } = error.response;
          this.setAlert({
            status: true,
            color: "error",
            text: data.message,
          });
        });
    },

    editItem(item) {
      this.editedIndex = this.admission.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.admission.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    resetItem(item) {
      this.editedIndex = this.admission.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogReset = true;
    },

    deleteItemConfirm() {
      // console.log("deleted data", this.editedItem.id);
      // this.admission.splice(this.editedIndex, 1);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post("/api/auth/destroyUser/" + this.editedItem.id, {}, config)
        .then(() => {
          // let { data } = response.data;
          //this.setAuth(data);
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Data Berhasil Dihapus!",
          });
          this.close();
          this.getAkunSiswa();
        })
        .catch((error) => {
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data,
          });
          this.close();
        });
      this.closeDelete();
    },

    resetItemConfirm(item) {
      this.loading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post("/api/auth/resetPass/" + item, {}, config)
        .then((response) => {
          this.loading = false;
          // console.log(response.data.kataSandi);
          this.newpass = response.data.kataSandi;
          this.dialogReset = false;
          this.dialogResetPass = true;
        })
        .catch((error) => {
          this.loading = false;
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data,
          });
          this.close();
        });
      this.closeReset();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeReset() {
      this.dialogReset = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeResetPass() {
      this.dialogResetPass = false;
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          // UPDATE DATA
          // console.log("edited data");
          // Object.assign(this.admission[this.editedIndex], this.editedItem);
          // let config = {
          //   headers: {
          //     Authorization: "Bearer " + this.user.token,
          //   },
          // };
          // let formData = new FormData();
          // formData.set("NAME", this.editedItem.NAME);
          // formData.set("STATUS", this.editedItem.STATUS);
          // // console.log(formData.getAll("STATUS"));
          // this.axios
          //   .post("/api/saveBank/" + this.editedItem.id, formData, config)
          //   .then(() => {
          //     // let { data } = response.data;
          //     //this.setAuth(data);
          //     // console.log(response);
          //     this.setAlert({
          //       status: true,
          //       color: "success",
          //       text: "Data Berhasil Diubah!",
          //     });
          //     this.close();
          //     this.getAkunSiswa();
          //   })
          //   .catch((error) => {
          //     let responses = error;
          //     // console.log(responses);
          //     // let { data } = error.response;
          //     this.setAlert({
          //       status: true,
          //       color: "error",
          //       text: responses.data.errors,
          //     });
          //   });
        } else {
          this.loading = true;
          // CREATE DATA
          // console.log("created data");
          // this.admission.push(this.editedItem);
          // console.log(this.editedItem.STATUS);
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.token,
            },
          };
          let formData = new FormData();
          formData.set("username", this.editedItem.username);
          formData.set("email", this.editedItem.email);
          this.axios
            .post("/api/auth/studentAdd", formData, config)
            .then(() => {
              this.loading = false;
              // let { data } = response.data;
              //this.setAuth(data);
              // console.log(response);
              this.setAlert({
                status: true,
                color: "success",
                text: "Data Berhasil Ditambahkan!",
              });
              this.close();
              this.getAkunSiswa();
            })
            .catch((error) => {
              this.loading = false;
              // console.log(error.response.data.errors);
              // var err = error.response.data;
              // var errBaru = err.map(key => {
              //   return key.errors;
              // });
              // console.log(errBaru);
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data.errors,
              });
              this.close();
            });
        }
        this.closes();
      }
    },

    reqList(item) {
      // console.log(item.id);
      localStorage.bankID = item.id;
      localStorage.bankNAME = item.NAME;
      this.$router.replace({ name: "Soal" });
    },

    closes() {
      this.$emit("closed", false);
    },
  },
};
</script>