<template>
  <div>
    <v-container class="ma-0 pa-1" grid-list-sm>
      <div class="text-left">
        <v-btn small text to="/" class="blue--text">
          <v-icon>mdi-chevron-left</v-icon> Kembali
        </v-btn>
      </div>
    </v-container>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Biodata Siswa</v-toolbar-title>
      </v-toolbar>

      <v-divider></v-divider>

      <v-container fluid>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row align="center">
            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="name"
                :rules="Rules"
                type="text"
                label="Nama Lengkap"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="sex"
                :rules="Rules"
                :items="sexItems"
                item-value="id"
                item-text="name"
                label="Jenis Kelamin"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="nisn"
                :rules="Rules"
                type="number"
                label="NISN"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="nis"
                :rules="Rules"
                type="text"
                label="NIS"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="noijazah"
                :rules="Rules"
                type="text"
                label="Nomor Seri Ijazah"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="skhun"
                :rules="Rules"
                type="text"
                label="Nomor Seri SKHUN"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="noun"
                :rules="Rules"
                type="text"
                label="Nomor Ujian Nasional"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="nik"
                :rules="Rules"
                type="number"
                label="No. Induk Kependudukan (NIK)"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="nokk"
                :rules="Rules"
                type="text"
                label="No. Kartu Keluarga (KK)"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="npsn"
                :rules="Rules"
                type="text"
                label="NPSN Sekolah Asal"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="jenjang"
                :rules="Rules"
                :items="jenjangItems"
                item-value="bentuk_pendidikan_id"
                item-text="nama"
                label="Jenjang Pendidikan Sebelumnya"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="email"
                :rules="Rules"
                label="Email Pribadi"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="blood"
                :rules="Rules"
                :items="bloodItems"
                item-value="id"
                item-text="name"
                label="Golongan Darah"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="childno"
                :rules="Rules"
                type="text"
                label="Anak yang ke"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="birthplace"
                :rules="Rules"
                type="text"
                label="Tempat Lahir"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="dob"
                :rules="Rules"
                type="date"
                label="Tanggal Lahir"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="religion"
                :rules="Rules"
                :items="religionItems"
                item-value="id"
                item-text="name"
                label="Agama"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="citizen"
                :rules="Rules"
                :items="citizenItems"
                item-value="id"
                item-text="name"
                label="Kewarganegaraan"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="address"
                :rules="Rules"
                type="text"
                label="Alamat Tempat Tinggal"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="dusun"
                :rules="Rules"
                type="text"
                label="Dusun"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="rt"
                :rules="Rules"
                type="text"
                label="RT"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="rw"
                :rules="Rules"
                type="text"
                label="RW"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="province"
                :rules="Rules"
                :items="provinceItems"
                item-value="id"
                item-text="name"
                label="Provinsi"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="city"
                :rules="Rules"
                :items="cityItems"
                item-value="kode_wilayah"
                item-text="name"
                label="Kota/Kab"
                v-on:change="kabkotChange"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="district"
                :rules="Rules"
                :items="districtItems"
                item-value="kode_wilayah"
                item-text="name"
                label="Kecamatan"
                v-on:change="kecChange"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="subdistrict"
                :rules="Rules"
                :items="subdistrictItems"
                item-value="kode_wilayah"
                item-text="name"
                label="Desa/Kelurahan"
                v-on:change="kelChange"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="box"
                :rules="Rules"
                type="text"
                label="Kode POS"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="transport"
                :rules="Rules"
                :items="transportItems"
                item-value="id"
                item-text="name"
                label="Alat Transportasi ke Sekolah"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="homestatus"
                :rules="Rules"
                :items="homestatusItems"
                item-value="id"
                item-text="name"
                label="Jenis Tinggal"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="phone"
                :rules="Rules"
                type="text"
                label="No. Telepon Rumah"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="hp"
                :rules="Rules"
                type="text"
                label="Nomor HP"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="specialneed"
                :rules="Rules"
                :items="specialneedItems"
                item-value="id"
                item-text="name"
                label="Berkebutuhan Khusus"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="kks"
                :rules="Rules"
                :items="booleanItems"
                item-value="id"
                item-text="name"
                label="Apakah Penerima KKS (Kartu Keluarga Sejahtera) ?"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="kps"
                :rules="Rules"
                :items="booleanItems"
                item-value="id"
                item-text="name"
                label="Apakah Penerima KPS (Kartu Perlindungan Sosial) ?"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="pip"
                :rules="Rules"
                :items="booleanItems"
                item-value="id"
                item-text="name"
                label="Usulan dari sekolah Layak PIP (Program Indonesia Pintar) ?"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="kip"
                :rules="Rules"
                :items="booleanItems"
                item-value="id"
                item-text="name"
                label="Apakah Penerima KIP (Kartu Indonesia Pintar) ?"
                v-on:change="kipChange"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="kipstatus"
                :rules="Rules"
                :items="deactiveItems"
                item-value="id"
                item-text="name"
                label="Status KIP"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="kipno"
                :rules="Rules"
                label="Nomor KIP (Kartu Indonesia Pintar)"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="lot"
                :rules="Rules"
                type="text"
                label="Garis Lintang"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="lang"
                :rules="Rules"
                type="text"
                label="Garis Bujur"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="noakta"
                :rules="Rules"
                type="text"
                label="No. Registrasi Akta Lahir"
                required
              ></v-text-field>
            </v-col>

            <!-- <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="admission"
                :rules="Rules"
                :items="admissionItems"
                item-value="id"
                item-text="NAME"
                label="Jalur Penerimaan"
                multiple
              ></v-select>
            </v-col> -->

            <v-col class="d-flex" cols="12" sm="12">
              Upload Foto (max. 2 MB)
              <input
                style="margin-left: 50px"
                type="file"
                id="file"
                ref="file"
                accept="image/*"
                v-on:change="handleFileUpload()"
              />
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <div class="text-xs-center">
                <v-btn color="warning" @click.stop="$router.push('/')">
                  <v-icon left dark>mdi-arrow-left</v-icon>
                  Kembali </v-btn
                >&nbsp;
                <v-btn
                  color="success"
                  :disabled="!valid || isSending"
                  @click="submit"
                >
                  Register
                  <v-icon right dark>mdi-plus</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>

    <!-- LOADER -->
    <v-dialog v-model="dialogLoading" persistent width="300">
      <v-card>
        <v-card-text>
          Sedang mengambil data...
          <v-progress-linear
            indeterminate
            color="primary"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Register",
  data() {
    return {
      dialogLoading: false,
      admissionItems: [{ id: "", NAME: "" }],
      sexItems: [
        { id: "M", name: "Laki-Laki" },
        { id: "F", name: "Perempuan" },
      ],
      religionItems: [
        { id: 1, name: "Islam" },
        { id: 2, name: "Kristen Protestan" },
        { id: 3, name: "Kristen Katolik" },
        { id: 4, name: "Hindu" },
        { id: 5, name: "Budha" },
        { id: 6, name: "Konghucu" },
      ],
      specialneedItems: [
        { id: 1, name: "Tidak" },
        { id: 2, name: "Netra" },
        { id: 3, name: "Rungu" },
        { id: 4, name: "Grahita Ringan" },
        { id: 5, name: "Grahita Sedang" },
        { id: 6, name: "Daksa Ringan" },
        { id: 7, name: "Daksa Sedang" },
        { id: 8, name: "Lara" },
      ],
      homestatusItems: [
        { id: 1, name: "Bersama Orang Tua" },
        { id: 2, name: "Wali" },
        { id: 3, name: "Kos" },
        { id: 4, name: "Asrama" },
        { id: 5, name: "Panti Asuhan" },
      ],
      transportItems: [
        { id: 1, name: "Jalan Kaki" },
        { id: 2, name: "Kendaraan Pribadi" },
        { id: 3, name: "Kendaraan Umum" },
        { id: 4, name: "Jemputan Sekolah" },
        { id: 5, name: "Kereta Api" },
        { id: 6, name: "Ojek" },
        { id: 7, name: "Andong" },
      ],
      bloodItems: [
        { id: "O", name: "O" },
        { id: "A", name: "A" },
        { id: "B", name: "B" },
        { id: "AB", name: "AB" },
      ],
      booleanItems: [
        { id: "1", name: "Ya" },
        { id: "0", name: "Tidak" },
      ],
      deactiveItems: [
        { id: "1", name: "Aktif" },
        { id: "0", name: "Tidak" },
      ],
      citizenItems: [
        { id: "1", name: "WNI" },
        { id: "0", name: "WNA" },
      ],
      provinceItems: [{ id: 300000, name: "Gorontalo" }],
      cityItems: [{ kode_wilayah: "", name: "" }],
      districtItems: [{ kode_wilayah: "", name: "" }],
      subdistrictItems: [{ kode_wilayah: "", name: "" }],
      jenjangItems: [{ bentuk_pendidikan_id: "", nama: "" }],
      valid: true,
      isSending: false,
      email: localStorage.getItem("email"),
      nisn: localStorage.getItem("nisn"),
      nis: "",
      npsn: localStorage.getItem("npsn"),
      nik: localStorage.getItem("nik"),
      nokk: "",
      noakta: "",
      admission: "",
      name: localStorage.getItem("nama"),
      birthplace: localStorage.getItem("tempat_lahir"),
      dob: localStorage.getItem("tanggal_lahir"),
      sex: localStorage.getItem("jenis_kelamin") == "L" ? "M" : "F",
      religion: "",
      address: localStorage.getItem("alamat_jalan"),
      rt: localStorage.getItem("rt"),
      rw: localStorage.getItem("rw"),
      dusun: localStorage.getItem("nama_dusun"),
      province: "",
      city: "",
      district: "",
      subdistrict: "",
      box: "",
      lang: localStorage.getItem("bujur"),
      lot: localStorage.getItem("lintang"),
      phone: "",
      hp: "",
      blood: "",
      kip: "",
      kipstatus: "",
      kipno: localStorage.getItem("no_kip"),
      citizen: "",
      specialneed: "",
      homestatus: "",
      transport: "",
      childno: "",
      noijazah: "",
      skhun: "",
      noun: "",
      kks: "",
      kps: "",
      pip: "",
      jenjang: parseInt(localStorage.getItem("levelSource")),

      Rules: [(v) => !!v || "Form ini wajib di isi"],
    };
  },
  watch: {
    dialogLoading(val) {
      if (!val) return;

      setTimeout(() => (this.dialogLoading = false), 2000);
    },
  },

  created() {
    this.initialize();
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      setAlert: "alert/set",
    }),

    initialize() {
      this.getKabkot();
      this.getJenjang();
      // this.getJalur();
      // this.dialogLoading = true;
    },

    kipChange: function (e) {
      if (e == 0) {
        this.kipno = "0";
        this.kipstatus = "0";
      }
    },

    kabkotChange: function (e) {
      // console.log("kode kabkot", e);
      this.kabkotKode = e;
      this.getKec();
      // localStorage.kabkot = kabkot;
    },
    kecChange: function (e) {
      // console.log("kode kecamatan", e);
      this.kecamatanKode = e;
      this.getKel();
    },
    kelChange: function (e) {
      console.log("kel", e);
    },

    // getJalur() {
    //   // console.log("getKabkot");
    //   let config = {
    //     headers: {
    //       Authorization: "Bearer " + this.user.token,
    //     },
    //   };
    //   this.axios
    //     .post("/api/activeAdmission", {}, config)
    //     .then((response) => {
    //       // console.log(response.data);
    //       this.admissionItems = response.data.data;
    //     })
    //     .catch((error) => {
    //       console.log("error" + error);
    //     });
    // },
    getJenjang() {
      // console.log("getKabkot");
      this.dialogLoading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post("/api/jenjang", {}, config)
        .then((response) => {
          this.dialogLoading = false;
          // console.log(response.data);
          this.jenjangItems = response.data;
        })
        .catch((error) => {
          this.dialogLoading = false;
          console.log("error" + error);
        });
    },
    getKabkot() {
      // console.log("getKabkot");
      this.dialogLoading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post("/api/getWilayah", {}, config)
        .then((response) => {
          this.dialogLoading = false;
          // console.log(response.data);
          this.cityItems = response.data;
        })
        .catch((error) => {
          this.dialogLoading = false;
          console.log("error" + error);
        });
    },
    getKec() {
      // console.log("getKec");
      this.dialogLoading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = {
        kode_wilayah: this.kabkotKode,
      };
      this.axios
        .post("/api/getWilayah", formData, config)
        .then((response) => {
          this.dialogLoading = false;
          // console.log("kecamatan", response.data);
          this.districtItems = response.data;
        })
        .catch((error) => {
          this.dialogLoading = false;
          console.log("error" + error);
        });
    },
    getKel() {
      // console.log("getKec");
      this.dialogLoading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = {
        kode_wilayah: this.kecamatanKode,
      };
      this.axios
        .post("/api/getWilayah", formData, config)
        .then((response) => {
          this.dialogLoading = false;
          // console.log("kecamatan", response.data);
          this.subdistrictItems = response.data;
        })
        .catch((error) => {
          this.dialogLoading = false;
          console.log("error" + error);
        });
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.isSending = true;
        this.dialogLoading = true;
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.token,
          },
        };
        let formData = new FormData();
        // formData.set("EMAIL", this.email);
        // formData.set("NISN", this.nisn);
        formData.set("NIS", this.nis);
        formData.set("NPSN", this.npsn);
        formData.set("NIK", this.nik);
        formData.set("NOKK", this.nokk);
        formData.set("NOAKTA", this.noakta);
        formData.set("NAME", this.name);
        formData.set("BIRTHPLACE", this.birthplace);
        formData.set("DOB", this.dob);
        formData.set("SEX", this.sex);
        formData.set("RELIGION", this.religion);
        formData.set("ADDRESS", this.address);
        formData.set("RT", this.rt);
        formData.set("RW", this.rw);
        formData.set("DUSUN", this.dusun);
        formData.set("PROVINCE", this.province);
        formData.set("CITY", this.city);
        formData.set("DISTRICT", this.district);
        formData.set("SUBDISTRICT", this.subdistrict);
        formData.set("BOX", this.box);
        formData.set("LANG", this.lang);
        formData.set("LOT", this.lot);
        formData.set("PHONE", this.phone);
        formData.set("HP", this.hp);
        formData.set("PHOTO", this.file);
        formData.set("BLOOD", this.blood);
        formData.set("KIP", this.kip);
        formData.set("KIPSTATUS", this.kipstatus);
        formData.set("KIPNO", this.kipno);
        formData.set("CITIZEN", this.citizen);
        formData.set("SPECIALNEED", this.specialneed);
        formData.set("HOMESTATUS", this.homestatus);
        formData.set("TRANSPORT", this.transport);
        formData.set("CHILDNO", this.childno);
        formData.set("NOIJAZAH", this.noijazah);
        formData.set("SKHUN", this.skhun);
        formData.set("NOUN", this.noun);
        formData.set("KKS", this.kks);
        formData.set("KPS", this.kps);
        formData.set("PIP", this.pip);
        formData.set("LEVEL", this.jenjang);
        // console.log(formData.getAll("ADMISSION"));
        this.axios
          .post("/api/saveBio", formData, config)
          .then((response) => {
            this.dialogLoading = false;
            // let { data } = response.data;
            //this.setAuth(data);
            console.log(response.data);
            this.setAlert({
              status: true,
              color: "success",
              text: "Berhasil Melengkapi Biodata",
            });
            this.close();
            localStorage.studentBio = true;
            this.$router.replace({ name: "Home" });
          })
          .catch((error) => {
            this.dialogLoading = false;
            this.isSending = false;
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.errors,
            });
            this.close();
          });
      }
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },

    close() {
      this.$emit("closed", false);
    },
  },
};

// export default {
//   data: () => ({
//     books: [],
//     page: 0,
//     lengthPage: 0,
//   }),
//   created() {
//     this.go();
//   },
//   methods: {
//     go() {
//       let url = "/books?page=" + this.page;
//       this.axios
//         .get(url)
//         .then((response) => {
//           let { data } = response.data;
//           let { meta } = response.data;
//           this.books = data;
//           // jumlah halaman di dapat dari meta endpoint books
//           this.lengthPage = meta.last_page;
//           this.page = meta.current_page;
//         })
//         .catch((error) => {
//           let { responses } = error;
//           console.log(responses);
//         });
//     },
//   },
// };
</script>