<template>
  <div>
    <v-app-bar app color="primary" dark>
      <v-btn icon @click.stop="$router.go(-1)">
        <v-icon>mdi-arrow-left-circle</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn icon to="/about">
        <v-badge color="orange" overlap>
          <template v-slot:badge>
            <span>{{ countCart }} </span>
          </template>
          <v-icon>mdi-cart</v-icon>
        </v-badge>
      </v-btn>
    </v-app-bar>

    <v-card :to="'/category/' + category.slug" v-if="category.slug">
      <v-img :src="getImage(category.image)" class="white--text">
        <v-card-title
          class="fill-height align-end"
          v-text="category.name"
        ></v-card-title>
      </v-img>
    </v-card>
    <v-container class="ma-0 pa-0" grid-list-sm v-if="books">
      <v-subheader> All Books </v-subheader>
      <v-layout wrap>
        <v-flex v-for="book in books" :key="`book-` + book.id" xs6>
          <v-card :to="'/book/' + book.slug">
            <v-img :src="getImage(book.cover)" class="white--text">
              <v-card-title
                class="fill-height align-end"
                v-text="book.title"
              ></v-card-title>
            </v-img>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <template>
      <div class="text-center">
        <v-pagination
          v-model="page"
          @input="go"
          :length="lengthPage"
          :total-visible="5"
        ></v-pagination>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    category: {}, // objek category
    books: [], // daftar buku pada category tersebut
    page: 0,
    lengthPage: 0,
  }),
  created() {
    this.go();
  },
  methods: {
    go() {
      let { slug } = this.$route.params;
      let url = "/categories/slug/" + slug;
      url = url + "?page=" + this.page;
      url = encodeURI(url);
      this.axios
        .get(url)
        .then((response) => {
          let { data } = response.data;
          let category = data;
          this.category = category;
          this.books = category.books.data;
          this.page = category.books.current_page;
          this.lengthPage = category.books.last_page;
        })
        .catch((error) => {
          let { responses } = error;
          console.log(responses);
        });
    },
  },
  computed: {
    ...mapGetters({
      countCart: "cart/count", // <= tambahkan ini
    }),
  },
};
</script>