<template>
  <div>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Scoring Final {{ appName }}</v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-container fluid class="pa-10">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row align="center">
            <v-col cols="12">
              <v-select
                v-model="filterJalur"
                :items="jalurItems"
                :rules="rules"
                dense
                item-value="id"
                item-text="NAME"
                label="Pilih Jalur"
                v-on:change="jalurChange"
              ></v-select>
            </v-col>
            <!-- <v-col cols="12">
              <v-select
                v-model="filterSekolah"
                :items="sekolahItems"
                :rules="rules"
                dense
                item-value="SCHOOL_ID"
                item-text="NAME"
                label="Pilih Sekolah"
              ></v-select>
            </v-col> -->
            <v-col cols="12">
              <v-select
                v-model="filterPilihan"
                :items="pilihanItems"
                :rules="rules"
                dense
                item-value="id"
                item-text="name"
                label="Pilih Prioritas"
                v-on:change="pilihanChange"
              ></v-select>
            </v-col>
            <!-- <v-col cols="12">
              <div class="text-center">
                <v-btn color="success" :disabled="!valid" @click="submit">
                  <v-icon left dark>mdi-text-search</v-icon>
                  Scoring
                </v-btn>
              </div>
            </v-col> -->
          </v-row>
        </v-form>
      </v-container>
    </v-card>
    <br />
    <v-data-table
      :headers="headers"
      :items="sekolahItems"
      class="elevation-1"
      item-key="name"
      :search="search"
    >
      <template v-slot:item.nomor="{ item }">
        {{ sekolahItems.indexOf(item) + 1 }}
      </template>
      <template v-slot:item.scoring="{ item }">
        <v-btn
          x-small
          rounded
          color="teal"
          dark
          @click="scoringAdmissionOneTemp(item)"
          v-if="filterPilihan == 1"
        >
          Scoring Final Pilihan 1
        </v-btn>
        <v-btn
          x-small
          rounded
          color="warning"
          @click="scoringAdmissionTwoTemp(item)"
          v-if="filterPilihan == 2"
        >
          Scoring Final Pilihan 2
        </v-btn>
        <v-btn
          x-small
          rounded
          color="error"
          @click="scoringAdmissionThreeTemp(item)"
          v-if="filterPilihan == 3"
        >
          Scoring Final Pilihan 3
        </v-btn>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar dark color="success">
            <v-toolbar-title>Daftar Sekolah </v-toolbar-title>
          </v-toolbar>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-text-field v-model="search" label="Cari" class="mx-4"></v-text-field>
      </template>
      <!-- <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template> -->
      <template v-slot:no-data> Tidak ada data </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ScoringFinal",
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    dialogLoading: false,
    menu2: false,
    dialog: false,
    dialogDelete: false,
    valid: true,
    search: "",
    status: "",
    headers: [
      { text: "No", value: "nomor" },
      {
        text: "NPSN",
        align: "left",
        value: "SCHOOL_ID",
      },
      { text: "Nama Sekolah", value: "NAME" },
      { text: "Scoring", value: "scoring", sortable: false },
    ],
    admission: [],
    sekolah: [],
    jalurItems: [],
    sekolahItems: [],
    filterJalur: null,
    filterSekolah: null,
    filterPilihan: null,
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    pilihanItems: [
      { id: 1, name: "Pilihan 1" },
      { id: 2, name: "Pilihan 2" },
      { id: 3, name: "Pilihan 3" },
    ],
    rules: [(v) => !!v || "Form ini wajib di isi"],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Data" : "Edit Data";
    },
    passwordConfirmationRule() {
      return () => this.password === this.confpassword || "Password harus sama";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
    }),

    initialize() {
      this.getJalur();
    },

    getJalur() {
      this.$vloading.show();
      let config = {
        headers: {},
      };
      this.axios
        .post("/api/getAdmission", {}, config)
        .then((response) => {
          this.$vloading.hide();
          this.jalurItems = response.data;
          // console.log(this.jalurItems);
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log("error : " + error);
          let { data } = error.response;
          this.setAlert({
            status: true,
            color: "error",
            text: data.message,
          });
        });
    },

    getSekolah(e) {
      this.$vloading.show();
      let config = {
        headers: {},
      };
      let formData = new FormData();
      formData.set("ADMISSIONID", e);
      this.axios
        .post("/api/getActiveSchool", formData, config)
        .then((response) => {
          this.$vloading.hide();
          this.sekolahItems = response.data.data;
          console.log(this.sekolahItems);
        })
        .catch((error) => {
          this.$vloading.hide();
          this.sekolahItems = [];
          console.log("error : " + error);
          let { data } = error.response;
          this.setAlert({
            status: true,
            color: "error",
            text: data.message,
          });
        });
    },

    jalurChange: function (e) {
      // console.log(e);
      this.getSekolah(e);
      this.filterPilihan = null;
    },

    pilihanChange: function (e) {
      // console.log(e);
      this.filterPilihan = e;
    },

    editItem(item) {
      this.editedIndex = this.admission.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.admission.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.token,
          },
        };
        let formData = new FormData();
        formData.set("NPSN", this.filterSekolah);
        formData.set("ADMISSIONID", this.filterJalur);
        formData.set("PRIORITY", this.filterPilihan);
        this.axios
          .post("/api/auth/scoringAdmission", formData, config)
          .then((response) => {
            this.$vloading.hide();
            console.log(response.data);
            this.setAlert({
              status: true,
              color: "success",
              text: response.data.message,
            });
            this.close();
          })
          .catch((error) => {
            this.$vloading.hide();
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data,
            });
            this.close();
          });
      }
      this.closes();
    },

    scoringAdmissionOneTemp(item) {
      // console.log(item.SCHOOL_ID);
      // console.log(this.filterJalur);
      this.$vloading.show();
      // console.log(item.id);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("ADMISSIONID", this.filterJalur);
      formData.set("PRIORITY", "1");
      formData.set("NPSN", item.SCHOOL_ID);
      this.axios
        .post("/api/auth/scoringAdmission", formData, config)
        .then((response) => {
          this.$vloading.hide();
          console.log(response.data);
          this.setAlert({
            status: true,
            color: "success",
            text: response.data.message,
          });
          this.close();
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data,
          });
          this.close();
        });
    },

    scoringAdmissionTwoTemp(item) {
      // console.log(item.SCHOOL_ID);
      // console.log(this.filterJalur);
      this.$vloading.show();
      // console.log(item.id);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("ADMISSIONID", this.filterJalur);
      formData.set("PRIORITY", "2");
      formData.set("NPSN", item.SCHOOL_ID);
      this.axios
        .post("/api/auth/scoringAdmission", formData, config)
        .then((response) => {
          this.$vloading.hide();
          console.log(response.data);
          this.setAlert({
            status: true,
            color: "success",
            text: response.data.message,
          });
          this.close();
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data,
          });
          this.close();
        });
    },

    scoringAdmissionThreeTemp(item) {
      // console.log(item.SCHOOL_ID);
      // console.log(this.filterJalur);
      this.$vloading.show();
      // console.log(item.id);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("ADMISSIONID", this.filterJalur);
      formData.set("PRIORITY", "3");
      formData.set("NPSN", item.SCHOOL_ID);
      this.axios
        .post("/api/auth/scoringAdmission", formData, config)
        .then((response) => {
          this.$vloading.hide();
          console.log(response.data);
          this.setAlert({
            status: true,
            color: "success",
            text: response.data.message,
          });
          this.close();
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data,
          });
          this.close();
        });
    },

    reqList(item) {
      // console.log(item.id);
      localStorage.bankID = item.id;
      localStorage.bankNAME = item.NAME;
      this.$router.replace({ name: "Soal" });
    },

    closes() {
      this.$emit("closed", false);
    },
  },
};
</script>