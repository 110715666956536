<template>
  <div>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Mapping Data {{ appName }}</v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-container fluid class="pa-10">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row align="center">
            <v-col cols="12">
              <v-select
                v-model="filterJalur"
                :items="jalurItems"
                :rules="rules"
                dense
                item-value="id"
                item-text="NAME"
                label="Pilih Jalur"
                v-on:change="jalurChange"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="filterSekolah"
                :items="sekolahItems"
                :rules="rules"
                dense
                item-value="SCHOOL_ID"
                item-text="NAME"
                label="Pilih Sekolah"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <div class="text-center">
                <v-btn color="success" :disabled="!valid" @click="submit">
                  <v-icon left dark>mdi-text-search</v-icon>
                  Check
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
    <br />
    <v-data-table
      :headers="headers"
      :items="admission"
      class="elevation-1"
      item-key="name"
      :search="search"
    >
      <template v-slot:item.nomor="{ item }">
        {{ admission.indexOf(item) + 1 }}
      </template>
      <template v-slot:item.PILIHAN="{ item }">
        <!-- {{ item.school_selected }} -->
        <ul class="pa-1">
          <span v-for="dt in item.PILIHAN" :key="dt.id">
            <li>{{ dt.NAME }}</li>
          </span>
        </ul>
      </template>
      <template v-slot:item.registered="{ item }">
        <span v-if="item.registered != null">
          <v-chip small rounded color="success" dark>
            Sudah Registrasi Ulang
          </v-chip>
        </span>
        <span v-else>
          <v-chip small rounded color="error" dark>
            Belum Registrasi Ulang
          </v-chip>
        </span>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar dark color="success">
            <v-toolbar-title
              >Peserta Didik Diterima
              <v-btn small color="teal" dark class="mb-2" @click="makePDF">
                <v-icon left>mdi-download</v-icon>Download
              </v-btn>
            </v-toolbar-title>
          </v-toolbar>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-text-field v-model="search" label="Cari" class="mx-4"></v-text-field>
      </template>
      <!-- <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template> -->
      <template v-slot:no-data> Tidak ada data </template>
    </v-data-table>

    <br />
    <div id="print" hidden>
      <center>
        <table
          border="1"
          width="100%"
          style="
            border-collapse: collapse;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 11px;
          "
          align="center"
          cellpadding="5px"
        >
          <thead>
            <tr>
              <th class="text-left">No</th>
              <th class="text-left">Jalur Penerimaan</th>
              <th class="text-left">Sekolah</th>
              <th class="text-left">Nomor Registrasi</th>
              <th class="text-left">Nama Peserta Didik</th>
              <th class="text-left">Status Registrasi Ulang</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="key in admission" :key="key.id">
              <td>{{ admission.indexOf(key) + 1 }}</td>
              <td>{{ key.admission.admission.NAME }}</td>
              <td>{{ key.school.NAME }}</td>
              <td>{{ key.admission.REGNUMBER }}</td>
              <td>{{ key.student.NAME }}</td>
              <td>
                <span v-if="key.registered != null">
                  Sudah Registrasi Ulang
                </span>
                <span v-else> Belum Registrasi Ulang </span>
              </td>
            </tr>
          </tbody>
        </table>
      </center>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "MappingData",
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    dialogLoading: false,
    menu2: false,
    dialog: false,
    dialogDelete: false,
    valid: true,
    search: "",
    status: "",
    headers: [
      { text: "No", value: "nomor" },
      {
        text: "Nomor Registrasi",
        align: "left",
        value: "admission.REGNUMBER",
      },
      { text: "Nama Peserta Didik", value: "student.NAME" },
      { text: "Status Registrasi Ulang", value: "registered" },
      // { text: "Actions", value: "actions", sortable: false },
    ],
    admission: [],
    sekolah: [],
    jalurItems: [],
    sekolahItems: [],
    filterJalur: null,
    filterSekolah: null,
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    statusItems: [
      { id: 1, name: "Dibuka" },
      { id: 0, name: "Ditutup" },
    ],
    rules: [(v) => !!v || "Form ini wajib di isi"],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Data" : "Edit Data";
    },
    passwordConfirmationRule() {
      return () => this.password === this.confpassword || "Password harus sama";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
    }),

    initialize() {
      this.getJalur();
    },

    getJalur() {
      this.$vloading.show();
      let config = {
        headers: {},
      };
      this.axios.post("/api/getAdmission", {}, config).then((response) => {
        this.jalurItems = response.data;
        // console.log(this.jalurItems);
        this.$vloading.hide();
      });
    },

    getSekolah(e) {
      this.$vloading.show();
      let config = {
        headers: {},
      };
      let formData = new FormData();
      formData.set("ADMISSIONID", e);
      this.axios
        .post("/api/getActiveSchool", formData, config)
        .then((response) => {
          this.sekolahItems = response.data.data;
          // console.log(this.sekolahItems);
          this.$vloading.hide();
        });
    },

    jalurChange: function (e) {
      // console.log(e);
      this.getSekolah(e);
    },

    editItem(item) {
      this.editedIndex = this.admission.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.admission.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.token,
          },
        };
        let formData = new FormData();
        formData.set("NPSN", this.filterSekolah);
        formData.set("ADMISSIONID", this.filterJalur);
        this.axios
          .post("/api/auth/mappingData", formData, config)
          .then((response) => {
            this.admission = response.data.data;
            this.status = response.data.dataStatus;
            this.$vloading.hide();
            console.log(this.admission);
            console.log(this.status);
          })
          .catch((error) => {
            console.log(error.response.data);
            this.$vloading.hide();
            this.admission = [];
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.message,
            });
            this.close();
          });
      }
      this.closes();
    },

    makePDF() {
      // window.html2canvas = html2canvas;
      // // var w = document.getElementById("print").offsetWidth;
      // // var h = document.getElementById("print").offsetHeight;
      // var doc = new jsPDF("p", "pt", "a4");
      // doc.html(document.getElementById("print"), {
      //   callback: function (pdf) {
      //     pdf.save("mypdf.pdf");
      //   },
      // });
      var prtContent = document.getElementById("print");
      var WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(prtContent.innerHTML);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },

    reqList(item) {
      // console.log(item.id);
      localStorage.bankID = item.id;
      localStorage.bankNAME = item.NAME;
      this.$router.replace({ name: "Soal" });
    },

    closes() {
      this.$emit("closed", false);
    },
  },
};
</script>