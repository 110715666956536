<template>
  <div>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Ubah Password</v-toolbar-title>
      </v-toolbar>

      <v-divider></v-divider>
      <v-container fluid class="pa-10">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row align="center">
            <v-col cols="4">
              <v-subheader> Password Lama </v-subheader>
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="oldPassword"
                :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="passwordRules"
                :type="showOldPassword ? 'text' : 'password'"
                counter
                @click:append="showOldPassword = !showOldPassword"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-subheader> Password Baru </v-subheader>
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="passwordRules"
                :type="showPassword ? 'text' : 'password'"
                counter
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-subheader> Konfirmasi Password Baru </v-subheader>
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="confpassword"
                :append-icon="showconfPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="confpasswordRules.concat(passwordConfirmationRule)"
                :type="showconfPassword ? 'text' : 'password'"
                counter
                @click:append="showconfPassword = !showconfPassword"
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <div class="text-xs-center">
                <v-btn color="success" :disabled="!valid" @click="submit">
                  Simpan
                  <v-icon right dark>mdi-plus</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Pilihan",
  data() {
    return {
      valid: true,
      oldPassword: "",
      newPassword: "",
      showOldPassword: false,
      showPassword: false,
      password: "",
      passwordRules: [
        (v) => !!v || "Password wajib di isi",
        (v) => (v && v.length >= 8) || "Minimal 8 karakter",
      ],
      showconfPassword: false,
      confpassword: "",
      confpasswordRules: [
        (v) => !!v || "Konfirmasi Password wajib di isi",
        (v) => (v && v.length >= 8) || "Minimal 8 karakter",
      ],
    };
  },
  mounted() {},
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    passwordConfirmationRule() {
      return () => this.password === this.confpassword || "Password harus sama";
    },
  },
  methods: {
    ...mapActions({
      setAlert: "alert/set",
    }),

    submit() {
      if (this.$refs.form.validate()) {
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.token,
          },
        };
        let formData = new FormData();
        formData.set("oldpassword", this.oldPassword);
        formData.set("password", this.password);
        formData.set("password_confirmation", this.confpassword);
        // console.log(formData.getAll("npsn"));
        this.axios
          .post("/api/auth/changePassword", formData, config)
          .then(() => {
            // let { data } = response.data;
            //this.setAuth(data);
            // console.log(response.data);
            this.setAlert({
              status: true,
              color: "success",
              text: "Berhasil disimpan",
            });
            this.close();
            // this.$router.replace({ name: "register" });
            this.$refs.form.reset();
          })
          .catch((error) => {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.errors,
            });
            this.close();
          });
      }
    },
    close() {
      this.$emit("closed", false);
    },
  },
};
</script>