<template>
  <div>
    <v-select
      v-model="kabkot"
      :items="kabkots"
      item-value="kode_wilayah"
      item-text="name"
      label="Kota/Kab"
      v-on:change="kabkotChange"
    ></v-select>

    <v-select
      v-model="kecamatan"
      :items="kecamatans"
      item-value="kode_wilayah"
      item-text="name"
      label="Kecamatan"
      v-on:change="kecChange"
    ></v-select>

    <v-select
      v-model="kelurahan"
      :items="kelurahans"
      item-value="kode_wilayah"
      item-text="name"
      label="Desa/Kelurahan"
      v-on:change="kelChange"
    ></v-select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      kabkot: "",
      kabkots: [{ kode_wilayah: "", name: "" }],
      kecamatan: "",
      kecamatans: [{ kode_wilayah: "", name: "" }],
      kelurahan: "",
      kelurahans: [{ kode_wilayah: "", name: "" }],
      kabkotKode: "",
      kecamatanKode: "",
    };
  },

  mounted() {
    this.getKabkot();
  },

  methods: {
    kabkotChange: function (e) {
      // console.log("kode kabkot", e);
      this.kabkotKode = e;
      this.getKec();
      // localStorage.kabkot = kabkot;
    },
    kecChange: function (e) {
      // console.log("kode kecamatan", e);
      this.kecamatanKode = e;
      this.getKel();
    },
    kelChange: function (e) {
      console.log("kode kelurahan", e);
    },



    getKabkot() {
      // console.log("getKabkot");
      this.axios
        .post("/api/frontpage/getWilayah")
        .then((response) => {
          // console.log(response.data);
          this.kabkots = response.data;
        })
        .catch((error) => {
          console.log("error" + error);
        });
    },
    getKec() {
      // console.log("getKec");
      let formData = {
        kode_wilayah: this.kabkotKode,
      };
      this.axios
        .post("/api/frontpage/getWilayah", formData)
        .then((response) => {
          // console.log("kecamatan", response.data);
          this.kecamatans = response.data;
        })
        .catch((error) => {
          console.log("error" + error);
        });
    },
    getKel() {
      // console.log("getKec");
      let formData = {
        kode_wilayah: this.kecamatanKode,
      };
      this.axios
        .post("/api/frontpage/getWilayah", formData)
        .then((response) => {
          // console.log("kecamatan", response.data);
          this.kelurahans = response.data;
        })
        .catch((error) => {
          console.log("error" + error);
        });
    },
  },
};
</script>