<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="admission"
      :expanded.sync="expanded"
      item-key="name"
      show-expand
      class="elevation-1"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          style="padding-left: 7%; padding-top: 10px; padding-bottom: 10px"
        >
          <b
            ><u>Informasi Detail {{ item.NAME }}</u></b
          ><br />
          Tipe Jalur :
          <span v-for="dt in typeItems" :key="dt.id">
            <span v-if="dt.id == item.TYPE">
              {{ dt.name }}
            </span> </span
          ><br />
          Jenjang :
          <span v-for="dt in jenjangItems" :key="dt.id">
            <span v-if="dt.bentuk_pendidikan_id == item.LEVEL">
              {{ dt.nama }}
            </span> </span
          ><br />
          Tanggal Pengumuman : {{ item.DDAY | moment("DD MMMM YYYY") }}, Pukul
          {{ item.DDAY | moment("HH:mm") }} WITA<br />
          Registrasi Ulang Dibuka : {{ item.RSTART | moment("DD MMMM YYYY") }},
          Pukul {{ item.RSTART | moment("HH:mm") }} WITA<br />
          Registrasi Ulang Ditutup : {{ item.REND | moment("DD MMMM YYYY") }},
          Pukul {{ item.REND | moment("HH:mm") }} WITA<br />
          Menggunakan CBT : <span v-if="item.CBT == 0">Tidak</span>
          <span v-else>
            <span v-for="dt in cbt" :key="dt.id">
              <span v-if="dt.id == item.CBT">
                {{ dt.NAME }}
                <v-btn
                  x-small
                  rounded
                  color="cyan"
                  dark
                  @click="scoreList(item)"
                >
                  <v-icon left small>mdi-format-list-checkbox</v-icon> Lihat
                  Nilai
                </v-btn>
              </span>
            </span>
          </span>
        </td>
      </template>
      <template v-slot:item.nomor="{ item }">
        {{ admission.indexOf(item) + 1 }}
      </template>
      <template v-slot:item.OPEN="{ item }">
        {{ item.OPEN | moment("DD MMMM YYYY") }},<br />Pukul
        {{ item.OPEN | moment("HH:mm") }} WITA
      </template>
      <template v-slot:item.CLOSE="{ item }">
        {{ item.CLOSE | moment("DD MMMM YYYY") }},<br />Pukul
        {{ item.CLOSE | moment("HH:mm") }} WITA
      </template>
      <template v-slot:item.STATUS="{ item }">
        <span v-if="item.STATUS == 1">
          <v-chip color="green" dark small>Buka</v-chip></span
        >
        <span v-else><v-chip color="red" dark small>Tutup</v-chip></span>
      </template>
      <template v-slot:item.QUOTA="{ item }"> {{ item.QUOTA }} % </template>
      <template v-slot:item.BACKUP="{ item }"> {{ item.BACKUP }} % </template>
      <template v-slot:item.persyaratan="{ item }">
        <v-btn
          x-small
          class="mr-1"
          rounded
          color="info"
          dark
          @click="reqList(item)"
        >
          <v-icon left small>mdi-clipboard-list-outline</v-icon>
          Persyaratan </v-btn
        ><br />
        <v-btn
          x-small
          class="mr-1"
          rounded
          color="warning"
          dark
          @click="sekolahList(item)"
        >
          <v-icon left small>mdi-school</v-icon> Sekolah
        </v-btn>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Daftar Jalur Penerimaan</v-toolbar-title>
          </v-toolbar>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="800px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-form ref="form" v-model="valid" lazy-validation>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.NAME"
                          :rules="rules"
                          label="Nama Jalur"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <VueCtkDateTimePicker
                          v-model="editedItem.OPEN"
                          :rules="rules"
                          label="Buka"
                          format="YYYY-MM-DD HH:mm"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <VueCtkDateTimePicker
                          v-model="editedItem.CLOSE"
                          :rules="rules"
                          label="Tutup"
                          format="YYYY-MM-DD HH:mm"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.QUOTA"
                          :rules="rules"
                          type="number"
                          label="Kuota (%)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.BACKUP"
                          type="number"
                          label="Cadangan (%)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" v-if="editedIndex > -1">
                        <v-select
                          v-model="editedItem.CBT"
                          :items="cbt"
                          item-value="id"
                          item-text="NAME"
                          label="CBT"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" v-else>
                        <v-select
                          v-model="cbtAuto"
                          :items="cbt"
                          item-value="id"
                          item-text="NAME"
                          label="CBT"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-select
                          v-model="editedItem.STATUS"
                          :items="statusItems"
                          item-value="id"
                          item-text="name"
                          label="Status"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-select
                          v-model="editedItem.TYPE"
                          :items="typeItems"
                          item-value="id"
                          item-text="name"
                          label="Tipe Jalur"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-select
                          v-model="editedItem.LEVEL"
                          :items="jenjangItems"
                          item-value="bentuk_pendidikan_id"
                          item-text="nama"
                          label="Jenjang Pendidikan"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <VueCtkDateTimePicker
                          v-model="editedItem.DDAY"
                          :rules="rules"
                          label="Tanggal Pengumuman"
                          format="YYYY-MM-DD HH:mm"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <VueCtkDateTimePicker
                          v-model="editedItem.RSTART"
                          :rules="rules"
                          label="Tanggal Registrasi Ulang Dibuka"
                          format="YYYY-MM-DD HH:mm"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <VueCtkDateTimePicker
                          v-model="editedItem.REND"
                          :rules="rules"
                          label="Tanggal Registrasi Ulang Ditutup"
                          format="YYYY-MM-DD HH:mm"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Batal
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    :disabled="!valid || loading"
                    :loading="loading"
                    text
                    @click="save"
                  >
                    Simpan
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Anda yakin ingin menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Batal</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data> Tidak ada data </template>
    </v-data-table>
    <!-- LOADER -->
    <v-dialog v-model="dialogLoading" persistent width="300">
      <v-card>
        <v-card-text>
          Sedang mengambil data...
          <v-progress-linear
            indeterminate
            color="primary"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "JalurPenerimaan",
  data: () => ({
    expanded: [],
    date: new Date().toISOString().substr(0, 10),
    loading: false,
    loader: false,
    dialogLoading: false,
    menu: false,
    menu2: false,
    dialog: false,
    dialogDelete: false,
    valid: true,
    headers: [
      { text: "", value: "data-table-expand" },
      { text: "No", value: "nomor" },
      {
        text: "Nama",
        align: "left",
        value: "NAME",
      },
      { text: "Buka", value: "OPEN" },
      { text: "Tutup", value: "CLOSE" },
      { text: "Kuota", value: "QUOTA" },
      { text: "Cadangan", value: "BACKUP" },
      { text: "Status", value: "STATUS" },
      { text: "Persyaratan", value: "persyaratan" },
      // { text: "Scoring", value: "scoring" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    cbtAuto: 0,
    admission: [],
    cbt: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    statusItems: [
      { id: 1, name: "Buka" },
      { id: 0, name: "Tutup" },
    ],
    typeItems: [
      { id: "1", name: "Jalur Prestasi" },
      { id: "2", name: "Jalur Zonasi" },
      { id: "3", name: "Jalur Afirmasi" },
      { id: "4", name: "Jalur Perpindahan" },
      { id: "5", name: "Jalur Inklusive" },
    ],
    jenjangItems: [{ bentuk_pendidikan_id: "", nama: "" }],
    rules: [(v) => !!v || "Form ini wajib di isi"],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Data" : "Edit Data";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 6000);

      this.loader = null;
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
    }),

    initialize() {
      this.getAdmission();
      this.getCbt();
      this.getJenjang();
    },

    getAdmission() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post("/api/auth/admission", {}, config)
        .then((response) => {
          this.$vloading.hide();
          this.admission = response.data.data;
          console.log(this.admission);
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log("error : " + error);
          let { data } = error.response;
          this.setAlert({
            status: true,
            color: "error",
            text: data.message,
          });
        });
    },

    getCbt() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post("/api/cbtList", {}, config)
        .then((response) => {
          this.$vloading.hide();
          let data = response.data.data;
          data.unshift({ id: 0, NAME: "Tidak" });
          // console.log(data);
          this.cbt = data;
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log("error : " + error);
          let { data } = error.response;
          this.setAlert({
            status: true,
            color: "error",
            text: data.message,
          });
        });
    },

    getJenjang() {
      this.$vloading.show();
      // console.log("getKabkot");
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post("/api/jenjang", {}, config)
        .then((response) => {
          this.$vloading.hide();
          // console.log(response.data);
          this.jenjangItems = response.data;
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log("error" + error);
          let { data } = error.response;
          this.setAlert({
            status: true,
            color: "error",
            text: data.message,
          });
        });
    },

    editItem(item) {
      this.editedIndex = this.admission.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.admission.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$vloading.show();
      // console.log("deleted data", this.editedItem.id);
      // this.admission.splice(this.editedIndex, 1);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("ADMISSION_ID", this.editedItem.id);
      this.axios
        .post("/api/auth/destroyAdmission", formData, config)
        .then(() => {
          this.$vloading.hide();
          // let { data } = response.data;
          //this.setAuth(data);
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Data Berhasil Dihapus!",
          });
          this.close();
          this.getAdmission();
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data,
          });
          this.close();
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          this.$vloading.show();
          // UPDATE DATA
          // console.log("edited data");
          // Object.assign(this.admission[this.editedIndex], this.editedItem);
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.token,
            },
          };
          let formData = new FormData();
          formData.set("ADMISSION_ID", this.editedItem.id);
          formData.set("NAME", this.editedItem.NAME);
          formData.set("OPEN", this.editedItem.OPEN);
          formData.set("CLOSE", this.editedItem.CLOSE);
          formData.set("QUOTA", this.editedItem.QUOTA);
          formData.set("BACKUP", this.editedItem.BACKUP);
          formData.set("CBT", this.editedItem.CBT);
          formData.set("STATUS", this.editedItem.STATUS);
          formData.set("TYPE", this.editedItem.TYPE);
          formData.set("LEVEL", this.editedItem.LEVEL);
          formData.set("DDAY", this.editedItem.DDAY);
          formData.set("RSTART", this.editedItem.RSTART);
          formData.set("REND", this.editedItem.REND);
          // console.log(formData.getAll("STATUS"));
          this.axios
            .post("/api/auth/saveAdmission", formData, config)
            .then(() => {
              this.$vloading.hide();
              // let { data } = response.data;
              //this.setAuth(data);
              // console.log(response);
              this.setAlert({
                status: true,
                color: "success",
                text: "Data Berhasil Diubah!",
              });
              this.close();
              this.getAdmission();
            })
            .catch((error) => {
              this.$vloading.hide();
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data,
              });
              this.close();
            });
        } else {
          this.$vloading.show();
          // CREATE DATA
          // console.log("created data");
          // this.admission.push(this.editedItem);
          // console.log(this.editedItem.STATUS);
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.token,
            },
          };
          let formData = new FormData();
          formData.set("NAME", this.editedItem.NAME);
          formData.set("OPEN", this.editedItem.OPEN);
          formData.set("CLOSE", this.editedItem.CLOSE);
          formData.set("QUOTA", this.editedItem.QUOTA);
          formData.set("BACKUP", this.editedItem.BACKUP);
          formData.set("CBT", this.cbtAuto);
          formData.set("TYPE", this.editedItem.TYPE);
          formData.set("LEVEL", this.editedItem.LEVEL);
          formData.set("DDAY", this.editedItem.DDAY);
          formData.set("RSTART", this.editedItem.RSTART);
          formData.set("REND", this.editedItem.REND);
          // formData.set("STATUS", this.editedItem.STATUS);
          // console.log(formData.getAll("STATUS"));
          this.axios
            .post("/api/auth/saveAdmission", formData, config)
            .then(() => {
              this.$vloading.hide();
              // let { data } = response.data;
              //this.setAuth(data);
              // console.log(response);
              this.setAlert({
                status: true,
                color: "success",
                text: "Data Berhasil Ditambahkan!",
              });
              this.close();
              this.getAdmission();
            })
            .catch((error) => {
              this.$vloading.hide();
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data,
              });
              this.close();
            });
        }
        this.closes();
      }
    },

    reqList(item) {
      // console.log(item.id);
      localStorage.admissionID = item.id;
      localStorage.admissionNAME = item.NAME;
      this.$router.replace({ name: "Persyaratan Jalur" });
    },

    sekolahList(item) {
      // console.log(item.id);
      localStorage.admissionID = item.id;
      localStorage.admissionNAME = item.NAME;
      this.$router.replace({ name: "Sekolah Jalur" });
    },

    scoreList(item) {
      // console.log(item.id);
      localStorage.admissionID = item.id;
      localStorage.admissionNAME = item.NAME;
      this.$router.replace({ name: "Cbt Nilai" });
    },

    closes() {
      this.$emit("closed", false);
    },
  },
};
</script>
