<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Masuk aplikasi PPDB!</v-toolbar-title>
    </v-toolbar>

    <v-divider></v-divider>

    <v-container fluid>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          v-model="username"
          :rules="usernameRules"
          label="Username / NISN"
          required
          append-icon="mdi-acoount"
        ></v-text-field>

        <v-text-field
          v-model="password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="passwordRules"
          :type="showPassword ? 'text' : 'password'"
          label="Password"
          counter
          @click:append="showPassword = !showPassword"
        ></v-text-field>

        <div class="text-xs-center">
          <v-btn
            color="primary"
            :disabled="!valid || loading"
            @click="submit"
            :loading="loading"
          >
            Masuk

            <v-icon right dark>mdi-lock-open</v-icon>
          </v-btn>
        </div>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Masuk",
  data() {
    return {
      valid: true,
      loading: false,
      username: "",
      // username: "0057534901",
      usernameRules: [(v) => !!v || "Username / NISN wajib di isi"],
      showPassword: false,
      password: "",
      // password: "qwe12345",
      passwordRules: [
        (v) => !!v || "Password wajib di isi",
        (v) => (v && v.length >= 6) || "Minimal 6 karakter",
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      setAlert: "alert/set",
      setAuth: "auth/set",
    }),
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let formData = {
          username: this.username,
          password: this.password,
        };
        this.axios
          .post("/api/login", formData)
          .then((response) => {
            let { data } = response;
            //console.log(data.data);
            //console.log(data.token);
            this.setAuth(data.data);
            localStorage.studentBio = data.data.studentBio;
            localStorage.temp = data.data.temp;
            localStorage.levelSource = data.data.levelSource;
            if (this.user.id > 0) {
              this.setAlert({
                status: true,
                color: "success",
                text: "Login success",
              });
              this.close();
              this.$router.replace({ name: "Home" });
              this.loading = false;
            } else {
              this.setAlert({
                status: true,
                color: "error",
                text: "Login failed",
              });
              this.loading = false;
            }
          })
          .catch((error) => {
            let responses = error.response;
            this.setAlert({
              status: true,
              text: responses.data,
              color: "error",
            });
            this.loading = false;
          });
      }
    },
    close() {
      this.$emit("closed", false);
    },
  },
};
</script>