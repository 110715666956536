<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="admission"
      class="elevation-1"
      item-key="name"
    >
      <template v-slot:item.nomor="{ item }">
        {{ admission.indexOf(item) + 1 }}
      </template>
      <template v-slot:item.CBT_STATUS="{ item }">
        <span v-if="item.CBT_STATUS == 1">Dibuka</span>
        <span v-else>Ditutup</span>
      </template>
      <template v-slot:item.WAKTU_UJIAN="{ item }">
        <span v-if="item.WAKTU_UJIAN != null">
          {{ item.WAKTU_UJIAN }} Menit
        </span>
        <span v-else> - </span>
      </template>
      <template v-slot:item.CBTSTATUS="{ item }">
        <span v-if="item.CBT > 0">Ya</span>
        <span v-else>Tidak</span>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Asesmen Diagnostik</v-toolbar-title>
          </v-toolbar>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <!-- <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template> -->
            <v-card>
              <v-card-title>
                <span class="headline">Masukkan PIN</span>
              </v-card-title>

              <v-form ref="form" v-model="valid" lazy-validation>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.PIN"
                          :rules="rules"
                          label="PIN"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Batal
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    :disabled="!valid"
                    text
                    @click="save"
                  >
                    Mulai Ujian
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Anda yakin ingin menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Batal</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <!-- <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon> -->
        <!-- Jalur Tutup -->
        <span v-if="item.ADMISSION_OPEN == 0">
          <!-- Jika Jalur Memiliki Ujian CBT -->
          <span v-if="item.CBT > 0">
            <!-- Jika Status CBT Dibuka -->
            <span v-if="item.CBT_STATUS > 0">
              <!-- Jika belum ikut ujian -->
              <span v-if="item.PAKET_STATUS == null">
                <v-btn
                  x-small
                  class="mr-1"
                  rounded
                  color="info"
                  dark
                  @click="editItem(item)"
                >
                  Ikut Ujian
                </v-btn>
              </span>
              <!-- Sudah ikut ujian tapi belum selesai -->
              <span v-if="item.PAKET_STATUS == 0">
                <v-btn
                  x-small
                  class="mr-1"
                  rounded
                  color="warning"
                  dark
                  @click="editItem(item)"
                >
                  Lanjutkan Ujian
                </v-btn>
              </span>
              <!-- Sudah ikut ujian dan sudah selesai -->
              <span v-if="item.PAKET_STATUS == 1">
                <v-btn
                  x-small
                  class="mt-2"
                  depressed
                  rounded
                  color="error"
                  dark
                >
                  Selesai </v-btn
                ><br />
                <small>Score :</small> <b>{{ item.SCORE }}</b>
              </span>
            </span>
          </span>
          <!-- Jika Jalur Tidak Memiliki Ujian CBT -->
        </span>

        <!-- Jalur Buka -->
        <span v-else>
          <!-- Jika Jalur Memiliki Ujian CBT -->
          <!-- <span v-if="item.CBT > 0">
            <v-btn x-small class="mr-1" rounded color="error" dark>
              Tutup
            </v-btn>
          </span> -->
          <!-- Jika Jalur Tidak Memiliki Ujian CBT -->
        </span>
      </template>
      <template v-slot:no-data> Tidak ada data </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "CbtSiswa",
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    menu2: false,
    dialog: false,
    dialogDelete: false,
    valid: true,
    headers: [
      { text: "No", value: "nomor" },
      {
        text: "Nama",
        align: "left",
        value: "NAME",
      },
      { text: "Nomor Registrasi", value: "REGNUMBER" },
      { text: "Ujian CBT", value: "CBTSTATUS" },
      { text: "Waktu Ujian", value: "WAKTU_UJIAN" },
      { text: "Status", value: "CBT_STATUS" },
      // { text: "Aksi", value: "CBT" },
      { text: "Aksi", value: "actions" },
    ],
    admission: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    statusItems: [
      { id: 1, name: "Dibuka" },
      { id: 0, name: "Ditutup" },
    ],
    rules: [(v) => !!v || "Wajib di isi"],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Data" : "Edit Data";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
    }),

    initialize() {
      this.getTestList();
    },

    getTestList() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post("/api/testList", {}, config)
        .then((response) => {
          this.$vloading.hide();
          this.admission = response.data;
          console.log(this.admission);
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log("error : " + error);
          let { data } = error.response;
          this.setAlert({
            status: true,
            color: "error",
            text: data.message,
          });
        });
    },

    editItem(item) {
      this.editedIndex = this.admission.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.admission.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      // console.log("deleted data", this.editedItem.id);
      // this.admission.splice(this.editedIndex, 1);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("ADMISSION_ID", this.editedItem.id);
      this.axios
        .post("/api/auth/destroyAdmission", formData, config)
        .then(() => {
          // let { data } = response.data;
          //this.setAuth(data);
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Data Berhasil Dihapus!",
          });
          this.close();
          this.getTestList();
        })
        .catch((error) => {
          let responses = error;
          // console.log(responses);
          // let { data } = error.response;
          this.setAlert({
            status: true,
            color: "error",
            text: responses.data.errors,
          });
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          // UPDATE DATA
          // console.log("edited data");
          // Object.assign(this.admission[this.editedIndex], this.editedItem);
          this.$vloading.show();
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.token,
            },
          };
          let formData = new FormData();
          formData.set("ADMISSIONCHOICEID", this.editedItem.ADMISSIONID);
          formData.set("PIN", this.editedItem.PIN);
          // formData.set("OPEN", this.editedItem.OPEN);
          // formData.set("CLOSE", this.editedItem.CLOSE);
          // formData.set("QUOTA", this.editedItem.QUOTA);
          // formData.set("CBT", this.editedItem.CBT);
          // formData.set("STATUS", this.editedItem.STATUS);
          // console.log(formData.getAll("STATUS"));
          this.axios
            .post("/api/verifyPIN", formData, config)
            .then(() => {
              this.$vloading.hide();
              // let { data } = response.data;
              //this.setAuth(data);
              // console.log(response.data);
              this.setAlert({
                status: true,
                color: "success",
                text: "PIN Terverifikasi!",
              });
              this.close();

              localStorage.admissionID = this.editedItem.ADMISSIONID;
              localStorage.admissionNAME = this.editedItem.NAME;
              localStorage.admissionTIME = this.editedItem.WAKTU_UJIAN;
              localStorage.admissionPIN = this.editedItem.PIN;
              this.$router.replace({ name: "Ujian Cbt Siswa" });
            })
            .catch((error) => {
              this.$vloading.hide();
              let responses = error;
              console.log("error: " + responses);
              // let { data } = error.response;
              this.setAlert({
                status: true,
                color: "error",
                text: "PIN Salah! Silahkan hubungi operator",
              });
            });
        } else {
          // CREATE DATA
          // console.log("created data");
          // this.admission.push(this.editedItem);
          // console.log(this.editedItem.STATUS);
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.token,
            },
          };
          let formData = new FormData();
          formData.set("NAME", this.editedItem.NAME);
          formData.set("OPEN", this.editedItem.OPEN);
          formData.set("CLOSE", this.editedItem.CLOSE);
          formData.set("QUOTA", this.editedItem.QUOTA);
          formData.set("CBT", this.editedItem.CBT);
          // formData.set("STATUS", this.editedItem.STATUS);
          // console.log(formData.getAll("STATUS"));
          this.axios
            .post("/api/auth/saveAdmission", formData, config)
            .then(() => {
              // let { data } = response.data;
              //this.setAuth(data);
              // console.log(response);
              this.setAlert({
                status: true,
                color: "success",
                text: "Data Berhasil Ditambahkan!",
              });
              this.close();
              this.getTestList();
            })
            .catch((error) => {
              let responses = error;
              // console.log(responses);
              // let { data } = error.response;
              this.setAlert({
                status: true,
                color: "error",
                text: responses.data.errors,
              });
            });
        }
        this.closes();
      }
    },

    reqList(item) {
      // console.log(item.id);
      localStorage.admissionID = item.ADMISSIONID;
      localStorage.admissionNAME = item.NAME;
      localStorage.admissionTIME = item.WAKTU_UJIAN;
      this.$router.replace({ name: "Ujian Cbt Siswa" });
    },

    closes() {
      this.$emit("closed", false);
    },
  },
};
</script>