<template>
  <center>
    <div class="text-center">
      <div class="my-2">
        <v-btn color="error" dark large @click="reset()">
          Tombol Reset Scoring
        </v-btn>
      </div>
    </div>
  </center>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Testing",
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    loading: false,
    dialogLoading: false,
    menu: false,
    menu2: false,
    dialog: false,
    dialogDelete: false,
    valid: true,
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Data" : "Edit Data";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
    }),

    initialize() {},

    reset() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post("/api/auth/resetScoring", {}, config)
        .then((response) => {
          this.$vloading.hide();
          // let { data } = response.data;
          //this.setAuth(data);
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: response.data,
          });
          this.close();
          this.getAdmission();
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data,
          });
          this.close();
          console.log(error);
        });
      this.closeDelete();
    },

    editItem(item) {
      this.editedIndex = this.admission.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.admission.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$vloading.show();
      // console.log("deleted data", this.editedItem.id);
      // this.admission.splice(this.editedIndex, 1);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("ADMISSION_ID", this.editedItem.id);
      this.axios
        .post("/api/auth/destroyAdmission", formData, config)
        .then(() => {
          this.$vloading.hide();
          // let { data } = response.data;
          //this.setAuth(data);
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Data Berhasil Dihapus!",
          });
          this.close();
          this.getAdmission();
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data,
          });
          this.close();
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          this.$vloading.show();
          // UPDATE DATA
          // console.log("edited data");
          // Object.assign(this.admission[this.editedIndex], this.editedItem);
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.token,
            },
          };
          let formData = new FormData();
          formData.set("ADMISSION_ID", this.editedItem.id);
          formData.set("NAME", this.editedItem.NAME);
          formData.set("OPEN", this.editedItem.OPEN);
          formData.set("CLOSE", this.editedItem.CLOSE);
          formData.set("QUOTA", this.editedItem.QUOTA);
          formData.set("BACKUP", this.editedItem.BACKUP);
          formData.set("CBT", this.editedItem.CBT);
          formData.set("STATUS", this.editedItem.STATUS);
          formData.set("TYPE", this.editedItem.TYPE);
          formData.set("LEVEL", this.editedItem.LEVEL);
          formData.set("DDAY", this.editedItem.DDAY);
          formData.set("RSTART", this.editedItem.RSTART);
          formData.set("REND", this.editedItem.REND);
          // console.log(formData.getAll("STATUS"));
          this.axios
            .post("/api/auth/saveAdmission", formData, config)
            .then(() => {
              this.$vloading.hide();
              // let { data } = response.data;
              //this.setAuth(data);
              // console.log(response);
              this.setAlert({
                status: true,
                color: "success",
                text: "Data Berhasil Diubah!",
              });
              this.close();
              this.getAdmission();
            })
            .catch((error) => {
              this.$vloading.hide();
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data,
              });
              this.close();
            });
        } else {
          this.$vloading.show();
          // CREATE DATA
          // console.log("created data");
          // this.admission.push(this.editedItem);
          // console.log(this.editedItem.STATUS);
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.token,
            },
          };
          let formData = new FormData();
          formData.set("NAME", this.editedItem.NAME);
          formData.set("OPEN", this.editedItem.OPEN);
          formData.set("CLOSE", this.editedItem.CLOSE);
          formData.set("QUOTA", this.editedItem.QUOTA);
          formData.set("BACKUP", this.editedItem.BACKUP);
          formData.set("CBT", this.cbtAuto);
          formData.set("TYPE", this.editedItem.TYPE);
          formData.set("LEVEL", this.editedItem.LEVEL);
          formData.set("DDAY", this.editedItem.DDAY);
          formData.set("RSTART", this.editedItem.RSTART);
          formData.set("REND", this.editedItem.REND);
          // formData.set("STATUS", this.editedItem.STATUS);
          // console.log(formData.getAll("STATUS"));
          this.axios
            .post("/api/auth/saveAdmission", formData, config)
            .then(() => {
              this.$vloading.hide();
              // let { data } = response.data;
              //this.setAuth(data);
              // console.log(response);
              this.setAlert({
                status: true,
                color: "success",
                text: "Data Berhasil Ditambahkan!",
              });
              this.close();
              this.getAdmission();
            })
            .catch((error) => {
              this.$vloading.hide();
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data,
              });
              this.close();
            });
        }
        this.closes();
      }
    },

    reqList(item) {
      // console.log(item.id);
      localStorage.admissionID = item.id;
      localStorage.admissionNAME = item.NAME;
      this.$router.replace({ name: "Persyaratan Jalur" });
    },

    sekolahList(item) {
      // console.log(item.id);
      localStorage.admissionID = item.id;
      localStorage.admissionNAME = item.NAME;
      this.$router.replace({ name: "Sekolah Jalur" });
    },

    scoringAdmissionOne(item) {
      // console.log(item.id);
      this.$vloading.show();
      // console.log(item.id);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("ADMISSIONID", item.id);
      formData.set("PRIORITY", "1");
      this.axios
        .post("/api/auth/scoringAdmission", formData, config)
        .then((response) => {
          this.$vloading.hide();
          console.log(response.data);
          this.setAlert({
            status: true,
            color: "success",
            text: response.data.message,
          });
          this.close();
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data,
          });
          this.close();
        });
    },

    scoringAdmissionTwo(item) {
      // console.log(item.id);
      this.$vloading.show();
      // console.log(item.id);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("ADMISSIONID", item.id);
      formData.set("PRIORITY", "2");
      this.axios
        .post("/api/auth/scoringAdmission", formData, config)
        .then((response) => {
          this.$vloading.hide();
          console.log(response.data);
          this.setAlert({
            status: true,
            color: "success",
            text: response.data.message,
          });
          this.close();
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data,
          });
          this.close();
        });
    },

    scoringAdmissionThree(item) {
      // console.log(item.id);
      this.$vloading.show();
      // console.log(item.id);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("ADMISSIONID", item.id);
      formData.set("PRIORITY", "3");
      this.axios
        .post("/api/auth/scoringAdmission", formData, config)
        .then((response) => {
          this.$vloading.hide();
          console.log(response.data);
          this.setAlert({
            status: true,
            color: "success",
            text: response.data.message,
          });
          this.close();
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data,
          });
          this.close();
        });
    },

    scoringAdmissionOneTemp(item) {
      // console.log(item.id);
      this.$vloading.show();
      // console.log(item.id);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("ADMISSIONID", item.id);
      formData.set("PRIORITY", "1");
      this.axios
        .post("/api/auth/tempScoring", formData, config)
        .then((response) => {
          this.$vloading.hide();
          console.log(response.data);
          this.setAlert({
            status: true,
            color: "success",
            text: response.data.message,
          });
          this.close();
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data,
          });
          this.close();
        });
    },

    scoringAdmissionTwoTemp(item) {
      // console.log(item.id);
      this.$vloading.show();
      // console.log(item.id);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("ADMISSIONID", item.id);
      formData.set("PRIORITY", "2");
      this.axios
        .post("/api/auth/tempScoring", formData, config)
        .then((response) => {
          this.$vloading.hide();
          console.log(response.data);
          this.setAlert({
            status: true,
            color: "success",
            text: response.data.message,
          });
          this.close();
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data,
          });
          this.close();
        });
    },

    scoringAdmissionThreeTemp(item) {
      // console.log(item.id);
      this.$vloading.show();
      // console.log(item.id);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("ADMISSIONID", item.id);
      formData.set("PRIORITY", "3");
      this.axios
        .post("/api/auth/tempScoring", formData, config)
        .then((response) => {
          this.$vloading.hide();
          console.log(response.data);
          this.setAlert({
            status: true,
            color: "success",
            text: response.data.message,
          });
          this.close();
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data,
          });
          this.close();
        });
    },

    closes() {
      this.$emit("closed", false);
    },
  },
};
</script>

