<template>
  <div>
    <!-- <br />
    <center>
      <v-alert color="warning" dark width="80%" prominent align="center">
        UNTUK MENGETAHUI HASIL PENERIMAAN SILAKAN LOGIN KE AKUN PESERTA DIDIK.
      </v-alert>
    </center> -->
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Hasil Penerimaan {{ appName }}</v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-container fluid class="pa-10">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row align="center">
            <v-col cols="12">
              <v-select
                v-model="filterJalur"
                :items="jalurItems"
                :rules="rules"
                dense
                item-value="id"
                item-text="NAME"
                label="Pilih Jalur"
                v-on:change="jalurChange"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="filterSekolah"
                :items="sekolahItems"
                :rules="rules"
                dense
                item-value="SCHOOL_ID"
                item-text="NAME"
                label="Pilih Sekolah"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <div class="text-center">
                <v-btn color="success" :disabled="!valid" @click="submit">
                  <v-icon left dark>mdi-text-search</v-icon>
                  Check
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
    <br />
    <v-data-table
      :headers="headers"
      :items="admission"
      class="elevation-1"
      item-key="name"
      :search="search"
    >
      <template v-slot:item.nomor="{ item }">
        {{ admission.indexOf(item) + 1 }}
      </template>
      <template v-slot:item.PILIHAN="{ item }">
        <ul class="pa-1">
          <span v-for="dt in item.PILIHAN" :key="dt.id">
            <li>{{ dt.NAME }}</li>
          </span>
        </ul>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar dark color="success">
            <v-toolbar-title
              >Peserta Didik Diterima</v-toolbar-title
            >
          </v-toolbar>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-text-field v-model="search" label="Cari" class="mx-4"></v-text-field>
      </template>
      <template v-slot:no-data> Tidak ada data </template>
    </v-data-table>

    <!-- LOADER -->
    <v-dialog v-model="dialogLoading" persistent width="300">
      <v-card>
        <v-card-text>
          Sedang mengambil data...
          <v-progress-linear
            indeterminate
            color="primary"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "HasilPenerimaan",
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    dialogLoading: false,
    menu2: false,
    dialog: false,
    dialogDelete: false,
    valid: true,
    search: "",
    status: "",
    headers: [
      { text: "No", value: "nomor" },
      {
        text: "Nomor Registrasi",
        align: "left",
        value: "admission.REGNUMBER",
      },
      { text: "Nama Peserta Didik", value: "student.NAME" },
      // { text: "Actions", value: "actions", sortable: false },
    ],
    admission: [],
    sekolah: [],
    jalurItems: [],
    sekolahItems: [],
    filterJalur: null,
    filterSekolah: null,
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    statusItems: [
      { id: 1, name: "Dibuka" },
      { id: 0, name: "Ditutup" },
    ],
    rules: [(v) => !!v || "Form ini wajib di isi"],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Data" : "Edit Data";
    },
    passwordConfirmationRule() {
      return () => this.password === this.confpassword || "Password harus sama";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
    }),
    initialize() {
      this.getJalur();
    },
    getJalur() {
      this.$vloading.show();
      let config = {
        headers: {},
      };
      this.axios
        .post("/api/getAdmission", {}, config)
        .then((response) => {
          this.jalurItems = response.data;
          // console.log(this.jalurItems);
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log("error : " + error);
          let { data } = error.response;
          this.setAlert({
            status: true,
            color: "error",
            text: data.message,
          });
        });
    },
    getSekolah(e) {
      this.$vloading.show();
      let config = {
        headers: {},
      };
      let formData = new FormData();
      formData.set("ADMISSIONID", e);
      this.axios
        .post("/api/getActiveSchool", formData, config)
        .then((response) => {
          this.sekolahItems = response.data.data;
          // console.log(this.sekolahItems);
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log("error : " + error);
          let { data } = error.response;
          this.setAlert({
            status: true,
            color: "error",
            text: data.message,
          });
        });
    },
    jalurChange: function (e) {
      // console.log(e);
      this.getSekolah(e);
    },
    editItem(item) {
      this.editedIndex = this.admission.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.admission.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.$vloading.show();
        let config = {
          headers: {},
        };
        let formData = new FormData();
        formData.set("NPSN", this.filterSekolah);
        formData.set("ADMISSIONID", this.filterJalur);
        this.axios
          .post("/api/resultAccepted", formData, config)
          .then((response) => {
            this.admission = response.data.data;
            // this.status = response.data.dataStatus;
            this.$vloading.hide();
            // console.log(this.admission);
            // console.log(this.status);
          })
          .catch((error) => {
            console.log(error.response.data);
            this.$vloading.hide();
            this.admission = [];
            this.status = [];
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.message,
            });
            this.close();
          });
      }
      this.closes();
    },
    reqList(item) {
      // console.log(item.id);
      localStorage.bankID = item.id;
      localStorage.bankNAME = item.NAME;
      this.$router.replace({ name: "Soal" });
    },
    closes() {
      this.$emit("closed", false);
    },
  },
};
</script>