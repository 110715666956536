<template>
  <div>
    <v-container class="ma-0 pa-1" grid-list-sm>
      <div class="text-left">
        <v-btn small text to="/jalur" class="blue--text">
          <v-icon>mdi-chevron-left</v-icon> Kembali
        </v-btn>
      </div>
    </v-container>
    <v-data-table
      :headers="headers"
      :items="admission"
      class="elevation-1"
      item-key="name"
      :search="search"
    >
      <template v-slot:item.nomor="{ item }">
        {{ admission.indexOf(item) + 1 }}
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Sekolah Aktif {{ admissionNAME }}</v-toolbar-title>
          </v-toolbar>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-form ref="form" v-model="valid" lazy-validation>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12" v-if="editedIndex > -1">
                        <v-autocomplete
                          v-model="editedItem.SCHOOL_ID"
                          :items="sekolahEdit"
                          :rules="rules"
                          dense
                          item-value="NPSN"
                          item-text="NAME"
                          label="Sekolah"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" v-else>
                        <v-autocomplete
                          v-model="sklh"
                          :items="sekolah"
                          :rules="rules"
                          dense
                          item-value="NPSN"
                          item-text="NAME"
                          label="Pilih Sekolah"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.QUOTA"
                          :rules="rules"
                          type="number"
                          label="Kuota"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Batal
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    :disabled="!valid"
                    text
                    @click="save"
                  >
                    Simpan
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Anda yakin ingin menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Batal</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-text-field
          v-model="search"
          label="Cari Sekolah"
          class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data> Belum ada data sekolah ditambahkan </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "SekolahJalur",
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    menu2: false,
    dialog: false,
    dialogDelete: false,
    valid: true,
    search: "",
    admissionID: localStorage.getItem("admissionID"),
    admissionNAME: localStorage.getItem("admissionNAME"),
    headers: [
      { text: "No", value: "nomor" },
      {
        text: "Nama Sekolah",
        align: "left",
        value: "NAME",
      },
      { text: "NPSN", value: "SCHOOL_ID" },
      { text: "Kuota", value: "QUOTA" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    admission: [],
    sekolah: [],
    sekolahEdit: [],
    sklh: null,
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    statusItems: [
      { id: 1, name: "Wajib" },
      { id: 0, name: "Tidak Wajib" },
    ],
    rules: [(v) => !!v || "Form ini wajib di isi"],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Data" : "Edit Data";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
    }),

    initialize() {
      this.getActiveSchool();
    },

    getActiveSchool() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post(
          "/api/auth/activeSchool/" + localStorage.getItem("admissionID"),
          {},
          config
        )
        .then((response) => {
          this.$vloading.hide();
          this.admission = response.data.data;
          //console.log(this.info);
          var dt = response.data.data;
          this.getSchool(dt);
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log("error : " + error);
          if (error == "Error: Request failed with status code 404") {
            // console.log("benar");
            var dt = 0;
            this.getSchool(dt);
          }
        });
    },

    getSchool(dt) {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post("/api/schoolList", {}, config)
        .then((response) => {
          this.$vloading.hide();
          var a = response.data.data;
          var b = dt;

          // console.log(a);
          // console.log(b);

          if (b == "0") {
            this.sekolah = a;
            // console.log(this.sekolah);
          } else {
            var c = a.filter(function(objFromA) {
              return !b.find(function(objFromB) {
                return objFromA.NPSN === objFromB.SCHOOL_ID;
              });
            });
            this.sekolah = c;
            // console.log(this.sekolah);
          }
          // console.log(c);
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log("error : " + error);
          let { data } = error.response;
          this.setAlert({
            status: true,
            color: "error",
            text: data.message,
          });
        });
    },

    getSchoolEdit() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post("/api/schoolList", {}, config)
        .then((response) => {
          this.$vloading.hide();
          this.sekolahEdit = response.data.data;
          console.log(this.sekolahEdit);
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log("error : " + error);
          let { data } = error.response;
          this.setAlert({
            status: true,
            color: "error",
            text: data.message,
          });
        });
    },

    editItem(item) {
      this.editedIndex = this.admission.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.getSchoolEdit();
      console.log("tambah");
    },

    deleteItem(item) {
      this.editedIndex = this.admission.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      // console.log("deleted data", this.editedItem.id);
      // this.admission.splice(this.editedIndex, 1);
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post("/api/auth/destroyActiveSchool/" + this.editedItem.id, {}, config)
        .then(() => {
          this.$vloading.hide();
          // let { data } = response.data;
          //this.setAuth(data);
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Data Berhasil Dihapus!",
          });
          this.close();
          this.getActiveSchool();
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data,
          });
          this.close();
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          // UPDATE DATA
          // console.log("edited data");
          // Object.assign(this.admission[this.editedIndex], this.editedItem);
          this.$vloading.show();
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.token,
            },
          };
          let formData = new FormData();
          formData.set("ADMISSION", localStorage.getItem("admissionID"));
          formData.set("SCHOOL_ID", this.editedItem.SCHOOL_ID);
          formData.set("QUOTA", this.editedItem.QUOTA);
          // formData.set("STATUS", this.editedItem.STATUS);
          // console.log(formData.getAll("STATUS"));
          this.axios
            .post(
              "/api/auth/saveActiveSchool/" + this.editedItem.id,
              formData,
              config
            )
            .then(() => {
              this.$vloading.hide();
              // let { data } = response.data;
              //this.setAuth(data);
              // console.log(response);
              this.setAlert({
                status: true,
                color: "success",
                text: "Data Berhasil Diubah!",
              });
              this.close();
              this.getActiveSchool();
            })
            .catch(() => {
              this.$vloading.hide();
              //   let responses = error;
              // console.log(responses);
              // let { data } = error.response;
              this.setAlert({
                status: true,
                color: "error",
                text: "Data Gagal Diubah!",
              });
            });
        } else {
          // CREATE DATA
          // console.log("created data");
          // this.admission.push(this.editedItem);
          // console.log(this.editedItem.STATUS);
          this.$vloading.show();
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.token,
            },
          };
          let formData = new FormData();
          formData.set("ADMISSION", localStorage.getItem("admissionID"));
          formData.set("SCHOOL_ID", this.sklh);
          formData.set("QUOTA", this.editedItem.QUOTA);
          // formData.set("STATUS", this.editedItem.STATUS);
          // console.log(formData.getAll("STATUS"));
          this.axios
            .post("/api/auth/saveActiveSchool", formData, config)
            .then(() => {
              this.$vloading.hide();
              // let { data } = response.data;
              //this.setAuth(data);
              // console.log(response);
              this.setAlert({
                status: true,
                color: "success",
                text: "Data Berhasil Ditambahkan!",
              });
              this.close();
              this.getActiveSchool();
            })
            .catch(() => {
              this.$vloading.hide();
              //   let responses = error;
              //   console.log(responses);
              // let { data } = error.response;
              this.setAlert({
                status: true,
                color: "error",
                text: "Data Gagal Ditambahkan!",
              });
            });
        }
        this.closes();
      }
    },

    reqList(item) {
      // console.log(item.id);
      localStorage.admissionID = item.id;
      this.$router.replace({ name: "Persyaratan Jalur" });
    },

    closes() {
      this.$emit("closed", false);
    },
  },
};
</script>
