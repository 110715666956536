<template>
  <div>
    <v-card
      ><v-toolbar dark color="primary">
        <v-toolbar-title
          >Rekapitulasi
          <v-btn small color="success" dark class="mb-2" @click="makePDF">
            <!-- <download-excel :data="admission" :fields="json_fields">
              <v-icon left>mdi-download</v-icon>Excel
            </download-excel> -->
            <v-icon left>mdi-download</v-icon>Download
          </v-btn>
        </v-toolbar-title>
      </v-toolbar>
      <v-simple-table dense fixed-header height="500px">
        <template v-slot:default>
          <thead>
            <tr>
              <th colspan="4"></th>
              <th colspan="3" class="text-center">Jalur Prestasi</th>
              <th colspan="3" class="text-center">Jalur Zonasi</th>
              <th colspan="3" class="text-center">Jalur Afirmasi</th>
              <th colspan="3" class="text-center">Jalur Perpindahan</th>
              <th colspan="3" class="text-center">Jalur Inklusi</th>
            </tr>
            <tr>
              <th class="text-left">No</th>
              <th class="text-left">Nama Sekolah</th>
              <th>Daya Tampung</th>
              <th>Daftar Ulang</th>
              <th>Kuota</th>
              <th>Peminat</th>
              <th>Diterima</th>
              <th>Kuota</th>
              <th>Peminat</th>
              <th>Diterima</th>
              <th>Kuota</th>
              <th>Peminat</th>
              <th>Diterima</th>
              <th>Kuota</th>
              <th>Peminat</th>
              <th>Diterima</th>
              <th>Kuota</th>
              <th>Peminat</th>
              <th>Diterima</th>
            </tr>
            <tr></tr>
          </thead>
          <tbody>
            <tr v-for="key in admission" :key="key.id">
              <td>
                {{ key.id }}
              </td>
              <td>
                {{ key.NAME }}
              </td>
              <td class="text-center">
                {{ key.DAYA_TAMPUNG }}
              </td>
              <td>
                {{ key.registrasi_ulang_count }}
              </td>
              <td class="text-center">
                <!-- Jika Level SMP -->
                <span v-if="key.LEVEL === 6">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 1">
                      {{ ss.ADMISSION_QUOTA }}
                    </span>
                  </span>
                </span>

                <!-- Jika Level SD -->
                <span v-if="key.LEVEL === 5">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 11">
                      {{ ss.ADMISSION_QUOTA }}
                    </span>
                  </span>
                </span>
              </td>
              <td class="text-center">
                <!-- Jika Level SMP -->
                <span v-if="key.LEVEL === 6">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 1">
                      {{ ss.PEMINAT }}
                    </span>
                  </span>
                </span>

                <!-- Jika Level SD -->
                <span v-if="key.LEVEL === 5">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 11">
                      {{ ss.PEMINAT }}
                    </span>
                  </span>
                </span>
              </td>
              <td class="text-center">
                <!-- Jika Level SMP -->
                <span v-if="key.LEVEL === 6">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 1">
                      {{ ss.DITERIMA }}
                    </span>
                  </span>
                </span>

                <!-- Jika Level SD -->
                <span v-if="key.LEVEL === 5">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 11">
                      {{ ss.DITERIMA }}
                    </span>
                  </span>
                </span>
              </td>
              <td class="text-center">
                <!-- Jika Level SMP -->
                <span v-if="key.LEVEL === 6">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 2">
                      {{ ss.ADMISSION_QUOTA }}
                    </span>
                  </span>
                </span>

                <!-- Jika Level SD -->
                <span v-if="key.LEVEL === 5">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 5">
                      {{ ss.ADMISSION_QUOTA }}
                    </span>
                  </span></span
                >
              </td>
              <td class="text-center">
                <!-- Jika Level SMP -->
                <span v-if="key.LEVEL === 6">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 2">
                      {{ ss.PEMINAT }}
                    </span>
                  </span>
                </span>

                <!-- Jika Level SD -->
                <span v-if="key.LEVEL === 5">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 5">
                      {{ ss.PEMINAT }}
                    </span>
                  </span></span
                >
              </td>
              <td class="text-center">
                <!-- Jika Level SMP -->
                <span v-if="key.LEVEL === 6">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 2">
                      {{ ss.DITERIMA }}
                    </span>
                  </span>
                </span>

                <!-- Jika Level SD -->
                <span v-if="key.LEVEL === 5">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 5">
                      {{ ss.DITERIMA }}
                    </span>
                  </span>
                </span>
              </td>
              <td class="text-center">
                <!-- Jika Level SMP -->
                <span v-if="key.LEVEL === 6">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 3">
                      {{ ss.ADMISSION_QUOTA }}
                    </span>
                  </span>
                </span>

                <!-- Jika Level SD -->
                <span v-if="key.LEVEL === 5">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 6">
                      {{ ss.ADMISSION_QUOTA }}
                    </span>
                  </span></span
                >
              </td>
              <td class="text-center">
                <!-- Jika Level SMP -->
                <span v-if="key.LEVEL === 6">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 3">
                      {{ ss.PEMINAT }}
                    </span>
                  </span>
                </span>

                <!-- Jika Level SD -->
                <span v-if="key.LEVEL === 5">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 6">
                      {{ ss.PEMINAT }}
                    </span>
                  </span></span
                >
              </td>
              <td class="text-center">
                <!-- Jika Level SMP -->
                <span v-if="key.LEVEL === 6">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 3">
                      {{ ss.DITERIMA }}
                    </span>
                  </span>
                </span>

                <!-- Jika Level SD -->
                <span v-if="key.LEVEL === 5">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 6">
                      {{ ss.DITERIMA }}
                    </span>
                  </span>
                </span>
              </td>
              <td class="text-center">
                <!-- Jika Level SMP -->
                <span v-if="key.LEVEL === 6">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 4">
                      {{ ss.ADMISSION_QUOTA }}
                    </span>
                  </span>
                </span>

                <!-- Jika Level SD -->
                <span v-if="key.LEVEL === 5">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 7">
                      {{ ss.ADMISSION_QUOTA }}
                    </span>
                  </span></span
                >
              </td>
              <td class="text-center">
                <!-- Jika Level SMP -->
                <span v-if="key.LEVEL === 6">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 4">
                      {{ ss.PEMINAT }}
                    </span>
                  </span>
                </span>

                <!-- Jika Level SD -->
                <span v-if="key.LEVEL === 5">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 7">
                      {{ ss.PEMINAT }}
                    </span>
                  </span></span
                >
              </td>
              <td class="text-center">
                <!-- Jika Level SMP -->
                <span v-if="key.LEVEL === 6">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 4">
                      {{ ss.DITERIMA }}
                    </span>
                  </span>
                </span>

                <!-- Jika Level SD -->
                <span v-if="key.LEVEL === 5">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 7">
                      {{ ss.DITERIMA }}
                    </span>
                  </span>
                </span>
              </td>
              <td class="text-center">
                <!-- Jika Level SMP -->
                <span v-if="key.LEVEL === 6">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 10">
                      {{ ss.ADMISSION_QUOTA }}
                    </span>
                  </span>
                </span>

                <!-- Jika Level SD -->
                <span v-if="key.LEVEL === 5">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 9">
                      {{ ss.ADMISSION_QUOTA }}
                    </span>
                  </span></span
                >
              </td>
              <td class="text-center">
                <!-- Jika Level SMP -->
                <span v-if="key.LEVEL === 6">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 10">
                      {{ ss.PEMINAT }}
                    </span>
                  </span>
                </span>

                <!-- Jika Level SD -->
                <span v-if="key.LEVEL === 5">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 9">
                      {{ ss.PEMINAT }}
                    </span>
                  </span></span
                >
              </td>
              <td class="text-center">
                <!-- Jika Level SMP -->
                <span v-if="key.LEVEL === 6">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 10">
                      {{ ss.DITERIMA }}
                    </span>
                  </span>
                </span>

                <!-- Jika Level SD -->
                <span v-if="key.LEVEL === 5">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 9">
                      {{ ss.DITERIMA }}
                    </span>
                  </span>
                </span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <br />
    <div id="print" hidden>
      <center>
        <table
          border="1"
          width="100%"
          style="
            border-collapse: collapse;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 11px;
          "
          align="center"
          cellpadding="5px"
        >
          <thead>
            <tr>
              <th colspan="4"></th>
              <th colspan="3" class="text-center">Jalur Prestasi</th>
              <th colspan="3" class="text-center">Jalur Zonasi</th>
              <th colspan="3" class="text-center">Jalur Afirmasi</th>
              <th colspan="3" class="text-center">Jalur Perpindahan</th>
              <th colspan="3" class="text-center">Jalur Inklusi</th>
            </tr>
            <tr>
              <th class="text-left">No</th>
              <th class="text-left">Nama Sekolah</th>
              <th>Daya Tampung</th>
              <th>Daftar Ulang</th>
              <th>Kuota</th>
              <th>Peminat</th>
              <th>Diterima</th>
              <th>Kuota</th>
              <th>Peminat</th>
              <th>Diterima</th>
              <th>Kuota</th>
              <th>Peminat</th>
              <th>Diterima</th>
              <th>Kuota</th>
              <th>Peminat</th>
              <th>Diterima</th>
              <th>Kuota</th>
              <th>Peminat</th>
              <th>Diterima</th>
            </tr>
            <tr></tr>
          </thead>
          <tbody>
            <tr v-for="key in admission" :key="key.id">
              <td>
                {{ key.id }}
              </td>
              <td>
                {{ key.NAME }}
              </td>
              <td class="text-center">
                {{ key.DAYA_TAMPUNG }}
              </td>
              <td>
                {{ key.registrasi_ulang_count }}
              </td>
              <td class="text-center">
                <!-- Jika Level SMP -->
                <span v-if="key.LEVEL === 6">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 1">
                      {{ ss.ADMISSION_QUOTA }}
                    </span>
                  </span>
                </span>

                <!-- Jika Level SD -->
                <span v-if="key.LEVEL === 5">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 11">
                      {{ ss.ADMISSION_QUOTA }}
                    </span>
                  </span>
                </span>
              </td>
              <td class="text-center">
                <!-- Jika Level SMP -->
                <span v-if="key.LEVEL === 6">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 1">
                      {{ ss.PEMINAT }}
                    </span>
                  </span>
                </span>

                <!-- Jika Level SD -->
                <span v-if="key.LEVEL === 5">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 11">
                      {{ ss.PEMINAT }}
                    </span>
                  </span>
                </span>
              </td>
              <td class="text-center">
                <!-- Jika Level SMP -->
                <span v-if="key.LEVEL === 6">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 1">
                      {{ ss.DITERIMA }}
                    </span>
                  </span>
                </span>

                <!-- Jika Level SD -->
                <span v-if="key.LEVEL === 5">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 11">
                      {{ ss.DITERIMA }}
                    </span>
                  </span>
                </span>
              </td>
              <td class="text-center">
                <!-- Jika Level SMP -->
                <span v-if="key.LEVEL === 6">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 2">
                      {{ ss.ADMISSION_QUOTA }}
                    </span>
                  </span>
                </span>

                <!-- Jika Level SD -->
                <span v-if="key.LEVEL === 5">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 5">
                      {{ ss.ADMISSION_QUOTA }}
                    </span>
                  </span></span
                >
              </td>
              <td class="text-center">
                <!-- Jika Level SMP -->
                <span v-if="key.LEVEL === 6">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 2">
                      {{ ss.PEMINAT }}
                    </span>
                  </span>
                </span>

                <!-- Jika Level SD -->
                <span v-if="key.LEVEL === 5">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 5">
                      {{ ss.PEMINAT }}
                    </span>
                  </span></span
                >
              </td>
              <td class="text-center">
                <!-- Jika Level SMP -->
                <span v-if="key.LEVEL === 6">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 2">
                      {{ ss.DITERIMA }}
                    </span>
                  </span>
                </span>

                <!-- Jika Level SD -->
                <span v-if="key.LEVEL === 5">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 5">
                      {{ ss.DITERIMA }}
                    </span>
                  </span>
                </span>
              </td>
              <td class="text-center">
                <!-- Jika Level SMP -->
                <span v-if="key.LEVEL === 6">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 3">
                      {{ ss.ADMISSION_QUOTA }}
                    </span>
                  </span>
                </span>

                <!-- Jika Level SD -->
                <span v-if="key.LEVEL === 5">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 6">
                      {{ ss.ADMISSION_QUOTA }}
                    </span>
                  </span></span
                >
              </td>
              <td class="text-center">
                <!-- Jika Level SMP -->
                <span v-if="key.LEVEL === 6">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 3">
                      {{ ss.PEMINAT }}
                    </span>
                  </span>
                </span>

                <!-- Jika Level SD -->
                <span v-if="key.LEVEL === 5">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 6">
                      {{ ss.PEMINAT }}
                    </span>
                  </span></span
                >
              </td>
              <td class="text-center">
                <!-- Jika Level SMP -->
                <span v-if="key.LEVEL === 6">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 3">
                      {{ ss.DITERIMA }}
                    </span>
                  </span>
                </span>

                <!-- Jika Level SD -->
                <span v-if="key.LEVEL === 5">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 6">
                      {{ ss.DITERIMA }}
                    </span>
                  </span>
                </span>
              </td>
              <td class="text-center">
                <!-- Jika Level SMP -->
                <span v-if="key.LEVEL === 6">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 4">
                      {{ ss.ADMISSION_QUOTA }}
                    </span>
                  </span>
                </span>

                <!-- Jika Level SD -->
                <span v-if="key.LEVEL === 5">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 7">
                      {{ ss.ADMISSION_QUOTA }}
                    </span>
                  </span></span
                >
              </td>
              <td class="text-center">
                <!-- Jika Level SMP -->
                <span v-if="key.LEVEL === 6">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 4">
                      {{ ss.PEMINAT }}
                    </span>
                  </span>
                </span>

                <!-- Jika Level SD -->
                <span v-if="key.LEVEL === 5">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 7">
                      {{ ss.PEMINAT }}
                    </span>
                  </span></span
                >
              </td>
              <td class="text-center">
                <!-- Jika Level SMP -->
                <span v-if="key.LEVEL === 6">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 4">
                      {{ ss.DITERIMA }}
                    </span>
                  </span>
                </span>

                <!-- Jika Level SD -->
                <span v-if="key.LEVEL === 5">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 7">
                      {{ ss.DITERIMA }}
                    </span>
                  </span>
                </span>
              </td>
              <td class="text-center">
                <!-- Jika Level SMP -->
                <span v-if="key.LEVEL === 6">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 10">
                      {{ ss.ADMISSION_QUOTA }}
                    </span>
                  </span>
                </span>

                <!-- Jika Level SD -->
                <span v-if="key.LEVEL === 5">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 9">
                      {{ ss.ADMISSION_QUOTA }}
                    </span>
                  </span></span
                >
              </td>
              <td class="text-center">
                <!-- Jika Level SMP -->
                <span v-if="key.LEVEL === 6">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 10">
                      {{ ss.PEMINAT }}
                    </span>
                  </span>
                </span>

                <!-- Jika Level SD -->
                <span v-if="key.LEVEL === 5">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 9">
                      {{ ss.PEMINAT }}
                    </span>
                  </span></span
                >
              </td>
              <td class="text-center">
                <!-- Jika Level SMP -->
                <span v-if="key.LEVEL === 6">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 10">
                      {{ ss.DITERIMA }}
                    </span>
                  </span>
                </span>

                <!-- Jika Level SD -->
                <span v-if="key.LEVEL === 5">
                  <span v-for="ss in key.active_school" :key="ss.id">
                    <span v-if="ss.ADMISSION === 9">
                      {{ ss.DITERIMA }}
                    </span>
                  </span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </center>
    </div>
    <!-- LOADER -->
    <v-dialog v-model="dialogLoading" persistent width="300">
      <v-card>
        <v-card-text>
          Sedang mengambil data...
          <v-progress-linear
            indeterminate
            color="primary"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Rekap",
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    loading: false,
    dialogLoading: false,
    menu: false,
    menu2: false,
    dialog: false,
    dialogDelete: false,
    valid: true,
    headers: [
      { text: "No", value: "nomor" },
      {
        text: "Jalur Penerimaan",
        align: "left",
        value: "NAME",
      },
      { text: "Sekolah", value: "sekolah" },
      { text: "Daya Tampung", value: "sekolah" },
    ],
    json_fields: {
      No: "id",
      "Nama Sekolah": "NAME",
      Kuota: "kuota",
      "Jalur Prestasi Peminat": "",
      "Jalur Prestasi Diterima": "",
    },
    cbtAuto: 0,
    admission: [],
    jalur: [],
    jalurAktif: [],
    cbt: [],
    kuota: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    statusItems: [
      { id: 1, name: "Dibuka" },
      { id: 0, name: "Ditutup" },
    ],
    rules: [(v) => !!v || "Form ini wajib di isi"],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Data" : "Edit Data";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
    }),

    initialize() {
      this.getAdmission();
    },

    shorten: function(string, len) {
      return string.substring(0, len + string.substring(len - 1).indexOf(" "));
    },

    makePDF() {
      // window.html2canvas = html2canvas;
      // // var w = document.getElementById("print").offsetWidth;
      // // var h = document.getElementById("print").offsetHeight;
      // var doc = new jsPDF("p", "pt", "a4");
      // doc.html(document.getElementById("print"), {
      //   callback: function (pdf) {
      //     pdf.save("mypdf.pdf");
      //   },
      // });
      var prtContent = document.getElementById("print");
      var WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(prtContent.innerHTML);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },

    getAdmission() {
      this.dialogLoading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post("/api/document/rekap/2", {}, config)
        .then((response) => {
          this.admission = response.data.data;
          console.log(response.data.data);

          // var data = response.data.data;
          // this.kuota = data.map((key) => {
          //   return key.active_school;
          // });
          // // var kuota = data;
          // console.log(this.kuota[0].QUOTA);
        })
        .catch((error) => {
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data,
          });
          this.close();
        })
        .finally(() => {
          this.dialogLoading = false;
        });
    },

    editItem(item) {
      this.editedIndex = this.admission.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.admission.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.loading = true;
      // console.log("deleted data", this.editedItem.id);
      // this.admission.splice(this.editedIndex, 1);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("ADMISSION_ID", this.editedItem.id);
      this.axios
        .post("/api/auth/destroyAdmission", formData, config)
        .then(() => {
          this.loading = false;
          // let { data } = response.data;
          //this.setAuth(data);
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Data Berhasil Dihapus!",
          });
          this.close();
          this.getAdmission();
        })
        .catch((error) => {
          this.loading = false;
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data,
          });
          this.close();
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          this.loading = true;
          // UPDATE DATA
          // console.log("edited data");
          // Object.assign(this.admission[this.editedIndex], this.editedItem);
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.token,
            },
          };
          let formData = new FormData();
          formData.set("ADMISSION_ID", this.editedItem.id);
          formData.set("NAME", this.editedItem.NAME);
          formData.set("OPEN", this.editedItem.OPEN);
          formData.set("CLOSE", this.editedItem.CLOSE);
          formData.set("QUOTA", this.editedItem.QUOTA);
          formData.set("BACKUP", this.editedItem.BACKUP);
          formData.set("CBT", this.editedItem.CBT);
          formData.set("STATUS", this.editedItem.STATUS);
          formData.set("DDAY", this.editedItem.DDAY);
          formData.set("RSTART", this.editedItem.RSTART);
          formData.set("REND", this.editedItem.REND);
          // console.log(formData.getAll("STATUS"));
          this.axios
            .post("/api/auth/saveAdmission", formData, config)
            .then(() => {
              this.loading = false;
              // let { data } = response.data;
              //this.setAuth(data);
              // console.log(response);
              this.setAlert({
                status: true,
                color: "success",
                text: "Data Berhasil Diubah!",
              });
              this.close();
              this.getAdmission();
            })
            .catch((error) => {
              this.loading = false;
              let responses = error;
              // console.log(responses);
              // let { data } = error.response;
              this.setAlert({
                status: true,
                color: "error",
                text: responses.data.errors,
              });
            });
        } else {
          this.loading = true;
          // CREATE DATA
          // console.log("created data");
          // this.admission.push(this.editedItem);
          // console.log(this.editedItem.STATUS);
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.token,
            },
          };
          let formData = new FormData();
          formData.set("NAME", this.editedItem.NAME);
          formData.set("OPEN", this.editedItem.OPEN);
          formData.set("CLOSE", this.editedItem.CLOSE);
          formData.set("QUOTA", this.editedItem.QUOTA);
          formData.set("BACKUP", this.editedItem.BACKUP);
          formData.set("CBT", this.cbtAuto);
          formData.set("DDAY", this.editedItem.DDAY);
          formData.set("RSTART", this.editedItem.RSTART);
          formData.set("REND", this.editedItem.REND);
          // formData.set("STATUS", this.editedItem.STATUS);
          // console.log(formData.getAll("STATUS"));
          this.axios
            .post("/api/auth/saveAdmission", formData, config)
            .then(() => {
              this.loading = false;
              // let { data } = response.data;
              //this.setAuth(data);
              // console.log(response);
              this.setAlert({
                status: true,
                color: "success",
                text: "Data Berhasil Ditambahkan!",
              });
              this.close();
              this.getAdmission();
            })
            .catch((error) => {
              this.loading = false;
              let responses = error;
              // console.log(responses);
              // let { data } = error.response;
              this.setAlert({
                status: true,
                color: "error",
                text: responses.data.errors,
              });
            });
        }
        this.closes();
      }
    },

    reqList(item) {
      // console.log(item.id);
      localStorage.admissionID = item.id;
      localStorage.admissionNAME = item.NAME;
      this.$router.replace({ name: "Persyaratan Jalur" });
    },

    sekolahList(item) {
      // console.log(item.id);
      localStorage.admissionID = item.id;
      localStorage.admissionNAME = item.NAME;
      this.$router.replace({ name: "Sekolah Jalur" });
    },

    scoringAdmission(item) {
      this.dialogLoading = true;
      // console.log(item.id);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("ADMISSIONID", item.id);
      this.axios
        .post("/api/auth/scoringAdmission", formData, config)
        .then((response) => {
          this.dialogLoading = false;
          console.log(response.data);
          this.setAlert({
            status: true,
            color: "success",
            text: response.data.message,
          });
          this.close();
        })
        .catch((error) => {
          this.dialogLoading = false;
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data,
          });
          this.close();
        });
    },

    closes() {
      this.$emit("closed", false);
    },
  },
};
</script>
