<template>
  <div>
    <v-container class="ma-0 pa-1" grid-list-sm>
      <div class="text-left">
        <v-btn small text to="/soal" class="blue--text">
          <v-icon>mdi-chevron-left</v-icon> Kembali ke Soal
        </v-btn>
      </div>
    </v-container>

    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-alert text color="info">
            <h6>({{ bankNAME }})</h6>
            Kategori :
            <span v-if="testCATEGORY == 1">Biasa </span>
            <span v-else> Kompleks </span>
            <br />Soal :
            <v-divider class="pa-1"></v-divider>
            <span v-html="testQUESTION">{{ testQUESTION }}</span>
            <v-img
              :src="baseUrl + urlFoto + testPICTURE"
              v-if="testPICTURE != 'null'"
              max-width="50%"
            ></v-img>
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :headers="headers"
      :items="admission"
      class="elevation-1"
      item-key="name"
    >
      <template v-slot:item.nomor="{ item }">
        {{ admission.indexOf(item) + 1 }}
      </template>
      <template v-slot:item.OPTION="{ item }">
        <span v-html="item.OPTION">
          {{ item.OPTION }}
        </span>
      </template>
      <template v-slot:item.RIGHT="{ item }">
        <span v-if="item.RIGHT == 1"><b>Benar</b></span>
        <span v-else>Salah</span>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Opsi Jawaban</v-toolbar-title>
          </v-toolbar>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-form ref="form" v-model="valid" lazy-validation>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <!-- <v-text-field
                          v-model="editedItem.OPTION"
                          :rules="rules"
                          label="Jawaban"
                        ></v-text-field> -->
                        <quillEditor
                          v-model="editedItem.OPTION"
                          :rules="rules"
                          label="Jawaban"
                          style="min-height: 150px"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-select
                          v-model="editedItem.RIGHT"
                          :items="statusItems"
                          item-value="id"
                          item-text="name"
                          label="Kunci"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Batal
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    :disabled="!valid"
                    text
                    @click="save"
                  >
                    Simpan
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Anda yakin ingin menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Batal</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data> Tidak ada data </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
export default {
  name: "Jawaban",
  components: {
    quillEditor,
  },
  data: () => ({
    // baseUrl: process.env.VUE_APP_API_IMAGEURL,
    // urlFoto: "/storage/app/public/images/questionImages/",
    baseUrl: process.env.VUE_APP_API_URL,
    urlFoto: "/storage/images/questionImages/",
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    menu2: false,
    dialog: false,
    dialogDelete: false,
    valid: true,
    bankID: localStorage.getItem("bankID"),
    bankNAME: localStorage.getItem("bankNAME"),
    testID: localStorage.getItem("testID"),
    testQUESTION: localStorage.getItem("testQUESTION"),
    testPICTURE: localStorage.getItem("testPICTURE"),
    testCATEGORY: localStorage.getItem("testCATEGORY"),
    headers: [
      { text: "No", value: "nomor" },
      {
        text: "Jawaban",
        align: "left",
        value: "OPTION",
      },
      { text: "Kunci", value: "RIGHT" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    admission: [],
    sekolah: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    statusItems: [
      { id: 0, name: "Salah" },
      { id: 1, name: "Benar" },
    ],
    rules: [(v) => !!v || "Form ini wajib di isi"],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Data" : "Edit Data";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
    }),

    initialize() {
      this.getOption();
    },

    getOption() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post("/api/optionList/" + localStorage.getItem("testID"), {}, config)
        .then((response) => {
          this.$vloading.hide();
          this.admission = response.data.data;
          //console.log(this.info);
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log("error : " + error);
          let { data } = error.response;
          this.setAlert({
            status: true,
            color: "error",
            text: data.message,
          });
        });
    },

    editItem(item) {
      this.editedIndex = this.admission.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.admission.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      // console.log("deleted data", this.editedItem.id);
      // this.admission.splice(this.editedIndex, 1);
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post("/api/destroyOption/" + this.editedItem.id, {}, config)
        .then((response) => {
          this.$vloading.hide();
          // let { data } = response.data;
          //this.setAuth(data);
          console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Data Berhasil Dihapus!",
          });
          this.close();
          this.getOption();
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data,
          });
          this.close();
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          // UPDATE DATA
          // console.log("edited data");
          // Object.assign(this.admission[this.editedIndex], this.editedItem);
          this.$vloading.show();
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.token,
            },
          };
          let formData = new FormData();
          formData.set("TESTID", localStorage.getItem("testID"));
          formData.set("OPTION", this.editedItem.OPTION);
          formData.set("RIGHT", this.editedItem.RIGHT);
          // formData.set("STATUS", this.editedItem.STATUS);
          // console.log(formData.getAll("STATUS"));
          this.axios
            .post("/api/saveOption/" + this.editedItem.id, formData, config)
            .then(() => {
              this.$vloading.hide();
              // let { data } = response.data;
              //this.setAuth(data);
              // console.log(response);
              this.setAlert({
                status: true,
                color: "success",
                text: "Data Berhasil Diubah!",
              });
              this.close();
              this.getOption();
            })
            .catch(() => {
              this.$vloading.hide();
              //   let responses = error;
              // console.log(responses);
              // let { data } = error.response;
              this.setAlert({
                status: true,
                color: "error",
                text: "Data Gagal Diubah!",
              });
            });
        } else {
          // CREATE DATA
          // console.log("created data");
          // this.admission.push(this.editedItem);
          // console.log(this.editedItem.STATUS);
          this.$vloading.show();
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.token,
            },
          };
          let formData = new FormData();
          formData.set("TESTID", localStorage.getItem("testID"));
          formData.set("OPTION", this.editedItem.OPTION);
          formData.set("RIGHT", this.editedItem.RIGHT);
          // formData.set("STATUS", this.editedItem.STATUS);
          // console.log(formData.getAll("STATUS"));
          this.axios
            .post("/api/saveOption", formData, config)
            .then(() => {
              this.$vloading.hide();
              // let { data } = response.data;
              //this.setAuth(data);
              // console.log(response);
              this.setAlert({
                status: true,
                color: "success",
                text: "Data Berhasil Ditambahkan!",
              });
              this.close();
              this.getOption();
            })
            .catch(() => {
              this.$vloading.hide();
              //   let responses = error;
              //   console.log(responses);
              // let { data } = error.response;
              this.setAlert({
                status: true,
                color: "error",
                text: "Data Gagal Ditambahkan!",
              });
            });
        }
        this.closes();
      }
    },

    closes() {
      this.$emit("closed", false);
    },
  },
};
</script>