<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="requirements"
      sort-by="calories"
      class="elevation-1"
    >
      <!-- <template #item.NAME="{ value }">
        <a target="_blank" href="http://103.26.13.16:8181/api/getFile/0057534901/1">
          {{ value }}
        </a>
      </template> -->
      <template v-slot:item.nomor="{ item }">
        {{ requirements.indexOf(item) + 1 }}
      </template>
      <template #item.NILAI="{ item }">
        <span v-for="dt in nilai" :key="dt.id">
          <span v-if="dt.MAPELUSID == item.id">
            {{ dt.NILAI }}
          </span>
        </span>
      </template>

      <template #item.status="{ item }">
        <span v-for="dt in item.requirement_check" :key="dt.id">
          <v-chip small rounded color="orange" dark v-if="dt.STATUS == 0">
            Proses Verifikasi
          </v-chip>
          <v-chip small rounded color="green" dark v-if="dt.STATUS == 1">
            Diverifikasi
          </v-chip>
          <v-chip small rounded color="error" dark v-if="dt.STATUS == 2">
            Ditolak
          </v-chip>
        </span>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Input Nilai</v-toolbar-title>
          </v-toolbar>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="720px">
            <!-- <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                New Item
              </v-btn>
            </template> -->
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <input type="hidden" v-model="editedItem.id" />
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.NAME"
                        label="Mata Pelajaran"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.NILAI"
                        :rules="rules"
                        label="Masukkan Nilai"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close"> Batal </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Simpan </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
      <template v-slot:no-data> Tidak ada data Mata Pelajaran </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Persyaratan",
  data: () => ({
    baseUrl: process.env.VUE_APP_API_URL,
    url: "/api/getFile/",
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "No",
        align: "left",
        sortable: false,
        value: "nomor",
      },
      { text: "Mata Pelajaran", value: "NAME" },
      { text: "Nilai", value: "NILAI" },
      { text: "Aksi", value: "actions", sortable: false },
    ],
    requirements: [],
    nilai: [],
    admissionID: localStorage.getItem("admissionID"),
    admissionNAME: localStorage.getItem("admissionNAME"),
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },

    rules: [(v) => !!v || "Form ini wajib di isi"],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
    }),

    initialize() {
      this.getMapel();
      this.getNilai();
    },

    getMapel() {
      // console.log("getKabkot");
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post("/api/mapelList", {}, config)
        .then((response) => {
          this.$vloading.hide();
          let { data } = response.data;
          this.requirements = data;
          console.log(this.requirements);
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log("error" + error);
        });
    },

    getNilai() {
      // console.log("getKabkot");
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post("/api/nilaiList", {}, config)
        .then((response) => {
          this.$vloading.hide();
          let data = response.data.data;
          this.nilai = data;
          console.log(data);
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log("error" + error);
        });
    },

    editItem(item) {
      this.editedIndex = this.requirements.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      // console.log("deleted data");
      this.editedIndex = this.requirements.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.requirements.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        this.$vloading.show();
        // console.log("edited data");
        // Object.assign(this.requirements[this.editedIndex], this.editedItem);
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.token,
          },
        };

        let formData = new FormData();
        formData.set("MAPELUSID", this.editedItem.id);
        formData.set("NILAI", this.editedItem.NILAI);
        this.axios
          .post("/api/nilaiSave", formData, config)
          .then(() => {
            this.$vloading.hide();
            // let { data } = response.data;
            // console.log(response.data);
            this.setAlert({
              status: true,
              color: "success",
              text: "Berhasil disimpan",
            });
            this.close();
            this.getMapel();
            this.getNilai();
          })
          .catch((error) => {
            this.$vloading.hide();
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data,
            });
            this.close();
          });
      } else {
        // console.log("created data");
        // this.requirements.push(this.editedItem);
      }
      this.close();
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },

    closes() {
      this.$emit("closed", false);
    },
  },
};
</script>