<template>
  <div>
    <v-container class="ma-0 pa-1" grid-list-sm>
      <div class="text-left">
        <v-btn small text to="/siswa" class="blue--text">
          <v-icon>mdi-chevron-left</v-icon> Kembali
        </v-btn>
      </div>
    </v-container>
    <v-row>
      <v-col cols="12" sm="12" md="4">
        <!-- FOTO -->
        <v-card>
          <v-img
            :src="baseUrl + urlFoto + nisnDetail"
            max-height="500"
            class="grey darken-4"
          ></v-img>
          <div class="text-center pa-2">
            <h3>{{ nameDetail }}</h3>
            <h4 class="pa-1">{{ nisnDetail }}</h4>
            <v-divider class="pa-1"></v-divider>
            <h6><u>Jalur Penerimaan</u></h6>
            <span v-for="dt in admissionItems" :key="dt.id">
              <span v-if="admissionDetail == dt.id"
                ><h4>{{ dt.NAME }}</h4></span
              >
            </span>
            <h6><u>Nomor Pendaftaran</u></h6>
            <h4>{{ regnumberDetail }}</h4>
          </div>
        </v-card>
        <v-expansion-panels multiple :value="panel">
          <!-- PILIHAN SEKOLAH -->
          <v-expansion-panel>
            <v-expansion-panel-header dark color="primary" style="color: white">
              Pilihan Sekolah
              <template v-slot:actions>
                <v-icon color="white"> mdi-chevron-down </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-simple-table class="pa-2" dense>
                <tbody v-if="schools == undefined">
                  <tr>
                    <td>Pilihan 1</td>
                    <td>: Belum memilih sekolah</td>
                  </tr>
                  <tr>
                    <td>Pilihan 2</td>
                    <td>: Belum memilih sekolah</td>
                  </tr>
                  <tr>
                    <td>Pilihan 3</td>
                    <td>: Belum memilih sekolah</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr v-for="(school, index) in schools" :key="index">
                    <td>Pilihan {{ index + 1 }}</td>
                    <td>: {{ school.school_data.NAME }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" sm="12" md="8">
        <v-expansion-panels multiple :value="panel2">
          <!-- IDENTITAS PESERTA DIDIK -->
          <v-expansion-panel>
            <v-expansion-panel-header dark color="primary" style="color: white">
              Identitas Peserta Didik
              <template v-slot:actions>
                <v-icon color="white"> mdi-chevron-down </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-simple-table class="pa-2" dense>
                <tbody>
                  <tr>
                    <td>Nama Lengkap</td>
                    <td>: {{ nameDetail }}</td>
                  </tr>
                  <tr>
                    <td>Jenis Kelamin</td>
                    <td>
                      :
                      <span v-if="jenkel == 'M'"> Laki-laki </span>
                      <span v-else> Perempuan </span>
                    </td>
                  </tr>
                  <tr>
                    <td>NISN</td>
                    <td>: {{ nisnDetail }}</td>
                  </tr>
                  <tr>
                    <td>NIS</td>
                    <td>: {{ nis }}</td>
                  </tr>
                  <tr>
                    <td>Nomor Seri Ijazah</td>
                    <td>: {{ noijazah }}</td>
                  </tr>
                  <tr>
                    <td>Nomor Seri SKHUN</td>
                    <td>: {{ noskhun }}</td>
                  </tr>
                  <tr>
                    <td>Nomor Ujian Nasional</td>
                    <td>: {{ noun }}</td>
                  </tr>
                  <tr>
                    <td>No. Induk Kependudukan (NIK)</td>
                    <td>: {{ nik }}</td>
                  </tr>
                  <tr>
                    <td>No. Kartu Keluarga (KK)</td>
                    <td>: {{ nokk }}</td>
                  </tr>
                  <tr>
                    <td>NPSN Sekolah Asal</td>
                    <td>: {{ npsn }}</td>
                  </tr>
                  <tr>
                    <td>Jenjang Pendidikan Sebelumnya</td>
                    <td>
                      :
                      <span
                        v-for="dt in jenjangItems"
                        :key="dt.bentuk_pendidikan_id"
                      >
                        <span v-if="jenjang == dt.bentuk_pendidikan_id">{{
                          dt.nama
                        }}</span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>: {{ email }}</td>
                  </tr>
                  <tr>
                    <td>Golongan Darah</td>
                    <td>: {{ goldar }}</td>
                  </tr>
                  <tr>
                    <td>Anak yang ke</td>
                    <td>: {{ anakke }}</td>
                  </tr>
                  <tr>
                    <td>Tempat, Tgl Lahir</td>
                    <td>: {{ tempat }}, {{ tgllahir }}</td>
                  </tr>
                  <tr>
                    <td>Agama</td>
                    <td>
                      :
                      <span v-for="dt in religionItems" :key="dt.id">
                        <span v-if="agama == dt.id">{{ dt.name }}</span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Kewarganegaraan</td>
                    <td>
                      :
                      <span v-for="dt in citizenItems" :key="dt.id">
                        <span v-if="kewarganegaraan == dt.id">{{
                          dt.name
                        }}</span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Alamat Tempat Tinggal</td>
                    <td>: {{ alamat }}</td>
                  </tr>
                  <tr>
                    <td>Dusun</td>
                    <td>: {{ dusun }}</td>
                  </tr>
                  <tr>
                    <td>RT</td>
                    <td>: {{ rt }}</td>
                  </tr>
                  <tr>
                    <td>RW</td>
                    <td>: {{ rw }}</td>
                  </tr>
                  <tr>
                    <td>Kelurahan / Desa</td>
                    <td>
                      :
                      <span
                        v-for="dt in subdistrictItems"
                        :key="dt.kode_wilayah"
                      >
                        <span v-if="keldes == dt.kode_wilayah">{{
                          dt.name
                        }}</span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Kecamatan</td>
                    <td>
                      :
                      <span v-for="dt in districtItems" :key="dt.kode_wilayah">
                        <span v-if="kec == dt.kode_wilayah">{{ dt.name }}</span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Kabupaten / Kota</td>
                    <td>
                      :
                      <span v-for="dt in cityItems" :key="dt.kode_wilayah">
                        <span v-if="kabkot == dt.kode_wilayah">{{
                          dt.name
                        }}</span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Propinsi</td>
                    <td>: {{ prov }}</td>
                  </tr>
                  <tr>
                    <td>Kode Pos</td>
                    <td>: {{ kodepos }}</td>
                  </tr>
                  <tr>
                    <td>Alat Transportasi ke Sekolah</td>
                    <td>
                      :
                      <span v-for="dt in transportItems" :key="dt.id">
                        <span v-if="transportasi == dt.id">{{ dt.name }}</span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Jenis Tinggal</td>
                    <td>
                      :
                      <span v-for="dt in homestatusItems" :key="dt.id">
                        <span v-if="tinggal == dt.id">{{ dt.name }}</span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>No. Telepon</td>
                    <td>: {{ notelp }}</td>
                  </tr>
                  <tr>
                    <td>No. HP</td>
                    <td>: {{ nohp }}</td>
                  </tr>
                  <tr>
                    <td>Berkebutuhan Khusus</td>
                    <td>
                      :
                      <span v-for="dt in specialneedItems" :key="dt.id">
                        <span v-if="khusus == dt.id">{{ dt.name }}</span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Apakah Penerima KKS* ?</td>
                    <td>
                      :
                      <span v-for="dt in booleanItems" :key="dt.id">
                        <span v-if="nokks == dt.id">{{ dt.name }}</span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Apakah Penerima KPS* ?</td>
                    <td>
                      :
                      <span v-for="dt in booleanItems" :key="dt.id">
                        <span v-if="kpsstatus == dt.id">{{ dt.name }}</span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Usulan dari sekolah Layak PIP* ?</td>
                    <td>
                      :
                      <span v-for="dt in booleanItems" :key="dt.id">
                        <span v-if="pipstatus == dt.id">{{ dt.name }}</span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Apakah Penerima KIP* ?</td>
                    <td>
                      :
                      <span v-for="dt in booleanItems" :key="dt.id">
                        <span v-if="kip == dt.id">{{ dt.name }}</span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Status KIP*</td>
                    <td>
                      :
                      <span v-for="dt in deactiveItems" :key="dt.id">
                        <span v-if="kipstatus == dt.id">{{ dt.name }}</span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Nomor KIP*</td>
                    <td>: {{ nokip }}</td>
                  </tr>
                  <tr>
                    <td>Lintang</td>
                    <td>: {{ lintang }}</td>
                  </tr>
                  <tr>
                    <td>Bujur</td>
                    <td>: {{ bujur }}</td>
                  </tr>
                  <tr>
                    <td>No Registrasi Akta Lahir</td>
                    <td>: {{ noakta }}</td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <small
                        >Catatan :<br />
                        *KKS = Kartu Keluarga Sejahtera <br />
                        *KPS = Kartu Perlindungan Sosial <br />
                        *PIP = Program Indonesia Pintar <br />
                        *KIP = Kartu Indonesia Pintar
                      </small>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- BIODATA ORANG TUA -->
          <v-expansion-panel>
            <v-expansion-panel-header dark color="primary" style="color: white">
              Biodata Orang Tua
              <template v-slot:actions>
                <v-icon color="white"> mdi-chevron-down </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-simple-table class="pa-2" dense>
                <tbody>
                  <tr>
                    <th colspan="2">DATA AYAH KANDUNG</th>
                  </tr>
                  <tr>
                    <td>Nama Ayah Kandung</td>
                    <td>: {{ nameAyah }}</td>
                  </tr>
                  <tr>
                    <td>NIK Ayah</td>
                    <td>: {{ nikAyah }}</td>
                  </tr>
                  <tr>
                    <td>Tanggal Lahir</td>
                    <td>: {{ dobAyah }}</td>
                  </tr>
                  <tr>
                    <td>Alamat</td>
                    <td>: {{ addressAyah }}</td>
                  </tr>
                  <tr>
                    <td>Nomor HP</td>
                    <td>: {{ hpAyah }}</td>
                  </tr>
                  <tr>
                    <td>Pendidikan</td>
                    <td>
                      :
                      <span v-for="dt in educationItems" :key="dt.id">
                        <span v-if="educationAyah == dt.id">{{ dt.name }}</span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Pekerjaan</td>
                    <td>
                      :
                      <span v-for="dt in jobItems" :key="dt.id">
                        <span v-if="jobAyah == dt.id">{{ dt.name }}</span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Penghasilan</td>
                    <td>
                      :
                      <span v-for="dt in salaryItems" :key="dt.id">
                        <span v-if="salaryAyah == dt.id">{{ dt.name }}</span>
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <th colspan="2">DATA IBU KANDUNG</th>
                  </tr>
                  <tr>
                    <td>Nama Ibu Kandung</td>
                    <td>: {{ nameIbu }}</td>
                  </tr>
                  <tr>
                    <td>NIK Ibu</td>
                    <td>: {{ nikIbu }}</td>
                  </tr>
                  <tr>
                    <td>Tanggal Lahir</td>
                    <td>: {{ dobIbu }}</td>
                  </tr>
                  <tr>
                    <td>Alamat</td>
                    <td>: {{ addressIbu }}</td>
                  </tr>
                  <tr>
                    <td>Nomor HP</td>
                    <td>: {{ hpIbu }}</td>
                  </tr>
                  <tr>
                    <td>Pendidikan</td>
                    <td>
                      :
                      <span v-for="dt in educationItems" :key="dt.id">
                        <span v-if="educationIbu == dt.id">{{ dt.name }}</span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Pekerjaan</td>
                    <td>
                      :
                      <span v-for="dt in jobItems" :key="dt.id">
                        <span v-if="jobIbu == dt.id">{{ dt.name }}</span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Penghasilan</td>
                    <td>
                      :
                      <span v-for="dt in salaryItems" :key="dt.id">
                        <span v-if="salaryIbu == dt.id">{{ dt.name }}</span>
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <th colspan="2">DATA WALI</th>
                  </tr>
                  <tr>
                    <td>Nama Wali</td>
                    <td>: {{ nameWali }}</td>
                  </tr>
                  <tr>
                    <td>NIK Wali</td>
                    <td>: {{ nikWali }}</td>
                  </tr>
                  <tr>
                    <td>Tanggal Lahir</td>
                    <td>: {{ dobWali }}</td>
                  </tr>
                  <tr>
                    <td>Alamat</td>
                    <td>: {{ addressWali }}</td>
                  </tr>
                  <tr>
                    <td>Nomor HP</td>
                    <td>: {{ hpWali }}</td>
                  </tr>
                  <tr>
                    <td>Pendidikan</td>
                    <td>
                      :
                      <span v-for="dt in educationItems" :key="dt.id">
                        <span v-if="educationWali == dt.id">{{ dt.name }}</span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Pekerjaan</td>
                    <td>
                      :
                      <span v-for="dt in jobItems" :key="dt.id">
                        <span v-if="jobWali == dt.id">{{ dt.name }}</span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Penghasilan</td>
                    <td>
                      :
                      <span v-for="dt in salaryItems" :key="dt.id">
                        <span v-if="salaryWali == dt.id">{{ dt.name }}</span>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- NILAI -->
          <v-expansion-panel>
            <v-expansion-panel-header dark color="primary" style="color: white">
              Nilai
              <template v-slot:actions>
                <v-icon color="white"> mdi-chevron-down </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table :headers="headersMapelList" :items="mapel">
                <template v-slot:item.nomor="{ item }">
                  {{ mapel.indexOf(item) + 1 }}
                </template>
                <template v-slot:item.NILAI="{ item }">
                  <span v-if="nilai.length == 0"> 0 </span>
                  <span v-else>
                    <span v-for="dt in nilai" :key="dt.id">
                      <span v-if="dt.MAPELUSID == item.id">
                        {{ dt.NILAI }}
                      </span>
                    </span>
                  </span>
                </template>
                <template v-slot:no-data> Tidak ada data </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- DOKUMEN PERSYARATAN -->
          <v-expansion-panel>
            <v-expansion-panel-header dark color="primary" style="color: white">
              Dokumen Persyaratan
              <template v-slot:actions>
                <v-icon color="white"> mdi-chevron-down </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table :headers="headersReqList" :items="reqlist">
                <template v-slot:item.nomor="{ item }">
                  {{ reqlist.indexOf(item) + 1 }}
                </template>
                <template v-slot:item.FILE="{ item }">
                  <span v-for="key in dokumen" :key="key.id">
                    <span v-if="key.ADMISSION_REQUIREMENT_ID == item.id">
                      <!-- <a
                        target="_blank"
                        :href="
                          baseUrl +
                          url +
                          nisnDetail +
                          '/documents/' +
                          key.FILE_NAME
                        "
                      >
                        {{ key.FILE_NAME }}
                      </a> -->
                      <v-btn
                        x-small
                        outlined
                        color="primary"
                        dark
                        @click="viewer(key)"
                      >
                        Lihat
                      </v-btn>
                    </span>
                  </span>
                </template>
                <template v-slot:item.POINT="{ item }">
                  <!-- Ketika Persyaratan Punya Poin -->
                  <span v-if="item.POINT == 1">
                    <span v-for="key in dokumen" :key="key.id">
                      <span v-if="key.ADMISSION_REQUIREMENT_ID == item.id">
                        <!-- Ketika Sudah ada data document_point -->
                        <span v-if="key.documentPoint != null">
                          {{ key.documentPoint.NAME }}<br /><small
                            ><v-btn
                              x-small
                              outlined
                              color="error"
                              dark
                              @click="batalVerifikasiDokumenPoin(key)"
                              v-if="validationDetail == 0"
                            >
                              Ganti
                              <v-icon right> mdi-close </v-icon>
                            </v-btn></small
                          >
                        </span>
                        <!-- Ketika Belum ada data document_point -->
                        <span v-else>
                          <v-btn
                            x-small
                            class="mr-1"
                            rounded
                            color="teal"
                            dark
                            @click="docItem(item)"
                          >
                            <v-icon left> mdi-file </v-icon>
                            Dokumen
                          </v-btn>

                          <v-dialog v-model="dialogDoc" max-width="800px">
                            <v-card>
                              <v-card-title>
                                <span class="headline">Pilih Dokumen</span>
                              </v-card-title>

                              <v-form ref="form" lazy-validation>
                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="12" sm="12" md="12">
                                        <v-select
                                          v-model="docID"
                                          :items="docList"
                                          item-value="id"
                                          item-text="NAME"
                                          label="Dokumen"
                                        ></v-select>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeDialogDoc"
                                  >
                                    Batal
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="
                                      saveVerifyPoint(admissionReqID, docID)
                                    "
                                  >
                                    Simpan
                                  </v-btn>
                                </v-card-actions>
                              </v-form>
                            </v-card>
                          </v-dialog>
                        </span>
                      </span>
                    </span>
                  </span>
                </template>
                <template v-slot:item.STATUS="{ item }">
                  <span v-for="key in dokumen" :key="key.id">
                    <span v-if="key.ADMISSION_REQUIREMENT_ID == item.id">
                      <!-- Ketika Persyaratan Punya Poin -->
                      <span v-if="item.POINT == 1">
                        <span v-if="key.documentPoint != null">
                          <!-- Jika status 0 -->
                          <v-btn
                            x-small
                            class="mr-1"
                            rounded
                            color="success"
                            dark
                            @click="dialogVerifyReqOpen(key, 1)"
                            v-if="key.STATUS == 0"
                          >
                            <v-icon left> mdi-check-bold </v-icon>
                            Verifikasi
                          </v-btn>
                          <v-btn
                            x-small
                            rounded
                            color="error"
                            dark
                            @click="dialogVerifyReqOpen(key, 2)"
                            v-if="key.STATUS == 0"
                          >
                            <v-icon left> mdi-close-thick </v-icon>
                            Tolak
                          </v-btn>

                          <!-- Jika Status 1 -->
                          <v-chip
                            small
                            rounded
                            color="green"
                            dark
                            v-if="key.STATUS == 1"
                          >
                            Diverifikasi
                          </v-chip>

                          <!-- Jika status 2 -->
                          <v-chip
                            small
                            rounded
                            color="error"
                            dark
                            v-if="key.STATUS == 2"
                          >
                            Ditolak
                          </v-chip>
                        </span>
                        <span v-else>
                          <small>Pilih Dokumen Terlebih Dahulu</small>
                        </span>
                      </span>
                      <span v-else>
                        <!-- Jika status 0 -->
                        <v-btn
                          x-small
                          class="mr-1"
                          rounded
                          color="success"
                          dark
                          @click="dialogVerifyReqOpen(key, 1)"
                          v-if="key.STATUS == 0"
                        >
                          <v-icon left> mdi-check-bold </v-icon>
                          Verifikasi
                        </v-btn>
                        <v-btn
                          x-small
                          rounded
                          color="error"
                          dark
                          @click="dialogVerifyReqOpen(key, 2)"
                          v-if="key.STATUS == 0"
                        >
                          <v-icon left> mdi-close-thick </v-icon>
                          Tolak
                        </v-btn>

                        <!-- Jika Status 1 -->
                        <v-chip
                          small
                          rounded
                          color="green"
                          dark
                          v-if="key.STATUS == 1"
                        >
                          Diverifikasi
                        </v-chip>

                        <!-- Jika status 2 -->
                        <v-chip
                          small
                          rounded
                          color="error"
                          dark
                          v-if="key.STATUS == 2"
                        >
                          Ditolak
                        </v-chip>
                      </span>
                    </span>
                  </span>
                </template>
                <template v-slot:no-data>
                  <span style="color: red"
                    >Belum mengupload dokumen persyaratan</span
                  >
                </template>
              </v-data-table>

              <!-- <span v-for="dt in admissionItems" :key="dt.id">
                <span v-if="admissionDetail == dt.id">
                  <span v-if="dt.STATUS == 1">
                    <v-btn
                      class="ma-2"
                      :loading="loading"
                      :disabled="loading"
                      medium
                      color="success"
                      @click="verifUser()"
                    >
                      Verifikasi Data Siswa
                      <v-icon right>mdi-check-underline-circle</v-icon>
                    </v-btn>
                  </span>
                </span>
              </span> -->
              <v-btn
                :loading="loading"
                :disabled="loading"
                medium
                color="success"
                @click="
                  verifUser();
                  loader = 'loading';
                "
                v-if="validationDetail == 0"
              >
                Verifikasi Data Siswa
                <v-icon right>mdi-check-underline-circle</v-icon>
              </v-btn>
              <v-btn medium color="teal" dark @click="verifikasi()" v-else>
                Bukti Verifikasi
                <v-icon right> mdi-printer </v-icon>
              </v-btn>
              <v-btn
                medium
                color="info"
                dark
                @click="pendaftaran()"
                class="ms-5"
              >
                Bukti Pendaftaran
                <v-icon right> mdi-printer </v-icon> </v-btn
              ><br />
              <small
                ><v-btn
                  x-small
                  outlined
                  color="error"
                  dark
                  @click="batalVerifikasi()"
                  v-if="validationDetail == 1 && user.role == 1"
                >
                  Batalkan Verifikasi
                  <v-icon right> mdi-close </v-icon>
                </v-btn></small
              >
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <!-- PDF Viewer -->
    <v-dialog v-model="dialogViewer" scrollable width="80%">
      <v-card>
        <v-card-title class="primary">
          <span class="headline white--text">Dokumen Persyaratan</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <!-- {{ editedItem }} -->
                <iframe
                  :src="
                    baseUrl +
                      url +
                      nisnDetail +
                      '/documents/' +
                      editedItem.FILE_NAME
                  "
                  width="100%"
                  height="500"
                  frameborder="0"
                ></iframe>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogViewer = false">
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- LOADER -->
    <v-dialog v-model="dialogLoading" persistent width="300">
      <v-card>
        <v-card-text>
          Sedang mengambil data...
          <v-progress-linear
            indeterminate
            color="primary"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog Konfirmasi Verifikasi -->
    <v-dialog v-model="dialogVerifyReq" persistent max-width="500">
      <v-card>
        <v-card-title class="text-h5">
          Konfirmasi
        </v-card-title>
        <v-card-text>
          Anda yakin ingin
          <span v-if="dialogVerifyReqOption == 1">memverifikasi</span
          ><span v-else>menolak</span> dokumen ini?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogVerifyReq = false">
            Batal
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="verifyDokumen(dialogVerifyReqData)"
            v-if="dialogVerifyReqOption == 1"
          >
            Verifikasi
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="verifyDokumenTolak(dialogVerifyReqData)"
            v-else
          >
            Tolak
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "DetailSiswa",
  data: () => ({
    baseUrl: process.env.VUE_APP_API_URL,
    url: "/storage/",
    urlFoto: "/api/getPhoto/",
    loading: false,
    dialogVerifyReq: false,
    dialogVerifyReqData: null,
    dialogVerifyReqOption: null,
    dialogLoading: false,
    dialogViewer: false,
    dialog: false,
    dialogDelete: false,
    dialogDoc: false,
    docID: null,
    admissionReqID: null,
    panel: [0],
    panel2: [2, 3],
    useridDetail: localStorage.getItem("useridDetail"),
    USERID: localStorage.getItem("USERID"),
    nameDetail: localStorage.getItem("nameDetail"),
    nisnDetail: localStorage.getItem("nisnDetail"),
    admissionDetail: localStorage.getItem("admissionDetail"),
    regnumberDetail: localStorage.getItem("regnumberDetail"),
    validationDetail: localStorage.getItem("validationDetail"),
    headers: [
      { text: "No", value: "nomor" },
      {
        text: "Nama Lengkap",
        align: "left",
        sortable: false,
        value: "NAME",
      },
      { text: "NISN", value: "NISN" },
      { text: "L/P", value: "SEX" },
      { text: "Tanggal Lahir", value: "DOB" },
      { text: "NIK", value: "NIK" },
      { text: "Aksi", value: "actions", sortable: false },
      { text: "", value: "data-table-expand" },
    ],
    headersReqList: [
      {
        text: "No",
        align: "left",
        sortable: false,
        value: "nomor",
      },
      { text: "NAME", value: "NAME" },
      { text: "FILE", value: "FILE" },
      { text: "DOKUMEN", value: "POINT" },
      { text: "STATUS", value: "STATUS" },
    ],
    headersMapelList: [
      {
        text: "No",
        align: "left",
        sortable: false,
        value: "nomor",
      },
      { text: "Mata Pelajaran", value: "NAME" },
      { text: "Nilai", value: "NILAI" },
    ],
    student: [],
    expanded: [],
    schools: [],
    dokumen: [],
    reqlist: [],
    mapel: [],
    nilai: [],
    name: "",
    nisn: "",
    singleExpand: true,
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    jenkel: "",
    nis: "",
    noijazah: "",
    noskhun: "",
    noun: "",
    nik: "",
    nokk: "",
    npsn: "",
    jenjang: "",
    email: "",
    goldar: "",
    anakke: "",
    namasekolah: "",
    tempat: "",
    tgllahir: "",
    agama: "",
    kewarganegaraan: "",
    khusus: "",
    alamat: "",
    dusun: "",
    rt: "",
    rw: "",
    keldes: "",
    kec: "",
    kabkot: "",
    prov: "",
    kodepos: "",
    transportasi: "",
    tinggal: "",
    notelp: "",
    nohp: "",
    nokks: "",
    kpsstatus: "",
    nokps: "",
    pipstatus: "",
    piplayak: "",
    nokip: "",
    kipstatus: "",
    kip: "",
    noakta: "",
    lintang: "",
    bujur: "",
    jalur: [],
    foto: "",
    religionItems: [
      { id: 1, name: "Islam" },
      { id: 2, name: "Kristen Protestan" },
      { id: 3, name: "Kristen Katolik" },
      { id: 4, name: "Hindu" },
      { id: 5, name: "Budha" },
      { id: 6, name: "Konghucu" },
    ],
    specialneedItems: [
      { id: 1, name: "Tidak" },
      { id: 2, name: "Netra" },
      { id: 3, name: "Rungu" },
      { id: 4, name: "Grahita Ringan" },
      { id: 5, name: "Grahita Sedang" },
      { id: 6, name: "Daksa Ringan" },
      { id: 7, name: "Daksa Sedang" },
      { id: 8, name: "Lara" },
    ],
    homestatusItems: [
      { id: 1, name: "Bersama Orang Tua" },
      { id: 2, name: "Wali" },
      { id: 3, name: "Kos" },
      { id: 4, name: "Asrama" },
      { id: 5, name: "Panti Asuhan" },
    ],
    transportItems: [
      { id: 1, name: "Jalan Kaki" },
      { id: 2, name: "Kendaraan Pribadi" },
      { id: 3, name: "Kendaraan Umum" },
      { id: 4, name: "Jemputan Sekolah" },
      { id: 5, name: "Kereta Api" },
      { id: 6, name: "Ojek" },
      { id: 7, name: "Andong" },
    ],
    booleanItems: [
      { id: "1", name: "Ya" },
      { id: "0", name: "Tidak" },
    ],
    deactiveItems: [
      { id: "1", name: "Aktif" },
      { id: "0", name: "Tidak" },
    ],
    citizenItems: [
      { id: "1", name: "WNI" },
      { id: "0", name: "WNA" },
    ],
    cityItems: [{ kode_wilayah: "", name: "" }],
    districtItems: [{ kode_wilayah: "", name: "" }],
    subdistrictItems: [{ kode_wilayah: "", name: "" }],
    jenjangItems: [{ bentuk_pendidikan_id: "", nama: "" }],
    admissionItems: [{ id: null, NAME: "" }],
    docList: [],

    nameAyah: "",
    nikAyah: "",
    dobAyah: "",
    addressAyah: "",
    hpAyah: "",
    educationAyah: "",
    jobAyah: "",
    salaryAyah: "",

    nameIbu: "",
    nikIbu: "",
    dobIbu: "",
    addressIbu: "",
    hpIbu: "",
    educationIbu: "",
    jobIbu: "",
    salaryIbu: "",

    nameWali: "",
    nikWali: "",
    dobWali: "",
    addressWali: "",
    hpWali: "",
    educationWali: "",
    jobWali: "",
    salaryWali: "",

    educationItems: [
      { id: "1", name: "Tidak sekolah" },
      { id: "2", name: "Putus SD" },
      { id: "3", name: "SD Sederajat" },
      { id: "4", name: "SMP Sederajat" },
      { id: "5", name: "SMA Sederajat" },
      { id: "6", name: "D1" },
      { id: "7", name: "D2" },
      { id: "8", name: "D3" },
      { id: "9", name: "D4/S1" },
      { id: "10", name: "S2" },
      { id: "11", name: "S3" },
    ],
    jobItems: [
      { id: "01", name: "Tidak bekerja" },
      { id: "02", name: "Nelayan" },
      { id: "03", name: "Petani" },
      { id: "04", name: "Peternak" },
      { id: "05", name: "PNS/TNI/POLRI" },
      { id: "06", name: "Karyawan Swasta" },
      { id: "07", name: "Pedagang Kecil" },
      { id: "08", name: "Pedagang Besar" },
      { id: "09", name: "Wiraswasta" },
      { id: "10", name: "Wirausaha" },
      { id: "11", name: "Buruh" },
      { id: "12", name: "Pensiunan" },
    ],
    salaryItems: [
      { id: "01", name: "< Rp. 500.000" },
      { id: "02", name: "Rp. 500.000-Rp.999.999" },
      { id: "03", name: "Rp. 1.000.000-Rp.1.999.999" },
      { id: "04", name: "Rp.2.000.000-Rp.4.999.999" },
      { id: "05", name: "Rp.5.000.000-Rp.20.000.000" },
      { id: "06", name: "> Rp.20.000.000" },
      { id: "07", name: "Tidak Berpenghasilan" },
    ],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDoc(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
    }),

    initialize() {
      // this.getRequirementList();
      this.getBioSiswa();
      this.schoolSelected();
      this.loadDokumen();
      this.getKabkot();
      this.getJenjang();
      this.getJalur();
      this.getParent();
      this.getMapel();
      this.getNilai();
    },

    getBioSiswa() {
      this.dialogLoading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("nisn", localStorage.getItem("nisnDetail"));
      this.axios
        .post("/api/getBioSiswa", formData, config)
        .then((response) => {
          this.dialogLoading = false;
          // this.student = response.data;
          let { data } = response;
          // console.log(data);
          // console.log(data.data.ADDRESS);
          this.jenkel = data.data.SEX;
          this.nis = data.data.NIS;
          this.noijazah = data.data.NOIJAZAH;
          this.noskhun = data.data.SKHUN;
          this.noun = data.data.NOUN;
          this.nik = data.data.NIK;
          this.nokk = data.data.NOKK;
          this.npsn = data.data.NPSN;
          this.jenjang = data.data.LEVEL;
          this.email = data.data.user_data.email;
          this.goldar = data.data.BLOOD;
          this.anakke = data.data.CHILDNO;
          this.tempat = data.data.BIRTHPLACE;
          this.tgllahir = data.data.DOB;
          this.agama = data.data.RELIGION;
          this.kewarganegaraan = data.data.CITIZEN;
          this.khusus = data.data.SPECIALNEED;
          this.alamat = data.data.ADDRESS;
          this.dusun = data.data.DUSUN;
          this.rt = data.data.RT;
          this.rw = data.data.RW;
          this.keldes = data.data.SUBDISTRICT;
          this.kec = data.data.DISTRICT;
          this.kabkot = data.data.CITY;
          this.prov = "Gorontalo";
          this.kodepos = data.data.BOX;
          this.transportasi = data.data.TRANSPORT;
          this.tinggal = data.data.HOMESTATUS;
          this.notelp = data.data.PHONE;
          this.nohp = data.data.HP;
          this.nokks = data.data.KKS;
          this.kpsstatus = data.data.KPS;
          this.pipstatus = data.data.PIP;
          this.nokip = data.data.KIPNO;
          this.kipstatus = data.data.KIPSTATUS;
          this.kip = data.data.KIP;
          this.noakta = data.data.NOAKTA;
          this.lintang = data.data.LOT;
          this.bujur = data.data.LANG;
          this.foto = data.data.PHOTO;

          // this.getKabkot(this.kabkot);
          this.getKec(data.data.CITY);
          this.getKel(data.data.DISTRICT);

          // console.log(data.data.user_data.email);

          // var dts = data.data.ADMISSION;
          // this.jalur = Array.from(dts.split(","), Number);
        })
        .catch((error) => {
          this.dialogLoading = false;
          console.log("err biosiswa " + error);
        });
    },

    getParent() {
      this.dialogLoading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("nisn", localStorage.getItem("nisnDetail"));
      this.axios
        .post("/api/getParent", formData, config)
        .then((response) => {
          this.dialogLoading = false;
          // console.log(response.data);
          // this.parentsItems = response.data.data;
          let { data } = response.data;
          // console.log(data);

          // DATA PERTAMA YANG TERSIMPAN
          if (data[0].TYPE == "A") {
            this.nameAyah = data[0].NAME;
            this.nikAyah = data[0].NIK;
            this.dobAyah = data[0].DOB;
            this.addressAyah = data[0].ADDRESS;
            this.hpAyah = data[0].PHONE;
            this.educationAyah = data[0].EDUCATION;
            this.jobAyah = data[0].JOB;
            this.salaryAyah = data[0].SALARY;
          } else if (data[0].TYPE == "I") {
            this.nameIbu = data[0].NAME;
            this.nikIbu = data[0].NIK;
            this.dobIbu = data[0].DOB;
            this.addressIbu = data[0].ADDRESS;
            this.hpIbu = data[0].PHONE;
            this.educationIbu = data[0].EDUCATION;
            this.jobIbu = data[0].JOB;
            this.salaryIbu = data[0].SALARY;
          } else if (data[0].TYPE == "W") {
            this.nameWali = data[0].NAME;
            this.nikWali = data[0].NIK;
            this.dobWali = data[0].DOB;
            this.addressWali = data[0].ADDRESS;
            this.hpWali = data[0].PHONE;
            this.educationWali = data[0].EDUCATION;
            this.jobWali = data[0].JOB;
            this.salaryWali = data[0].SALARY;
          }

          // DATA KEDUA
          if (data[1].TYPE == "A") {
            this.nameAyah = data[1].NAME;
            this.nikAyah = data[1].NIK;
            this.dobAyah = data[1].DOB;
            this.addressAyah = data[1].ADDRESS;
            this.hpAyah = data[1].PHONE;
            this.educationAyah = data[1].EDUCATION;
            this.jobAyah = data[1].JOB;
            this.salaryAyah = data[1].SALARY;
          } else if (data[1].TYPE == "I") {
            this.nameIbu = data[1].NAME;
            this.nikIbu = data[1].NIK;
            this.dobIbu = data[1].DOB;
            this.addressIbu = data[1].ADDRESS;
            this.hpIbu = data[1].PHONE;
            this.educationIbu = data[1].EDUCATION;
            this.jobIbu = data[1].JOB;
            this.salaryIbu = data[1].SALARY;
          } else if (data[1].TYPE == "W") {
            this.nameWali = data[1].NAME;
            this.nikWali = data[1].NIK;
            this.dobWali = data[1].DOB;
            this.addressWali = data[1].ADDRESS;
            this.hpWali = data[1].PHONE;
            this.educationWali = data[1].EDUCATION;
            this.jobWali = data[1].JOB;
            this.salaryWali = data[1].SALARY;
          }

          // DATA KETIGA
          if (data[2].TYPE == "A") {
            this.nameAyah = data[2].NAME;
            this.nikAyah = data[2].NIK;
            this.dobAyah = data[2].DOB;
            this.addressAyah = data[2].ADDRESS;
            this.hpAyah = data[2].PHONE;
            this.educationAyah = data[2].EDUCATION;
            this.jobAyah = data[2].JOB;
            this.salaryAyah = data[2].SALARY;
          } else if (data[2].TYPE == "I") {
            this.nameIbu = data[2].NAME;
            this.nikIbu = data[2].NIK;
            this.dobIbu = data[2].DOB;
            this.addressIbu = data[2].ADDRESS;
            this.hpIbu = data[2].PHONE;
            this.educationIbu = data[2].EDUCATION;
            this.jobIbu = data[2].JOB;
            this.salaryIbu = data[2].SALARY;
          } else if (data[2].TYPE == "W") {
            this.nameWali = data[2].NAME;
            this.nikWali = data[2].NIK;
            this.dobWali = data[2].DOB;
            this.addressWali = data[2].ADDRESS;
            this.hpWali = data[2].PHONE;
            this.educationWali = data[2].EDUCATION;
            this.jobWali = data[2].JOB;
            this.salaryWali = data[2].SALARY;
          }
        })
        .catch((error) => {
          this.dialogLoading = false;
          console.log("error parent " + error);
        });
    },

    dialogVerifyReqOpen(dok, id) {
      // console.log(dok);
      // console.log(id);
      this.dialogVerifyReqData = dok;
      this.dialogVerifyReqOption = id;
      this.dialogVerifyReq = true;
    },

    verifyDokumen(dok) {
      this.dialogVerifyReq = false;
      // console.log(dok);
      this.editedIndex = this.student.indexOf(dok);
      this.editedItem = Object.assign({}, dok);
      // this.dialog = true;
      //   console.log(dok.STUDENT_ID);
      //   console.log(dok.id);

      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("nisn", dok.STUDENT_ID);
      formData.set("id", dok.id);
      if (dok.documentPoint != null) {
        formData.set("documentpointid", dok.documentPoint.DOKUMENTPOINTID);
        formData.set("admissionid", dok.documentPoint.ADMISSIONID);
        formData.set(
          "admissionrequirementid",
          dok.documentPoint.ADMISSIONREQUIREMENTID
        );
      } else {
        formData.set("documentpointid", 0);
      }
      formData.set("status", 1);
      this.axios
        .post("/api/saveVerifyRequirement", formData, config)
        .then(() => {
          this.$vloading.hide();
          // let { data } = response.data;
          //   console.log(response.data.message);
          this.setAlert({
            status: true,
            color: "success",
            text: "Berhasil disimpan!",
          });
          this.closes();
          this.loadDokumen();
        })
        .catch((error) => {
          this.$vloading.hide();
          let responses = error.response;
          //console.log(responses.data.errors);
          //let { data } = error.response;
          this.setAlert({
            status: true,
            color: "error",
            // text: responses.data.message,
            text: responses.data.errors,
          });
        });
    },

    verifyDokumenTolak(dok) {
      this.dialogVerifyReq = false;
      this.editedIndex = this.student.indexOf(dok);
      this.editedItem = Object.assign({}, dok);
      // this.dialog = true;
      //   console.log(dok.STUDENT_ID);
      //   console.log(dok.id);

      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("nisn", dok.STUDENT_ID);
      formData.set("id", dok.id);
      formData.set("status", 2);
      if (dok.documentPoint != null) {
        formData.set("documentpointid", dok.documentPoint.DOKUMENTPOINTID);
        formData.set("admissionid", dok.documentPoint.ADMISSIONID);
        formData.set(
          "admissionrequirementid",
          dok.documentPoint.ADMISSIONREQUIREMENTID
        );
      } else {
        formData.set("documentpointid", 0);
      }
      this.axios
        .post("/api/saveVerifyRequirement", formData, config)
        .then(() => {
          this.$vloading.hide();
          // let { data } = response.data;
          //   console.log(response.data.message);
          this.setAlert({
            status: true,
            color: "success",
            text: "Berhasil disimpan!",
          });
          this.closes();
          this.loadDokumen();
        })
        .catch((error) => {
          this.$vloading.hide();
          let responses = error.response;
          //console.log(responses.data.errors);
          //let { data } = error.response;
          this.setAlert({
            status: true,
            color: "error",
            // text: responses.data.message,
            text: responses.data.errors,
          });
        });
    },

    loadDokumen() {
      this.dialogLoading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("nisn", localStorage.getItem("nisnDetail"));
      formData.set("admissionid", localStorage.getItem("admissionDetail"));
      this.axios
        .post("/api/verifyRequirement", formData, config)
        .then((response) => {
          this.dialogLoading = false;
          // let { data } = response.data;
          // //   item.samples = data;
          // this.dokumen = data;
          // console.log(this.dokumen);
          // console.log("dokumen", response);
          this.dokumen = response.data.data;
          this.reqlist = response.data.data.admissionRequirement;
          // console.log(this.dokumen);
          // console.log(this.reqlist);
          // check if all verified
        })
        .catch((error) => {
          this.dialogLoading = false;
          //   let responses = error;
          console.log(error);
          //   let { data } = error.response;
          //   this.setAlert({
          //     status: true,
          //     color: "error",
          //     // text: responses.data.message,
          //     text: responses.data.errors,
          //   });
        });
    },

    saveVerifyPoint(admissionReqID, docID) {
      // console.log(docID);
      // console.log(admissionReqID);
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("nisn", localStorage.getItem("USERID"));
      formData.set("documentpointid", docID);
      formData.set("admissionid", localStorage.getItem("admissionDetail"));
      formData.set("admissionrequirementid", admissionReqID);
      this.axios
        .post("/api/saveVerifyPoint", formData, config)
        .then(() => {
          this.$vloading.hide();
          // let { data } = response.data;
          //this.setAuth(data);
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Berhasil Disimpan!",
          });
          this.close();
          this.loadDokumen();
          this.closeDialogDoc();
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data,
          });
          this.close();
        });
      this.close();
    },

    // getRequirementList() {
    //   // console.log("getKabkot");
    //   let config = {
    //     headers: {
    //       Authorization: "Bearer " + this.user.token,
    //     },
    //   };
    //   let formData = new FormData();
    //   formData.set("ADMISSION_ID", localStorage.getItem("admissionDetail"));
    //   this.axios
    //     .post("/api/requirementList", formData, config)
    //     .then((response) => {
    //       // console.log(response.data.requirement_check);
    //       // let { data } = response.data;
    //       // console.log(data.requirement_check);
    //       let { data } = response.data;
    //       this.reqlist = data;
    //       console.log(this.reqlist);
    //       // let data = response.data.data[3].requirement_check[0].FILE_NAME;
    //       // console.log(data[0].NAME);
    //       // console.log(req.TYPE);
    //     })
    //     .catch((error) => {
    //       console.log("error" + error);
    //     });
    // },

    docItem(item) {
      // this.editedIndex = this.admission.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      this.dialogDoc = true;
      this.getDocumentPointList(item.id);
    },

    getDocumentPointList(item) {
      this.dialogLoading = true;
      // console.log("getKabkot");
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("ADMISSIONID", localStorage.getItem("admissionDetail"));
      formData.set("ADMISSIONREQUIREMENTID", item);
      this.axios
        .post("/api/documentPointList", formData, config)
        .then((response) => {
          this.dialogLoading = false;
          // console.log(response.data.requirement_check);
          // let { data } = response.data;
          // console.log(data.requirement_check);
          let { data } = response.data;
          this.docList = data;
          this.admissionReqID = item;
          // console.log(data);
          // let data = response.data.data[3].requirement_check[0].FILE_NAME;
          // console.log(data[0].NAME);
          // console.log(req.TYPE);
        })
        .catch((error) => {
          this.dialogLoading = false;
          console.log("error" + error);
        });
    },

    getMapel() {
      // console.log("getKabkot");
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post("/api/mapelList/" + localStorage.getItem("USERID"), {}, config)
        .then((response) => {
          var Data = response.data.data;
          this.mapel = Data;
        })
        .catch((error) => {
          console.log("error" + error);
        });
    },

    getNilai() {
      // console.log("getKabkot");
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("USERID", localStorage.getItem("USERID"));
      this.axios
        .post("/api/nilaiList", formData, config)
        .then((response) => {
          var Data = response.data.data;
          this.nilai = Data;
          // console.log(newData);
        })
        .catch((error) => {
          console.log("error" + error);
        });
    },

    schoolSelected() {
      // this.editedIndex = this.student.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
      // console.log("pilihan sekolah", item.NISN);

      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("nisn", localStorage.getItem("nisnDetail"));
      formData.set("admissionid", localStorage.getItem("admissionDetail"));
      this.axios
        .post("/api/studentSelected", formData, config)
        .then((response) => {
          this.dialogDelete = false;
          let { data } = response.data;
          this.schools = data;
          //   console.log(data);
          //   this.name = item.NAME;
          //   this.nisn = item.NISN;
          // console.log(data);
        })
        .catch((error) => {
          this.dialogDelete = false;
          let responses = error.response;
          //console.log(responses.data.errors);
          //let { data } = error.response;
          this.setAlert({
            status: true,
            color: "error",
            // text: responses.data.message,
            text: responses.data.errors,
          });
        });
    },

    getKabkot() {
      // console.log("getKabkot");
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post("/api/getWilayah", {}, config)
        .then((response) => {
          // console.log(response.data);
          this.cityItems = response.data;
        })
        .catch((error) => {
          console.log("error" + error);
        });
    },

    getKec(item) {
      // console.log("getKec");
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = {
        kode_wilayah: item,
      };
      this.axios
        .post("/api/getWilayah", formData, config)
        .then((response) => {
          // console.log("kecamatan", response.data);
          this.districtItems = response.data;
        })
        .catch((error) => {
          console.log("error" + error);
        });
    },

    getKel(item) {
      // console.log("getKel");
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = {
        kode_wilayah: item,
      };
      this.axios
        .post("/api/getWilayah", formData, config)
        .then((response) => {
          // console.log("kecamatan", response.data);
          this.subdistrictItems = response.data;
        })
        .catch((error) => {
          console.log("error" + error);
        });
    },

    getJenjang() {
      // console.log("getKabkot");
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post("/api/jenjang", {}, config)
        .then((response) => {
          // console.log(response.data);
          this.jenjangItems = response.data;
        })
        .catch((error) => {
          console.log("error" + error);
        });
    },

    getJalur() {
      // console.log("getKabkot");
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post("/api/auth/admission", {}, config)
        .then((response) => {
          // console.log(response.data);
          this.admissionItems = response.data.data;
        })
        .catch((error) => {
          console.log("error" + error);
        });
    },

    viewer(item) {
      // this.editedIndex = this.student.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogViewer = true;
    },

    editItem(item) {
      this.editedIndex = this.student.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.student.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.student.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDialogDoc() {
      this.dialogDoc = false;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.student[this.editedIndex], this.editedItem);
      } else {
        this.student.push(this.editedItem);
      }
      this.close();
    },

    closes() {
      this.$emit("closed", false);
    },

    verifUser() {
      this.loading = true;
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("ADMISSIONID", this.admissionDetail);
      formData.set("USERID", this.USERID);
      this.axios
        .post("/api/saveValidation", formData, config)
        .then(() => {
          this.loading = false;
          this.$vloading.hide();
          // let data = response.data.data;
          //   item.samples = data;
          localStorage.setItem("validationDetail", 1);
          this.$router.go();
        })
        .catch((error) => {
          this.loading = false;
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data,
          });
          this.close();
        });
    },

    pendaftaran() {
      // localStorage.admissionID = this.admissionDetail;
      // localStorage.admissionNAME = this.USERID;
      // this.$router.replace({ name: "Bukti Pendaftaran" });
      // console.log(this.admissionDetail);
      var newData = this.admissionItems.filter(
        (e) => e.id == this.admissionDetail
      );
      // console.log(newData[0].NAME);
      // console.log(this.USERID);
      localStorage.admissionID = this.admissionDetail;
      localStorage.admissionNAME = newData[0].NAME;
      localStorage.USERID = this.USERID;
      localStorage.email = this.email;
      this.$router.replace({ name: "Bukti Pendaftaran Admin" });
    },

    verifikasi() {
      // localStorage.admissionID = this.admissionDetail;
      // localStorage.admissionNAME = this.USERID;
      // this.$router.replace({ name: "Bukti Pendaftaran" });
      // console.log(this.admissionDetail);
      var newData = this.admissionItems.filter(
        (e) => e.id == this.admissionDetail
      );
      // console.log(newData[0].NAME);
      // console.log(this.USERID);
      localStorage.admissionID = this.admissionDetail;
      localStorage.admissionNAME = newData[0].NAME;
      localStorage.USERID = this.USERID;
      localStorage.email = this.email;
      this.$router.replace({ name: "Bukti Verifikasi" });
    },

    batalVerifikasi() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("ADMISSIONID", this.admissionDetail);
      formData.set("USERID", this.USERID);
      this.axios
        .post("/api/cancelValidation", formData, config)
        .then(() => {
          this.$vloading.hide();
          // let data = response.data.data;
          //   item.samples = data;
          localStorage.setItem("validationDetail", 0);
          this.$router.go();
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data,
          });
          this.close();
        });
    },

    batalVerifikasiDokumenPoin(dok) {
      // console.log(dok)
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("nisn", dok.STUDENT_ID);
      formData.set("requirement_check_id", dok.id);
      this.axios
        .post(
          "/api/cancelverifyPoint/" + dok.documentPoint.DOKUMENTPOINTID,
          formData,
          config
        )
        .then((response) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "success",
            text: response.data,
          });
          this.close();
          this.loadDokumen();
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.data,
          });
          this.close();
        });
    },
  },
};
</script>
