<template>
  <div>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Cari Data Siswa Di DAPODIK</v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-container fluid class="pa-10">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row align="center">
            <v-col cols="12">
              <v-text-field
                v-model="nisn"
                :rules="rules"
                type="text"
                label="Masukkan NISN"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="npsn"
                :rules="rules"
                type="text"
                label="Masukkan NPSN"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <div class="text-center">
                <v-btn
                  color="success"
                  :disabled="!valid || loading"
                  @click="findStudent"
                  :loading="loading"
                >
                  <v-icon left dark>mdi-text-search</v-icon>
                  Check Siswa
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
    <br />
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Data Siswa Di DAPODIK</v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-container fluid class="pa-10">
        <table style="width: 100%">
          <tr>
            <td style="width: 50%">Nama</td>
            <td>: {{ nama }}</td>
          </tr>
          <tr>
            <td>Tempat Lahir</td>
            <td>: {{ tempat }}</td>
          </tr>
          <tr>
            <td>Tanggal Lahir</td>
            <td>: {{ tgllahir }}</td>
          </tr>
          <tr>
            <td>Jenis Kelamin</td>
            <td>: {{ jenkel }}</td>
          </tr>
        </table>
      </v-container>
    </v-card>

    <!-- LOADER -->
    <v-dialog v-model="dialogLoading" persistent width="300">
      <v-card>
        <v-card-text>
          Sedang mengambil data...
          <v-progress-linear
            indeterminate
            color="primary"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "AkunOperator",
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    dialogLoading: false,
    loading: false,
    menu2: false,
    dialog: false,
    dialogDelete: false,
    valid: true,
    nisn: "",
    npsn: "",
    nama: "",
    tempat: "",
    tgllahir: "",
    jenkel: "",
    rules: [(v) => !!v || "Form ini wajib di isi"],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
    }),

    initialize() {},

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    findStudent() {
      if (this.$refs.form.validate()) {
        this.dialogLoading = true;
        this.loading = true;
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.token,
          },
        };
        let formData = new FormData();
        formData.set("NISN", this.nisn);
        formData.set("NPSN", this.npsn);
        this.axios
          .post("/api/findStudent", formData, config)
          .then((response) => {
            this.dialogLoading = false;
            this.loading = false;
            console.log(response.data);
            this.nama = response.data.data[0].nama;
            this.tempat = response.data.data[0].tempat_lahir;
            this.tgllahir = response.data.data[0].tanggal_lahir;
            this.jenkel = response.data.data[0].jenis_kelamin;
          })
          .catch((error) => {
            this.dialogLoading = false;
            this.loading = false;
            this.nama = "";
            this.tempat = "";
            this.tgllahir = "";
            this.jenkel = "";
            console.log(error.response.data);
            this.setAlert({
              status: true,
              color: "error",
              text: "Data Tidak Ditemukan!",
            });
          });
      }
      this.closes();
    },

    closes() {
      this.$emit("closed", false);
    },
  },
};
</script>