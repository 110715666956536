<template>
  <div>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title
          >Asesmen Diagnostik {{ admissionNAME }}</v-toolbar-title
        >
      </v-toolbar>
      <div class="text-center pa-2">
        <v-alert outlined type="info"
          ><b>Informasi!</b> Pertanyaan yang belum dijawab berwarna
          <span style="color: red"><b>Merah</b></span> dan yang sudah dijawab
          berwarna <span style="color: green"><b>Hijau</b></span
          >.</v-alert
        >
        <v-chip color="teal" dark class="my-2" large>
          <v-icon left>mdi-clock-time-four-outline</v-icon> Sisa waktu anda :
          {{ timerCBT }} Menit
        </v-chip>
      </div>
      <v-divider></v-divider>
      <v-container fluid class="pa-10">
        <v-form ref="form" v-model="valid" lazy-validation>
          <span v-for="dt in test" :key="dt.id">
            <v-row align="center">
              <v-col cols="12" sm="2" md="2">
                <v-subheader v-if="dt.studentAnswer == null" style="color: red">
                  <h4 style="margin-right: 4px">Soal</h4>
                  <h1 style="padding-bottom: 8px">
                    {{ test.indexOf(dt) + 1 }}
                  </h1>
                </v-subheader>
                <v-subheader v-else style="color: green">
                  <h4 style="margin-right: 4px">Soal</h4>
                  <h1 style="padding-bottom: 8px">
                    {{ test.indexOf(dt) + 1 }}
                  </h1>
                </v-subheader>

                <br />
                <small v-if="dt.CATEGORY == 2" style="color: orange"
                  ><i
                    ><b>Informasi!</b> Ini merupakan pertanyaan Kompleks. Anda
                    bisa memilih jawaban lebih dari 1.
                    <!-- Jangan lupa untuk menekan
                    tombol <b>SIMPAN</b> untuk menyimpan jawaban anda. -->
                  </i></small
                >
              </v-col>
              <v-col cols="12" sm="10" md="10">
                <v-alert text color="info" v-if="dt.CATEGORY == 1">
                  <h6>Pertanyaan</h6>
                  <span v-html="dt.QUESTION" style="color: black">{{
                    dt.QUESTION
                  }}</span>
                  <v-img
                    :src="baseUrl + urlFoto + dt.PICTURE"
                    max-width="50%"
                    v-if="dt.PICTURE != null"
                  ></v-img>
                  <v-divider class="my-4"></v-divider>
                  <h6>Pilihan Jawaban</h6>
                  <v-radio-group v-model="radio[test.indexOf(dt)].dts">
                    <v-radio
                      v-for="n in dt.option_test"
                      :key="n.id"
                      :value="n.id"
                      @click="
                        pilChange(
                          dt.paketid +
                            '-' +
                            dt.id +
                            '-' +
                            n.id +
                            '-' +
                            dt.BANKID
                        )
                      "
                    >
                      <template slot="label">
                        <span v-html="n.OPTION" style="margin-top: 16px">{{
                          n.OPTION
                        }}</span>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-alert>
                <v-alert text color="info" v-else>
                  <h6>Pertanyaan</h6>
                  <span v-html="dt.QUESTION" style="color: black">{{
                    dt.QUESTION
                  }}</span>
                  <v-img
                    :src="baseUrl + urlFoto + dt.PICTURE"
                    max-width="50%"
                    v-if="dt.PICTURE != null"
                  ></v-img>
                  <v-divider class="my-4"></v-divider>
                  <h6>Pilihan Jawaban</h6>
                  <!-- <p>{{ radio[test.indexOf(dt)].sel }}</p> -->
                  <v-checkbox
                    v-model="radio[test.indexOf(dt)].sel"
                    v-for="n in dt.option_test"
                    :key="n.id"
                    :value="n.id"
                    dense
                  >
                    <template slot="label">
                      <span v-html="n.OPTION">{{ n.OPTION }}</span>
                    </template>
                  </v-checkbox>
                  <v-btn
                    color="success"
                    small
                    @click="
                      pilChange(
                        dt.paketid +
                          '-' +
                          dt.id +
                          '-' +
                          radio[test.indexOf(dt)].sel +
                          '-' +
                          dt.BANKID
                      )
                    "
                  >
                    Simpan Jawaban Soal {{ test.indexOf(dt) + 1 }}
                  </v-btn>
                </v-alert>
              </v-col>
            </v-row>
          </span>
          <div class="text-right">
            <v-btn color="error" :disabled="!valid" @click="popup">
              Selesai Ujian
              <v-icon right dark>mdi-content-save</v-icon>
            </v-btn>
          </div>
        </v-form>
      </v-container>
    </v-card>

    <v-dialog v-model="dialogDelete" max-width="500px" persistent>
      <v-card>
        <v-card-title class="headline"
          >Anda yakin ingin mengakhiri ujian ini?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Batal</v-btn>
          <v-btn color="blue darken-1" text @click="submit(test[0].paketid)"
            >Ya</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- LOADER -->
    <v-dialog v-model="dialogLoading" hide-overlay width="300">
      <v-card>
        <v-card-text>
          Loading...
          <v-progress-linear
            indeterminate
            color="primary"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "CbtSiswaUjian",
  data() {
    return {
      timerCount: 9999999999999,
      timerCBT: "",
      radio: [],
      selected: [],
      dialogDelete: false,
      dialogLoading: false,
      left5: false,
      left3: false,
      left1: false,
      // baseUrl: process.env.VUE_APP_API_IMAGEURL,
      // urlFoto: "/storage/app/public/images/questionImages/",
      baseUrl: process.env.VUE_APP_API_URL,
      urlFoto: "/storage/images/questionImages/",
      valid: true,
      admissionNAME: localStorage.getItem("admissionNAME"),
      admissionID: localStorage.getItem("admissionID"),
      admissionPIN: localStorage.getItem("admissionPIN"),
      PAKETID: null,
      test: [],

      rules: [(v) => !!v || "Form ini wajib di isi"],
    };
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 10000);
          this.getTimeLeft(this.PAKETID);
        } else if (value == 0) {
          this.popup();
          // this.submit(this.test[0].paketid);
          // this.$router.replace({ name: "Cbt Siswa" });
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },

    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set",
    }),

    initialize() {
      this.getTakeTest();
    },

    popup() {
      this.dialogDelete = true;
    },

    pilChange(id) {
      console.log("pilihan", id);
      // this.dialogLoading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("ANSWER", id);
      this.axios
        .post("/api/saveAnswer", formData, config)
        .then(() => {
          // this.dialogLoading = false;
          // let { data } = response.data;
          //this.setAuth(data);
          // console.log(response.data);
          this.setAlert({
            status: true,
            color: "success",
            text: "Jawaban Berhasil Disimpan!",
          });
          this.close();
          this.getTakeTest();
        })
        .catch((error) => {
          // this.dialogLoading = false;
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data,
          });
          this.close();
        });
    },

    getTakeTest() {
      this.dialogLoading = true;
      // console.log("getKabkot");
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("ADMISSIONCHOICEID", localStorage.getItem("admissionID"));
      formData.set("PIN", localStorage.getItem("admissionPIN"));
      this.axios
        .post("/api/takeTest", formData, config)
        .then((response) => {
          this.dialogLoading = false;
          this.test = response.data;
          console.log(response.data);
          // this.getTimeLeft(response.data[0].paketid)
          this.PAKETID = response.data[0].paketid;

          var data = response.data;

          data.map((key) => {
            var student = key.studentAnswer;
            // console.log(student);

            if (student != null) {
              var dts = parseInt(key.studentAnswer.ANSWER);
              let answer = key.studentAnswer.ANSWER;
              const sel = answer.split(",").map(Number);

              this.radio.push({ dts, sel });
              console.log(this.radio);
            } else {
              dts = 0;
              const sel = [];
              this.radio.push({ dts, sel });
              // console.log(this.radio);
            }
          });

          // this.radio = parseInt(response.data[1].studentAnswer.ANSWER);
        })
        .catch((error) => {
          this.dialogLoading = false;
          console.log("error" + error);
        });
    },

    getTimeLeft(item) {
      // console.log("getKabkot");
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("PAKETID", item);
      formData.set("ADMISSIONID", localStorage.getItem("admissionID"));
      this.axios
        .post("/api/timeLeft", formData, config)
        .then((response) => {
          console.log(response.data);
          this.timerCBT = response.data.left;

          if (response.data.left == 5 && this.left5 == false) {
            this.setAlert({
              status: true,
              color: "warning",
              text: "Waktu anda tersisa 5 menit",
            });
            this.close();
            this.left5 = true;
          } else if (response.data.left == 3 && this.left3 == false) {
            this.setAlert({
              status: true,
              color: "warning",
              text: "Waktu anda tersisa 3 menit",
            });
            this.close();
            this.left3 = true;
          } else if (response.data.left == 1 && this.left1 == false) {
            this.setAlert({
              status: true,
              color: "warning",
              text: "Waktu anda tersisa 1 menit",
            });
            this.close();
            this.left1 = true;
          } else if (response.data.left == 0) {
            this.popup();
          }
        })
        .catch((error) => {
          console.log("error" + error);
        });
    },

    submit(item) {
      if (this.$refs.form.validate()) {
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.token,
          },
        };
        let formData = new FormData();
        formData.set("PAKETID", item);
        formData.set("ADMISSIONID", localStorage.getItem("admissionID"));
        formData.set("NPSN", this.user.temp);
        console.log(formData.getAll("npsn"));
        this.axios
          .post("/api/endTest", formData, config)
          .then((response) => {
            this.$vloading.hide();
            console.log(response);
            // let { data } = response.data;
            //this.setAuth(data);
            // console.log(response.data);
            this.setAlert({
              status: true,
              color: "success",
              text: "Ujian Selesai",
            });
            this.close();
            // this.getSelectedSekolah();
            localStorage.removeItem("PAKETID");
            this.$router.replace({ name: "Cbt Siswa" });
          })
          .catch((error) => {
            this.$vloading.hide();
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data,
            });
            this.close();
          });
      }
    },
    close() {
      this.$emit("closed", false);
    },

    closeDelete() {
      this.dialogDelete = false;
      // console.log(this.timerCount);
      if (this.timerCBT == 0) {
        this.popup();
      }
    },
  },
};
</script>