var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"ma-0 pa-1",attrs:{"grid-list-sm":""}},[_c('div',{staticClass:"text-left"},[_c('v-btn',{staticClass:"blue--text",attrs:{"small":"","text":"","to":"/persyaratan-jalur"}},[_c('v-icon',[_vm._v("mdi-chevron-left")]),_vm._v(" Kembali ")],1)],1)]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"text":"","color":"info"}},[_c('h5',[_vm._v("("+_vm._s(_vm.admissionNAME)+")")]),_vm._v(" Persyaratan "+_vm._s(_vm.syaratNAME)+" ")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.admission,"item-key":"name"},scopedSlots:_vm._u([{key:"item.nomor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.admission.indexOf(item) + 1)+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Dokumen")])],1),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"rules":_vm.rules,"label":"Nama Dokumen"},model:{value:(_vm.editedItem.NAME),callback:function ($$v) {_vm.$set(_vm.editedItem, "NAME", $$v)},expression:"editedItem.NAME"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"rules":_vm.rules,"type":"number","label":"Poin"},model:{value:(_vm.editedItem.POINT),callback:function ($$v) {_vm.$set(_vm.editedItem, "POINT", $$v)},expression:"editedItem.POINT"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Batal ")]),_c('v-btn',{attrs:{"color":"blue darken-1","disabled":!_vm.valid,"text":""},on:{"click":_vm.save}},[_vm._v(" Simpan ")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Anda yakin ingin menghapus data ini?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Batal")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_vm._v(" Tidak ada data ")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }