<template>
  <v-data-table
    :headers="headers"
    :items="admission"
    class="elevation-1"
    item-key="name"
    :search="search"
  >
    <template v-slot:item.nomor="{ item }">
      {{ admission.indexOf(item) + 1 }}
    </template>
    <template v-slot:item.STATUS="{ item }">
      <span v-if="item.STATUS == 1">Dibuka</span>
      <span v-else>Ditutup</span>
    </template>
    <template v-slot:item.zonasi="{ item }">
      <v-btn
        x-small
        class="mr-1"
        rounded
        color="info"
        dark
        @click="reqList(item)"
      >
        Lihat Zonasi
      </v-btn>
    </template>
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Daftar Sekolah</v-toolbar-title>
        </v-toolbar>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <!-- <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template> -->
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-form ref="form" v-model="valid" lazy-validation>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.NAME"
                        :rules="rules"
                        label="Nama"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.ADDRESS"
                        :rules="rules"
                        label="Alamat"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.DISTRICT"
                        :rules="rules"
                        label="Kecamatan"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.BOX"
                        :rules="rules"
                        label="Kode POS"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.EMAIL"
                        :rules="emailRules"
                        label="Email"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.LAT"
                        :rules="rules"
                        label="Latitude"
                      ></v-text-field> </v-col
                    ><v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.LONG"
                        :rules="rules"
                        label="Longitude"
                      ></v-text-field> </v-col
                    ><v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.STATUS"
                        :rules="rules"
                        label="Status"
                      ></v-text-field> </v-col
                    ><v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.LEVEL"
                        :rules="rules"
                        label="Level"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.SK_PENDIRIAN"
                        :rules="rules"
                        label="SK Pendirian"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.TANGGAL_SK_PENDIRIAN"
                        :rules="rules"
                        type="date"
                        label="Tanggal SK Pendirian"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.SK_OPERASIONAL"
                        :rules="rules"
                        label="SK Operasional"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.TANGGAL_SK_OPERASIONAL"
                        :rules="rules"
                        type="date"
                        label="Tanggal SK Operasional"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.TANGGAL_SELESAI_SK_OPERASIONAL"
                        :rules="rules"
                        type="date"
                        label="Tanggal Selesai SK Operasional"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.HEADMASTER"
                        :rules="rules"
                        label="Kepala Sekolah"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.PHONE"
                        :rules="rules"
                        label="Telepon"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-alert dense type="warning">
                    Pastikan anda telah memperbaharui Dapodik
                  </v-alert>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close"> Batal </v-btn>
                <v-btn
                  color="blue darken-1"
                  :disabled="!valid"
                  text
                  @click="save"
                >
                  Simpan
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline"
              >Anda yakin ingin menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Batal</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-text-field
        v-model="search"
        label="Cari Sekolah"
        class="mx-4"
      ></v-text-field>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
    </template>
    <template v-slot:no-data> Tidak ada data </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Sekolah",
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    menu2: false,
    dialog: false,
    dialogDelete: false,
    valid: true,
    search: "",
    headers: [
      { text: "No", value: "nomor" },
      {
        text: "Nama",
        align: "left",
        value: "NAME",
      },
      { text: "NPSN", value: "NPSN" },
      { text: "Alamat", value: "ADDRESS" },
      { text: "Zonasi", value: "zonasi" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    admission: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    statusItems: [
      { id: 1, name: "Dibuka" },
      { id: 0, name: "Ditutup" },
    ],
    rules: [(v) => !!v || "Form ini wajib di isi"],
    emailRules: [
      (v) => !!v || "E-mail wajib di isi",
      (v) =>
        /([a-zA-Z0-9_]{1,})(@)(gorontalo-kota).(id)+/.test(v) ||
        "Email Wajib menggunakan domain @gorontalo-kota.id.",
    ],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Data" : "Edit Data";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
    }),

    initialize() {
      this.getSchool();
    },

    getSchool() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post("/api/schoolList", {}, config)
        .then((response) => {
          this.$vloading.hide();
          this.admission = response.data.data;
          //console.log(this.info);
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log("error : " + error);
          let { data } = error.response;
          this.setAlert({
            status: true,
            color: "error",
            text: data.message,
          });
        });
    },

    editItem(item) {
      this.editedIndex = this.admission.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.admission.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      // console.log("deleted data", this.editedItem.id);
      // this.admission.splice(this.editedIndex, 1);
      // let config = {
      //   headers: {
      //     Authorization: "Bearer " + this.user.token,
      //   },
      // };
      // let formData = new FormData();
      // formData.set("ADMISSION_ID", this.editedItem.id);
      // this.axios
      //   .post("/api/auth/destroyAdmission", formData, config)
      //   .then(() => {
      //     // let { data } = response.data;
      //     //this.setAuth(data);
      //     // console.log(response);
      //     this.setAlert({
      //       status: true,
      //       color: "success",
      //       text: "Data Berhasil Dihapus!",
      //     });
      //     this.close();
      //     this.getSchool();
      //   })
      //   .catch((error) => {
      //     let responses = error;
      //     // console.log(responses);
      //     // let { data } = error.response;
      //     this.setAlert({
      //       status: true,
      //       color: "error",
      //       text: responses.data.errors,
      //     });
      //   });
      // this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          // UPDATE DATA
          // console.log("edited data");
          // Object.assign(this.admission[this.editedIndex], this.editedItem);
          this.$vloading.show();
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.token,
            },
          };
          let formData = new FormData();
          formData.set("NAME", this.editedItem.NAME);
          formData.set("ADDRESS", this.editedItem.ADDRESS);
          formData.set("DISTRICT", this.editedItem.DISTRICT);
          formData.set("BOX", this.editedItem.BOX);
          formData.set("EMAIL", this.editedItem.EMAIL);
          formData.set("LAT", this.editedItem.LAT);
          formData.set("LONG", this.editedItem.LONG);
          formData.set("STATUS", this.editedItem.STATUS);
          formData.set("LEVEL", this.editedItem.LEVEL);
          formData.set("SK_PENDIRIAN", this.editedItem.SK_PENDIRIAN);
          formData.set(
            "TANGGAL_SK_PENDIRIAN",
            this.editedItem.TANGGAL_SK_PENDIRIAN
          );
          formData.set("SK_OPERASIONAL", this.editedItem.SK_OPERASIONAL);
          formData.set(
            "TANGGAL_SK_OPERASIONAL",
            this.editedItem.TANGGAL_SK_OPERASIONAL
          );
          formData.set(
            "TANGGAL_SELESAI_SK_OPERASIONAL",
            this.editedItem.TANGGAL_SELESAI_SK_OPERASIONAL
          );
          formData.set("HEADMASTER", this.editedItem.HEADMASTER);
          formData.set("PHONE", this.editedItem.PHONE);
          // console.log(formData.getAll("STATUS"));
          this.axios
            .post("/api/schoolSave/" + this.editedItem.id, formData, config)
            .then(() => {
              this.$vloading.hide();
              // let { data } = response.data;
              //this.setAuth(data);
              // console.log(response);
              this.setAlert({
                status: true,
                color: "success",
                text: "Data Berhasil Diubah!",
              });
              this.close();
              this.getSchool();
            })
            .catch((error) => {
              this.$vloading.hide();
              let responses = error;
              // console.log(responses);
              // let { data } = error.response;
              this.setAlert({
                status: true,
                color: "error",
                text: responses.data.errors,
              });
            });
        } else {
          // CREATE DATA
          // console.log("created data");
          // this.admission.push(this.editedItem);
          // console.log(this.editedItem.STATUS);
          // let config = {
          //   headers: {
          //     Authorization: "Bearer " + this.user.token,
          //   },
          // };
          // let formData = new FormData();
          // formData.set("NAME", this.editedItem.NAME);
          // formData.set("OPEN", this.editedItem.OPEN);
          // formData.set("CLOSE", this.editedItem.CLOSE);
          // // formData.set("STATUS", this.editedItem.STATUS);
          // // console.log(formData.getAll("STATUS"));
          // this.axios
          //   .post("/api/auth/saveAdmission", formData, config)
          //   .then(() => {
          //     // let { data } = response.data;
          //     //this.setAuth(data);
          //     // console.log(response);
          //     this.setAlert({
          //       status: true,
          //       color: "success",
          //       text: "Data Berhasil Ditambahkan!",
          //     });
          //     this.close();
          //     this.getSchool();
          //   })
          //   .catch((error) => {
          //     let responses = error;
          //     // console.log(responses);
          //     // let { data } = error.response;
          //     this.setAlert({
          //       status: true,
          //       color: "error",
          //       text: responses.data.errors,
          //     });
          //   });
        }
        this.closes();
      }
    },

    reqList(item) {
      // console.log(item.id);
      localStorage.schoolID = item.NPSN;
      localStorage.schoolNAME = item.NAME;
      this.$router.replace({ name: "Zonasi" });
    },

    closes() {
      this.$emit("closed", false);
    },
  },
};
</script>