<template>
  <div>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title
          >Scoring {{ appName }}
          <v-btn color="error" @click="dialogReset = true">
            <v-icon left dark>mdi-lock-reset</v-icon>
            Reset Scoring
          </v-btn></v-toolbar-title
        >
      </v-toolbar>
      <v-divider></v-divider>
      <v-container fluid class="pa-10">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row align="center">
            <v-col cols="12">
              <v-select
                v-model="filterJalur"
                :items="jalurItems"
                :rules="rules"
                dense
                item-value="id"
                item-text="NAME"
                label="Pilih Jalur"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="filterPriority"
                :items="priorityItems"
                :rules="rules"
                dense
                item-value="id"
                item-text="NAME"
                label="Pilih Prioritas"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <div class="text-center">
                <v-btn color="success" :disabled="!valid" @click="submit">
                  <v-icon left dark>mdi-counter</v-icon>
                  Scoring
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>

    <v-dialog v-model="dialogReset" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Reset Scoring</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="filterJalurReset"
                  :items="jalurItems"
                  :rules="rules"
                  dense
                  item-value="id"
                  item-text="NAME"
                  label="Pilih Jalur"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="filterPIN"
                  :rules="rules"
                  label="Masukkan PIN"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogReset = false">
            Batal
          </v-btn>
          <v-btn color="blue darken-1" text @click="reset"> Reset </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- LOADER -->
    <v-dialog v-model="dialogLoading" persistent width="400">
      <v-card>
        <v-card-text class="pt-4">
          <b>Sedang melakukan scoring... mohon menunggu hingga proses selesai. <span style="color:red">(Jangan refresh halaman ini)</span></b>
          <v-progress-linear
            indeterminate
            color="primary"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ScoringBeta",
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    dialogLoading: false,
    dialogReset: false,
    menu2: false,
    dialog: false,
    dialogDelete: false,
    valid: true,
    search: "",
    status: "",
    headers: [
      { text: "No", value: "nomor" },
      {
        text: "Nomor Registrasi",
        align: "left",
        value: "admission.REGNUMBER",
      },
      { text: "Nama Peserta Didik", value: "student.NAME" },
      { text: "Status Registrasi Ulang", value: "registered" },
      // { text: "Actions", value: "actions", sortable: false },
    ],
    admission: [],
    sekolah: [],
    jalurItems: [],
    priorityItems: [
      { id: 1, NAME: "Pilihan 1" },
      { id: 2, NAME: "Pilihan 2" },
      { id: 3, NAME: "Pilihan 3" },
    ],
    filterJalur: null,
    filterPriority: null,
    filterJalurReset: null,
    filterPIN: "",
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    statusItems: [
      { id: 1, name: "Dibuka" },
      { id: 0, name: "Ditutup" },
    ],
    rules: [(v) => !!v || "Form ini wajib di isi"],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Data" : "Edit Data";
    },
    passwordConfirmationRule() {
      return () => this.password === this.confpassword || "Password harus sama";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
    }),

    initialize() {
      this.getJalur();
    },

    getJalur() {
      this.$vloading.show();
      let config = {
        headers: {},
      };
      this.axios.post("/api/getAdmission", {}, config).then((response) => {
        this.jalurItems = response.data;
        // console.log(this.jalurItems);
        this.$vloading.hide();
      });
    },

    editItem(item) {
      this.editedIndex = this.admission.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.admission.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    submit() {
      if (this.$refs.form.validate()) {
        // this.$vloading.show();
        this.dialogLoading = true;
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.token,
          },
        };
        let formData = new FormData();
        formData.set("PRIORITY", this.filterPriority);
        formData.set("ADMISSIONID", this.filterJalur);
        this.axios
          .post("/api/auth/scored", formData, config)
          .then((response) => {
            console.log(response.data);
            if (response.data == 0) {
              // this.$vloading.hide();
              this.dialogLoading = false;
              this.setAlert({
                status: true,
                color: "success",
                text: "Berhasil Melakukan Scoring!",
              });
              this.close();
            } else {
              this.submit();
            }
          })
          .catch((error) => {
            console.log(error.response.data);
            // this.$vloading.hide();
            this.dialogLoading = false;
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.message,
            });
            this.close();
          });
      }
      this.closes();
    },

    reset() {
      // console.log(this.filterJalurReset);
      // console.log(this.filterPIN);
      // if (this.$refs.form.validate()) {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("PIN", this.filterPIN);
      formData.set("ADMISSIONID", this.filterJalurReset);
      this.axios
        .post("/api/auth/resetScoring", formData, config)
        .then((response) => {
          console.log(response.data);
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "success",
            text: "Berhasil Melakukan Reset!",
          });
          this.close();
          this.dialogReset = false;
        })
        .catch((error) => {
          console.log(error.response.data);
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
          this.dialogReset = false;
        });
      // }
      // this.closes();
    },

    makePDF() {
      // window.html2canvas = html2canvas;
      // // var w = document.getElementById("print").offsetWidth;
      // // var h = document.getElementById("print").offsetHeight;
      // var doc = new jsPDF("p", "pt", "a4");
      // doc.html(document.getElementById("print"), {
      //   callback: function (pdf) {
      //     pdf.save("mypdf.pdf");
      //   },
      // });
      var prtContent = document.getElementById("print");
      var WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(prtContent.innerHTML);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },

    reqList(item) {
      // console.log(item.id);
      localStorage.bankID = item.id;
      localStorage.bankNAME = item.NAME;
      this.$router.replace({ name: "Soal" });
    },

    closes() {
      this.$emit("closed", false);
    },
  },
};
</script>