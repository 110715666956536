var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.admission,"item-key":"name"},scopedSlots:_vm._u([{key:"item.nomor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.admission.indexOf(item) + 1)+" ")]}},{key:"item.CBT_STATUS",fn:function(ref){
var item = ref.item;
return [(item.CBT_STATUS == 1)?_c('span',[_vm._v("Dibuka")]):_c('span',[_vm._v("Ditutup")])]}},{key:"item.WAKTU_UJIAN",fn:function(ref){
var item = ref.item;
return [(item.WAKTU_UJIAN != null)?_c('span',[_vm._v(" "+_vm._s(item.WAKTU_UJIAN)+" Menit ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.CBTSTATUS",fn:function(ref){
var item = ref.item;
return [(item.CBT > 0)?_c('span',[_vm._v("Ya")]):_c('span',[_vm._v("Tidak")])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Asesmen Diagnostik")])],1),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Masukkan PIN")])]),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"rules":_vm.rules,"label":"PIN"},model:{value:(_vm.editedItem.PIN),callback:function ($$v) {_vm.$set(_vm.editedItem, "PIN", $$v)},expression:"editedItem.PIN"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Batal ")]),_c('v-btn',{attrs:{"color":"blue darken-1","disabled":!_vm.valid,"text":""},on:{"click":_vm.save}},[_vm._v(" Mulai Ujian ")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Anda yakin ingin menghapus data ini?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Batal")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.ADMISSION_OPEN == 0)?_c('span',[(item.CBT > 0)?_c('span',[(item.CBT_STATUS > 0)?_c('span',[(item.PAKET_STATUS == null)?_c('span',[_c('v-btn',{staticClass:"mr-1",attrs:{"x-small":"","rounded":"","color":"info","dark":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" Ikut Ujian ")])],1):_vm._e(),(item.PAKET_STATUS == 0)?_c('span',[_c('v-btn',{staticClass:"mr-1",attrs:{"x-small":"","rounded":"","color":"warning","dark":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" Lanjutkan Ujian ")])],1):_vm._e(),(item.PAKET_STATUS == 1)?_c('span',[_c('v-btn',{staticClass:"mt-2",attrs:{"x-small":"","depressed":"","rounded":"","color":"error","dark":""}},[_vm._v(" Selesai ")]),_c('br'),_c('small',[_vm._v("Score :")]),_vm._v(" "),_c('b',[_vm._v(_vm._s(item.SCORE))])],1):_vm._e()]):_vm._e()]):_vm._e()]):_c('span')]}},{key:"no-data",fn:function(){return [_vm._v(" Tidak ada data ")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }