<template>
  <div>
    <span v-for="dt in parentsItems" :key="dt.id">
      {{ dt.id }} {{ dt.NAME }} {{ dt.SALARY }}
    </span>
    <v-row>
      <v-col cols="12" sm="4" md="4">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Data Ayah Kandung</v-toolbar-title>
          </v-toolbar>

          <v-divider></v-divider>

          <v-container fluid>
            <v-form ref="formAyah" v-model="validAyah" lazy-validation>
              <v-row align="center">
                <v-col class="d-flex" cols="12" sm="12">
                  <v-text-field
                    v-model="nameAyah"
                    :rules="RulesAyah"
                    type="text"
                    label="Nama Ayah Kandung"
                    required
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12">
                  <v-text-field
                    v-model="nikAyah"
                    :rules="RulesAyahNumber"
                    type="number"
                    label="NIK Ayah"
                    required
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12">
                  <v-text-field
                    v-model="dobAyah"
                    :rules="RulesAyah"
                    type="date"
                    label="Tanggal Lahir"
                    required
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12">
                  <v-text-field
                    v-model="addressAyah"
                    :rules="RulesAyah"
                    type="text"
                    label="Alamat"
                    required
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12">
                  <v-text-field
                    v-model="hpAyah"
                    :rules="RulesAyahNumber"
                    type="number"
                    label="Nomor HP"
                    required
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12">
                  <v-select
                    v-model="educationAyah"
                    :rules="RulesAyah"
                    :items="educationItems"
                    item-value="id"
                    item-text="name"
                    label="Pendidikan"
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12">
                  <v-select
                    v-model="jobAyah"
                    :rules="RulesAyah"
                    :items="jobItems"
                    item-value="id"
                    item-text="name"
                    label="Pekerjaan"
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12">
                  <v-select
                    v-model="salaryAyah"
                    :rules="RulesAyah"
                    :items="salaryItems"
                    item-value="id"
                    item-text="name"
                    label="Penghasilan Bulanan"
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12">
                  <div class="text-xs-center">
                    <v-btn
                      color="success"
                      :disabled="!validAyah || loadingAyah"
                      @click="simpanAyah"
                      :loading="loadingAyah"
                    >
                      Simpan
                      <v-icon right dark>mdi-content-save</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card>
      </v-col>

      <v-col cols="12" sm="4" md="4">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Data Ibu Kandung</v-toolbar-title>
          </v-toolbar>

          <v-divider></v-divider>

          <v-container fluid>
            <v-form ref="formIbu" v-model="validIbu" lazy-validation>
              <v-row align="center">
                <v-col class="d-flex" cols="12" sm="12">
                  <v-text-field
                    v-model="nameIbu"
                    :rules="RulesIbu"
                    type="text"
                    label="Nama Ibu Kandung"
                    required
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12">
                  <v-text-field
                    v-model="nikIbu"
                    :rules="RulesIbuNumber"
                    type="number"
                    label="NIK Ibu"
                    required
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12">
                  <v-text-field
                    v-model="dobIbu"
                    :rules="RulesIbu"
                    type="date"
                    label="Tanggal Lahir"
                    required
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12">
                  <v-text-field
                    v-model="addressIbu"
                    :rules="RulesIbu"
                    type="text"
                    label="Alamat"
                    required
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12">
                  <v-text-field
                    v-model="hpIbu"
                    :rules="RulesIbuNumber"
                    type="number"
                    label="Nomor HP"
                    required
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12">
                  <v-select
                    v-model="educationIbu"
                    :rules="RulesIbu"
                    :items="educationItems"
                    item-value="id"
                    item-text="name"
                    label="Pendidikan"
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12">
                  <v-select
                    v-model="jobIbu"
                    :rules="RulesIbu"
                    :items="jobItems"
                    item-value="id"
                    item-text="name"
                    label="Pekerjaan"
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12">
                  <v-select
                    v-model="salaryIbu"
                    :rules="RulesIbu"
                    :items="salaryItems"
                    item-value="id"
                    item-text="name"
                    label="Penghasilan Bulanan"
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12">
                  <div class="text-xs-center">
                    <v-btn
                      color="success"
                      :disabled="!validIbu || loadingIbu"
                      @click="simpanIbu"
                      :loading="loadingIbu"
                    >
                      Simpan
                      <v-icon right dark>mdi-content-save</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card>
      </v-col>

      <v-col cols="12" sm="4" md="4">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Data Wali</v-toolbar-title>
          </v-toolbar>

          <v-divider></v-divider>

          <v-container fluid>
            <v-form ref="formWali" v-model="validWali" lazy-validation>
              <v-row align="center">
                <v-col class="d-flex" cols="12" sm="12">
                  <v-text-field
                    v-model="nameWali"
                    :rules="RulesWali"
                    type="text"
                    label="Nama Wali"
                    required
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12">
                  <v-text-field
                    v-model="nikWali"
                    :rules="RulesWaliNumber"
                    type="number"
                    label="NIK Wali"
                    required
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12">
                  <v-text-field
                    v-model="dobWali"
                    :rules="RulesWali"
                    type="date"
                    label="Tanggal Lahir"
                    required
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12">
                  <v-text-field
                    v-model="addressWali"
                    :rules="RulesWali"
                    type="text"
                    label="Alamat"
                    required
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12">
                  <v-text-field
                    v-model="hpWali"
                    :rules="RulesWaliNumber"
                    type="number"
                    label="Nomor HP"
                    required
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12">
                  <v-select
                    v-model="educationWali"
                    :rules="RulesWali"
                    :items="educationItems"
                    item-value="id"
                    item-text="name"
                    label="Pendidikan"
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12">
                  <v-select
                    v-model="jobWali"
                    :rules="RulesWali"
                    :items="jobItems"
                    item-value="id"
                    item-text="name"
                    label="Pekerjaan"
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12">
                  <v-select
                    v-model="salaryWali"
                    :rules="RulesWali"
                    :items="salaryItems"
                    item-value="id"
                    item-text="name"
                    label="Penghasilan Bulanan"
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12">
                  <div class="text-xs-center">
                    <v-btn
                      color="success"
                      :disabled="!validWali || loadingWali"
                      @click="simpanWali"
                      :loading="loadingWali"
                    >
                      Simpan
                      <v-icon right dark>mdi-content-save</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <!-- LOADER -->
    <v-dialog v-model="dialogLoading" persistent width="300">
      <v-card>
        <v-card-text>
          Sedang mengambil data...
          <v-progress-linear
            indeterminate
            color="primary"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "BiodataOrangTua",
  data() {
    return {
      educationItems: [
        { id: "1", name: "Tidak sekolah" },
        { id: "2", name: "Putus SD" },
        { id: "3", name: "SD Sederajat" },
        { id: "4", name: "SMP Sederajat" },
        { id: "5", name: "SMA Sederajat" },
        { id: "6", name: "D1" },
        { id: "7", name: "D2" },
        { id: "8", name: "D3" },
        { id: "9", name: "D4/S1" },
        { id: "10", name: "S2" },
        { id: "11", name: "S3" },
      ],
      jobItems: [
        { id: "01", name: "Tidak bekerja" },
        { id: "02", name: "Nelayan" },
        { id: "03", name: "Petani" },
        { id: "04", name: "Peternak" },
        { id: "05", name: "PNS/TNI/POLRI" },
        { id: "06", name: "Karyawan Swasta" },
        { id: "07", name: "Pedagang Kecil" },
        { id: "08", name: "Pedagang Besar" },
        { id: "09", name: "Wiraswasta" },
        { id: "10", name: "Wirausaha" },
        { id: "11", name: "Buruh" },
        { id: "12", name: "Pensiunan" },
      ],
      salaryItems: [
        { id: "01", name: "< Rp. 500.000" },
        { id: "02", name: "Rp. 500.000-Rp.999.999" },
        { id: "03", name: "Rp. 1.000.000-Rp.1.999.999" },
        { id: "04", name: "Rp.2.000.000-Rp.4.999.999" },
        { id: "05", name: "Rp.5.000.000-Rp.20.000.000" },
        { id: "06", name: "> Rp.20.000.000" },
        { id: "07", name: "Tidak Berpenghasilan" },
      ],

      dialogLoading: false,
      parentsItems: [{ id: null, NAME: "", TYPE: "" }],

      validAyah: true,
      validIbu: true,
      validWali: true,
      loadingAyah: false,
      loadingIbu: false,
      loadingWali: false,

      nameAyah: "",
      nikAyah: "",
      dobAyah: "",
      addressAyah: "",
      hpAyah: "",
      educationAyah: "",
      jobAyah: "",
      salaryAyah: "",

      nameIbu: "",
      nikIbu: "",
      dobIbu: "",
      addressIbu: "",
      hpIbu: "",
      educationIbu: "",
      jobIbu: "",
      salaryIbu: "",

      nameWali: "",
      nikWali: "",
      dobWali: "",
      addressWali: "",
      hpWali: "",
      educationWali: "",
      jobWali: "",
      salaryWali: "",

      RulesAyah: [(v) => !!v || "Form ini wajib di isi"],
      RulesIbu: [(v) => !!v || "Form ini wajib di isi"],
      RulesWali: [(v) => !!v || "Form ini wajib di isi"],
      RulesAyahNumber: [
        (v) => !!v || "Form ini wajib di isi",
        (v) => (v && v.length >= 10) || "Minimal 10 karakter",
      ],
      RulesIbuNumber: [
        (v) => !!v || "Form ini wajib di isi",
        (v) => (v && v.length >= 10) || "Minimal 10 karakter",
      ],
      RulesWaliNumber: [
        (v) => !!v || "Form ini wajib di isi",
        (v) => (v && v.length >= 10) || "Minimal 10 karakter",
      ],
    };
  },

  created() {
    this.initialize();
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      setAlert: "alert/set",
    }),

    initialize() {
      this.getParent();
    },

    getParent() {
      this.dialogLoading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post("/api/getParent", {}, config)
        .then((response) => {
          this.dialogLoading = false;
          // console.log(response.data);
          // this.parentsItems = response.data.data;
          let data = response.data.data;
          console.log(data);

          // console.log(data['A'][0]);

          // Data Ayah
          this.nameAyah = data["A"][0].NAME;
          this.nikAyah = data["A"][0].NIK;
          this.dobAyah = data["A"][0].DOB;
          this.addressAyah = data["A"][0].ADDRESS;
          this.hpAyah = data["A"][0].PHONE;
          this.educationAyah = data["A"][0].EDUCATION;
          this.jobAyah = data["A"][0].JOB;
          this.salaryAyah = data["A"][0].SALARY;

          // Data Ibu
          this.nameIbu = data["I"][0].NAME;
          this.nikIbu = data["I"][0].NIK;
          this.dobIbu = data["I"][0].DOB;
          this.addressIbu = data["I"][0].ADDRESS;
          this.hpIbu = data["I"][0].PHONE;
          this.educationIbu = data["I"][0].EDUCATION;
          this.jobIbu = data["I"][0].JOB;
          this.salaryIbu = data["I"][0].SALARY;

          // Data Wali
          this.nameWali = data["W"][0].NAME;
          this.nikWali = data["W"][0].NIK;
          this.dobWali = data["W"][0].DOB;
          this.addressWali = data["W"][0].ADDRESS;
          this.hpWali = data["W"][0].PHONE;
          this.educationWali = data["W"][0].EDUCATION;
          this.jobWali = data["W"][0].JOB;
          this.salaryWali = data["W"][0].SALARY;
        })
        .catch((error) => {
          this.dialogLoading = false;
          console.log("error" + error);
        });
    },

    simpanAyah() {
      if (this.$refs.formAyah.validate()) {
        this.loadingAyah = true;
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.token,
          },
        };
        let formData = new FormData();
        formData.set("TYPE", "A");
        formData.set("NAME", this.nameAyah);
        formData.set("NIK", this.nikAyah);
        formData.set("DOB", this.dobAyah);
        formData.set("ADDRESS", this.addressAyah);
        formData.set("PHONE", this.hpAyah);
        formData.set("EDUCATION", this.educationAyah);
        formData.set("JOB", this.jobAyah);
        formData.set("SALARY", this.salaryAyah);
        this.axios
          .post("/api/saveParent", formData, config)
          .then((response) => {
            // let { data } = response.data;
            //this.setAuth(data);
            console.log(response);
            this.setAlert({
              status: true,
              color: "success",
              text: "Data Berhasil Disimpan",
            });
            this.close();
            this.getParent();
            this.loadingAyah = false;
          })
          .catch((error) => {
            this.isSendingAyah = false;
            let responses = error;
            // console.log(responses.data.errors);
            //let { data } = error.response;
            this.setAlert({
              status: true,
              color: "error",
              // text: responses.data.message,
              text: responses,
            });
            this.loadingAyah = false;
          });
      }
    },

    simpanIbu() {
      if (this.$refs.formIbu.validate()) {
        this.loadingIbu = true;
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.token,
          },
        };
        let formData = new FormData();
        formData.set("TYPE", "I");
        formData.set("NAME", this.nameIbu);
        formData.set("NIK", this.nikIbu);
        formData.set("DOB", this.dobIbu);
        formData.set("ADDRESS", this.addressIbu);
        formData.set("PHONE", this.hpIbu);
        formData.set("EDUCATION", this.educationIbu);
        formData.set("JOB", this.jobIbu);
        formData.set("SALARY", this.salaryIbu);
        this.axios
          .post("/api/saveParent", formData, config)
          .then((response) => {
            // let { data } = response.data;
            //this.setAuth(data);
            console.log(response);
            this.setAlert({
              status: true,
              color: "success",
              text: "Data Berhasil Disimpan",
            });
            this.close();
            this.getParent();
            this.loadingIbu = false;
          })
          .catch((error) => {
            this.isSendingIbu = false;
            let responses = error;
            // console.log(responses.data.errors);
            //let { data } = error.response;
            this.setAlert({
              status: true,
              color: "error",
              // text: responses.data.message,
              text: responses,
            });
            this.loadingIbu = false;
          });
      }
    },

    simpanWali() {
      if (this.$refs.formWali.validate()) {
        this.loadingWali = true;
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.token,
          },
        };
        let formData = new FormData();
        formData.set("TYPE", "W");
        formData.set("NAME", this.nameWali);
        formData.set("NIK", this.nikWali);
        formData.set("DOB", this.dobWali);
        formData.set("ADDRESS", this.addressWali);
        formData.set("PHONE", this.hpWali);
        formData.set("EDUCATION", this.educationWali);
        formData.set("JOB", this.jobWali);
        formData.set("SALARY", this.salaryWali);
        this.axios
          .post("/api/saveParent", formData, config)
          .then((response) => {
            // let { data } = response.data;
            //this.setAuth(data);
            console.log(response);
            this.setAlert({
              status: true,
              color: "success",
              text: "Data Berhasil Disimpan",
            });
            this.close();
            this.getParent();
            this.loadingWali = false;
          })
          .catch((error) => {
            this.isSendingWali = false;
            let responses = error;
            // console.log(responses.data.errors);
            //let { data } = error.response;
            this.setAlert({
              status: true,
              color: "error",
              // text: responses.data.message,
              text: responses,
            });
            this.loadingWali = false;
          });
      }
    },

    close() {
      this.$emit("closed", false);
    },
  },
};
</script>