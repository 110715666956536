<template>
  <div>
    <v-container class="ma-0 pa-1" grid-list-sm>
      <div class="text-left">
        <v-btn small text to="/pilih-jalur" class="blue--text">
          <v-icon>mdi-chevron-left</v-icon> Kembali
        </v-btn>
      </div>
    </v-container>
    <!-- <v-card v-if="exist">
      <v-toolbar dark color="primary">
        <v-toolbar-title>Pilih Sekolah {{ admissionNAME }}</v-toolbar-title>
      </v-toolbar>

      <v-divider></v-divider>
      <v-container fluid class="pa-10">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row align="center">
            <v-col cols="4">
              <v-subheader> Sekolah Pilihan 1 </v-subheader>
            </v-col>
            <v-col cols="8">
              <v-autocomplete
                v-model="pilihan1"
                :rules="pilRules"
                :items="pilihanItems"
                dense
                item-value="SCHOOL_ID"
                item-text="NAME"
                label="Pilihan 1"
                readonly
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-subheader> Sekolah Pilihan 2 </v-subheader>
            </v-col>
            <v-col cols="8">
              <v-autocomplete
                v-model="pilihan2"
                :items="pilihanItems"
                :rules="pilRules.concat(pilConfirmationRule)"
                dense
                item-value="SCHOOL_ID"
                item-text="NAME"
                label="Pilihan 2"
                readonly
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-subheader> Sekolah Pilihan 3 </v-subheader>
            </v-col>
            <v-col cols="8">
              <v-autocomplete
                v-model="pilihan3"
                :rules="pilRules.concat(pilConfirmationRule)"
                :items="pilihanItems"
                dense
                item-value="SCHOOL_ID"
                item-text="NAME"
                label="Pilihan 3"
                readonly
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card> -->

    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Pilih Sekolah {{ admissionNAME }}</v-toolbar-title>
      </v-toolbar>

      <v-divider></v-divider>
      <v-container fluid class="pa-10">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row align="center">
            <v-col cols="4">
              <v-subheader> Sekolah Pilihan 1 </v-subheader>
            </v-col>
            <v-col cols="8">
              <v-autocomplete
                v-model="pilihan1"
                :rules="pilRules"
                :items="pilihanItems"
                dense
                item-value="SCHOOL_ID"
                item-text="NAME"
                label="Pilihan 1"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-subheader> Sekolah Pilihan 2 </v-subheader>
            </v-col>
            <v-col cols="8">
              <v-autocomplete
                v-model="pilihan2"
                :items="pilihanItems"
                :rules="pilRules.concat(pilConfirmationRule)"
                dense
                item-value="SCHOOL_ID"
                item-text="NAME"
                label="Pilihan 2"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-subheader> Sekolah Pilihan 3 </v-subheader>
            </v-col>
            <v-col cols="8">
              <v-autocomplete
                v-model="pilihan3"
                :rules="pilRules.concat(pilConfirmationRule)"
                :items="pilihanItems"
                dense
                item-value="SCHOOL_ID"
                item-text="NAME"
                label="Pilihan 3"
              ></v-autocomplete>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <div class="text-xs-center">
                <v-btn
                  color="success"
                  :disabled="!valid || sending"
                  @click="submit"
                  :loading="sending"
                >
                  Simpan
                  <v-icon right dark>mdi-plus</v-icon> </v-btn
                ><br />
                <h5>
                  <!-- *Pilihan sekolah yang telah disimpan tidak akan bisa diubah
                  lagi. -->
                </h5>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Pilihan",
  data() {
    return {
      sending: false,
      exist: false,
      hilang: false,
      valid: true,
      pilihan1: null,
      pilihan2: null,
      pilihan3: null,
      pilihanItems: [{ SCHOOL_ID: "", NAME: "" }],
      Rules: [(v) => !!v || "Form ini wajib di isi"],
      pilRules: [(v) => !!v || "Form ini wajib di isi"],
      admissionID: localStorage.getItem("admissionID"),
      admissionNAME: localStorage.getItem("admissionNAME"),
    };
  },

  created() {
    this.initialize();
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    pilConfirmationRule() {
      return () =>
        (this.pilihan1 != this.pilihan2 &&
          this.pilihan1 != this.pilihan3 &&
          this.pilihan2 != this.pilihan3) ||
        "Pilihan sekolah tidak bisa sama";
    },
  },
  methods: {
    ...mapActions({
      setAlert: "alert/set",
    }),

    initialize() {
      this.getSekolah();
      this.getSelectedSekolah();
    },

    pil1Change: function (e) {
      console.log("pilihan1", e);
      //   this.kabkotKode = e;
      //   this.getKec();
      // localStorage.kabkot = kabkot;
    },
    pil2Change: function (e) {
      console.log("pilihan2", e);
      //   this.kabkotKode = e;
      //   this.getKec();
      // localStorage.kabkot = kabkot;
    },
    pil3Change: function (e) {
      console.log("pilihan3", e);
      //   this.kabkotKode = e;
      //   this.getKec();
      // localStorage.kabkot = kabkot;
    },
    getSekolah() {
      this.$vloading.show();
      // console.log("getKabkot");
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post(
          "/api/auth/activeSchool/" + localStorage.getItem("admissionID"),
          {},
          config
        )
        .then((response) => {
          this.$vloading.hide();
          this.pilihanItems = response.data.data;
          // console.log(this.pilihanItems);
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log("error" + error);
        });
    },
    getSelectedSekolah() {
      this.$vloading.show();
      // console.log("getKabkot");
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("admissionid", localStorage.getItem("admissionID"));
      this.axios
        .post("/api/studentSelected", formData, config)
        .then((response) => {
          this.$vloading.hide();
          // console.log(response.data);
          let { data } = response.data;
          // console.log(data);
          if (data.length > 0) {
            this.pilihan1 = data[0].NPSN;
            this.pilihan2 = data[1].NPSN;
            this.pilihan3 = data[2].NPSN;
            this.exist = true;
          } else {
            this.exist = false;
            this.hilang = true;
          }
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log("error" + error);
        });
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.sending = true;
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.token,
          },
        };
        let formData = new FormData();
        formData.set("npsn", [this.pilihan1, this.pilihan2, this.pilihan3]);
        formData.set("admissionid", localStorage.getItem("admissionID"));
        // console.log(formData.getAll("npsn"));
        this.axios
          .post("/api/pickSekolah", formData, config)
          .then(() => {
            this.sending = false;
            // let { data } = response.data;
            //this.setAuth(data);
            // console.log(response.data);
            this.setAlert({
              status: true,
              color: "success",
              text: "Berhasil disimpan",
            });
            this.close();
            this.getSelectedSekolah();
            this.$router.replace({ name: "Pilih Jalur" });
          })
          .catch((error) => {
            this.sending = false;
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data,
            });
            this.close();
          });
      }
    },
    close() {
      this.$emit("closed", false);
    },
  },
};
</script>