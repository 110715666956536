<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Buat akun PPDB!</v-toolbar-title>
    </v-toolbar>

    <v-divider></v-divider>

    <v-container fluid>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-select
          v-model="tujuan"
          :items="tujuanItems"
          :rules="rules"
          item-value="id"
          item-text="name"
          label="Tujuan"
        ></v-select>

        <v-text-field
          v-model="nisn"
          :rules="nisnRules"
          label="NISN (Nomor Induk Siswa Nasional)"
          required
          append-icon="mdi-account"
        ></v-text-field>

        <v-text-field
          v-model="npsn"
          :rules="npsnRules"
          label="NPSN (Nomor Pokok Sekolah Nasional)"
          required
          append-icon="mdi-school"
        ></v-text-field>

        <v-text-field
          v-if="tujuan == 1"
          v-model="email"
          :rules="emailRulesTK"
          label="E-mail"
          required
          append-icon="mdi-email"
        ></v-text-field>

        <v-text-field
          v-else
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          required
          append-icon="mdi-email"
        ></v-text-field>

        <!-- <v-text-field
          v-model="dob"
          :rules="dobRules"
          type="date"
          label="Tanggal Lahir"
          required
        ></v-text-field> -->

        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dob"
              :rules="dobRules"
              label="Tanggal Lahir"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            ref="picker"
            v-model="dob"
            :max="new Date().toISOString().substr(0, 10)"
            min="1950-01-01"
            @change="save"
          ></v-date-picker>
        </v-menu>

        <v-text-field
          v-model="password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="passwordRules"
          :type="showPassword ? 'text' : 'password'"
          label="Password"
          counter
          @click:append="showPassword = !showPassword"
        ></v-text-field>

        <v-text-field
          v-model="confpassword"
          :append-icon="showconfPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="confpasswordRules.concat(passwordConfirmationRule)"
          :type="showconfPassword ? 'text' : 'password'"
          label="Konfirmasi Password"
          counter
          @click:append="showconfPassword = !showconfPassword"
        ></v-text-field>

        <!-- <v-checkbox
    
              v-model="checkbox"
    
              :rules="[(v) => !!v || 'You must agree to continue!']"
    
              label="Do You agree with our Privacy Policy?"
    
              required
    
            ></v-checkbox> -->

        <div class="text-xs-center">
          <v-btn
            color="success"
            :disabled="!valid || isSending"
            @click="submit"
          >
            Daftar

            <v-icon right dark>mdi-account-plus</v-icon>
          </v-btn>
          &nbsp;
          <v-btn @click="clear">
            Reset

            <v-icon right dark>mdi-lock-reset</v-icon>
          </v-btn>
        </div>
        <br />
        <!-- <v-alert dense text type="info">
          Belum punya email @belajar.id? Silahkan mengajukan permohonan email Siswa melalui link berikut <a href="https://s.id/aktivasi-akunedukasi-CPD" target="_blank">Aktivasi Akun Edukasi</a>
        </v-alert> -->
      </v-form>
    </v-container>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Daftar",
  data() {
    return {
      menu: false,
      valid: true,
      isSending: false,
      dob: "",
      dobRules: [(v) => !!v || "Tanggal Lahir wajib di isi"],
      nisn: "",
      nisnRules: [(v) => !!v || "NISN wajib di isi"],
      npsn: "",
      npsnRules: [(v) => !!v || "NPSN wajib di isi"],
      email: "",
      emailValid: false,
      emailRules: [
        (v) => !!v || "E-mail wajib di isi",
        (v) =>
          /([a-zA-Z0-9_]{1,})(@)(sd).(belajar).(id)+/.test(v) ||
          "Email Wajib menggunakan domain @sd.belajar.id. Bagi yang belum punya, silahkan mengajukan permohonan email Siswa ke Pusdatin Kemdikbud.",
      ],
      emailRulesTK: [
        (v) => !!v || "E-mail wajib di isi",
        (v) =>
          /([a-zA-Z0-9_]{1,})(@)(gorontalo-kota).(id)+/.test(v) ||
          "Email Wajib menggunakan domain @gorontalo-kota.id. Bagi yang belum punya, silahkan mengajukan permohonan email Siswa ke Panitia PPDB 2024 Kota Gorontalo.",
      ],
      showPassword: false,
      password: "",
      passwordRules: [
        (v) => !!v || "Password wajib di isi",
        (v) => (v && v.length >= 8) || "Minimal 8 karakter",
      ],
      showconfPassword: false,
      confpassword: "",
      confpasswordRules: [
        (v) => !!v || "Konfirmasi Password wajib di isi",
        (v) => (v && v.length >= 8) || "Minimal 8 karakter",
      ],
      tujuanItems: [
        { id: 1, name: "TK ke SD" },
        { id: 2, name: "SD ke SMP" },
      ],
      // checkbox: false,
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    passwordConfirmationRule() {
      return () => this.password === this.confpassword || "Password harus sama";
    },
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
    ...mapActions({
      setAlert: "alert/set",
      setAuth: "auth/set",
    }),
    submit() {
      if (this.$refs.form.validate()) {
        this.isSending = true;
        let formData = new FormData();
        formData.set("nisn", this.nisn);
        formData.set("npsn", this.npsn);
        formData.set("email", this.email);
        formData.set("password", this.password);
        formData.set("password_confirmation", this.confpassword);
        formData.set("dob", this.dob);
        this.axios
          .post("/api/check", formData)
          .then((response) => {
            this.isSending = false;
            let { data } = response.data;
            console.log(data);
            // this.setAuth(data);
            this.setAlert({
              status: true,
              color: "success",
              text: "Pendaftaran Berhasil. Silahkan Melakukan Login!",
            });
            this.close();
            this.$router.replace({ name: "Home" });
          })
          .catch((error) => {
            this.isSending = false;
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data,
            });
            this.close();
          });
      }
    },
    close() {
      this.$emit("closed", false);
    },
    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>
