<template>
  <div>
    <v-card v-if="studentBio == 'false'">
      <v-toolbar dark color="primary">
        <v-toolbar-title>Cek Data Siswa </v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-container fluid>
        <v-form ref="form" v-model="valid" lazy-validation>
          <!-- <v-text-field
            v-model="nisn"
            :rules="nisnRules"
            type="number"
            label="NISN (Nomor Induk Siswa Nasional)"
          ></v-text-field> -->
          <v-text-field
            v-model="npsn"
            :rules="npsnRules"
            type="number"
            label="Masukkan NPSN (Nomor Pokok Sekolah Nasional)"
          ></v-text-field>
          <!-- <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
          ></v-text-field>
          <v-text-field
            v-model="dob"
            :rules="dobRules"
            type="date"
            label="Tanggal Lahir"
          ></v-text-field> -->
          <div class="text-xs-center">
            <v-btn
              color="primary"
              :disabled="!valid || isSending"
              @click="submit"
            >
              Cek Data
              <v-icon right dark>mdi-lock-open</v-icon>
            </v-btn>
          </div>
        </v-form>
      </v-container>
    </v-card>

    <v-card v-if="studentBio == 'true'">
      <v-toolbar dark color="primary">
        <v-toolbar-title>Biodata Siswa</v-toolbar-title>
      </v-toolbar>

      <v-divider></v-divider>

      <v-container fluid>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row align="center">
            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="name"
                :rules="Rules"
                type="text"
                label="Nama Lengkap"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="sex"
                :rules="Rules"
                :items="sexItems"
                item-value="id"
                item-text="name"
                label="Jenis Kelamin"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="nisn"
                :rules="Rules"
                type="number"
                label="NISN"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="nis"
                :rules="Rules"
                type="text"
                label="NIS"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="noijazah"
                :rules="Rules"
                type="text"
                label="Nomor Seri Ijazah"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="skhun"
                :rules="Rules"
                type="text"
                label="Nomor Seri SKHUN"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="noun"
                :rules="Rules"
                type="text"
                label="Nomor Ujian Nasional"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="nik"
                :rules="Rules"
                type="number"
                label="No. Induk Kependudukan (NIK)"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="nokk"
                :rules="Rules"
                type="text"
                label="No. Kartu Keluarga (KK)"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="npsnDB"
                :rules="Rules"
                type="text"
                label="NPSN Sekolah Asal"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="jenjang"
                :rules="Rules"
                :items="jenjangItems"
                item-value="bentuk_pendidikan_id"
                item-text="nama"
                label="Jenjang Pendidikan Sebelumnya"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="email"
                :rules="Rules"
                label="Email Pribadi"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="blood"
                :rules="Rules"
                :items="bloodItems"
                item-value="id"
                item-text="name"
                label="Golongan Darah"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="childno"
                :rules="Rules"
                type="text"
                label="Anak yang ke"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="birthplace"
                :rules="Rules"
                type="text"
                label="Tempat Lahir"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="dob"
                :rules="Rules"
                type="date"
                label="Tanggal Lahir"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="religion"
                :rules="Rules"
                :items="religionItems"
                item-value="id"
                item-text="name"
                label="Agama"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="citizen"
                :rules="numberRules"
                :items="citizenItems"
                item-value="id"
                item-text="name"
                label="Kewarganegaraan"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="address"
                :rules="Rules"
                type="text"
                label="Alamat Tempat Tinggal"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="dusun"
                :rules="Rules"
                type="text"
                label="Dusun"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="rt"
                :rules="Rules"
                type="text"
                label="RT"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="rw"
                :rules="Rules"
                type="text"
                label="RW"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="province"
                :rules="Rules"
                :items="provinceItems"
                item-value="id"
                item-text="name"
                label="Provinsi"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="city"
                :rules="Rules"
                :items="cityItems"
                item-value="kode_wilayah"
                item-text="name"
                label="Kota/Kab"
                v-on:change="kabkotChange"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="district"
                :rules="Rules"
                :items="districtItems"
                item-value="kode_wilayah"
                item-text="name"
                label="Kecamatan"
                v-on:change="kecChange"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="subdistrict"
                :rules="Rules"
                :items="subdistrictItems"
                item-value="kode_wilayah"
                item-text="name"
                label="Desa/Kelurahan"
                v-on:change="kelChange"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="box"
                :rules="Rules"
                type="text"
                label="Kode POS"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="transport"
                :rules="Rules"
                :items="transportItems"
                item-value="id"
                item-text="name"
                label="Alat Transportasi ke Sekolah"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="homestatus"
                :rules="Rules"
                :items="homestatusItems"
                item-value="id"
                item-text="name"
                label="Jenis Tinggal"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="phone"
                :rules="Rules"
                type="text"
                label="No. Telepon Rumah"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="hp"
                :rules="Rules"
                type="text"
                label="Nomor HP"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="specialneed"
                :rules="Rules"
                :items="specialneedItems"
                item-value="id"
                item-text="name"
                label="Berkebutuhan Khusus"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="kks"
                :rules="numberRules"
                :items="booleanItems"
                item-value="id"
                item-text="name"
                label="Apakah Penerima KKS (Kartu Keluarga Sejahtera) ?"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="kps"
                :rules="numberRules"
                :items="booleanItems"
                item-value="id"
                item-text="name"
                label="Apakah Penerima KPS (Kartu Perlindungan Sosial) ?"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="pip"
                :rules="Rules"
                :items="booleanPIPItems"
                item-value="id"
                item-text="name"
                label="Usulan dari sekolah Layak PIP (Program Indonesia Pintar) ?"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="kip"
                :rules="numberRules"
                :items="booleanItems"
                item-value="id"
                item-text="name"
                label="Apakah Penerima KIP (Kartu Indonesia Pintar) ?"
                v-on:change="kipChange"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="kipstatus"
                :rules="numberRules"
                :items="deactiveItems"
                item-value="id"
                item-text="name"
                label="Status KIP"
              ></v-select>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="kipno"
                :rules="Rules"
                label="Nomor KIP (Kartu Indonesia Pintar)"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="lot"
                :rules="Rules"
                type="text"
                label="Garis Lintang"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="lang"
                :rules="Rules"
                type="text"
                label="Garis Bujur"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                v-model="noakta"
                :rules="Rules"
                type="text"
                label="No. Registrasi Akta Lahir"
                required
              ></v-text-field>
            </v-col>

            <!-- <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="admission"
                :rules="Rules"
                :items="admissionItems"
                item-value="id"
                item-text="NAME"
                label="Jalur Penerimaan"
                multiple
              ></v-select>
            </v-col> -->

            <v-col class="d-flex" cols="6" sm="3">
              <v-img
                :src="baseUrl + urlFoto + nisn"
                style="width: 30px"
              ></v-img>
            </v-col>

            <v-col class="d-flex" cols="12" sm="12">
              Upload Foto (max. 2 MB)
              <input
                style="margin-left: 50px"
                type="file"
                id="file"
                ref="file"
                accept="image/*"
                v-on:change="handleFileUpload()"
              />
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <div class="text-xs-center">
                <v-btn @click="clear">
                  Reset
                  <v-icon right dark>mdi-lock-reset</v-icon> </v-btn
                >&nbsp;
                <v-btn
                  color="success"
                  :disabled="!valid || isSending"
                  @click="simpan"
                >
                  Simpan
                  <v-icon right dark>mdi-content-save</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>

    <!-- LOADER -->
    <v-dialog v-model="dialogLoading" persistent width="300">
      <v-card>
        <v-card-text>
          Sedang mengambil data...
          <v-progress-linear
            indeterminate
            color="primary"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Biodata",
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_URL,
      urlFoto: "/api/getPhoto/",
      dialogLoading: false,
      valid: true,
      isSending: false,
      studentBio: localStorage.getItem("studentBio"),
      npsn: localStorage.getItem("temp"),
      npsnRules: [(v) => !!v || "NPSN wajib di isi"],

      // admissionItems: [{ id: null, NAME: "" }],
      sexItems: [
        { id: "M", name: "Laki-Laki" },
        { id: "F", name: "Perempuan" },
      ],
      religionItems: [
        { id: "1", name: "Islam" },
        { id: "2", name: "Kristen Protestan" },
        { id: "3", name: "Kristen Katolik" },
        { id: "4", name: "Hindu" },
        { id: "5", name: "Budha" },
        { id: "6", name: "Konghucu" },
      ],
      specialneedItems: [
        { id: "1", name: "Tidak" },
        { id: "2", name: "Netra" },
        { id: "3", name: "Rungu" },
      ],
      homestatusItems: [
        { id: "1", name: "Bersama Orang Tua" },
        { id: "2", name: "Wali" },
        { id: "3", name: "Kos" },
      ],
      transportItems: [
        { id: "1", name: "Jalan Kaki" },
        { id: "2", name: "Kendaraan Pribadi" },
        { id: "3", name: "Kendaraan Umum" },
      ],
      bloodItems: [
        { id: "O", name: "O" },
        { id: "A", name: "A" },
        { id: "B", name: "B" },
        { id: "AB", name: "AB" },
      ],
      booleanItems: [
        { id: 1, name: "Ya" },
        { id: 0, name: "Tidak" },
      ],
      booleanPIPItems: [
        { id: "1", name: "Ya" },
        { id: "0", name: "Tidak" },
      ],
      deactiveItems: [
        { id: 1, name: "Aktif" },
        { id: 0, name: "Tidak" },
      ],
      citizenItems: [
        { id: 1, name: "WNI" },
        { id: 0, name: "WNA" },
      ],
      provinceItems: [{ id: 300000, name: "Gorontalo" }],
      cityItems: [{ kode_wilayah: "", name: "" }],
      districtItems: [{ kode_wilayah: "", name: "" }],
      subdistrictItems: [{ kode_wilayah: "", name: "" }],
      jenjangItems: [{ bentuk_pendidikan_id: "", nama: "" }],
      email: "",
      nisn: "",
      nis: "",
      npsnDB: "",
      nik: "",
      nokk: "",
      noakta: "",
      // admission: [],
      name: "",
      birthplace: "",
      dob: "",
      sex: "",
      religion: "",
      address: "",
      rt: "",
      rw: "",
      dusun: "",
      province: "",
      city: "",
      district: "",
      subdistrict: "",
      box: "",
      lang: "",
      lot: "",
      phone: "",
      hp: "",
      photo: "",
      blood: "",
      kip: "",
      kipstatus: "",
      kipno: "",
      citizen: "",
      specialneed: "",
      homestatus: "",
      transport: "",
      childno: "",
      noijazah: "",
      skhun: "",
      noun: "",
      kks: "",
      kps: "",
      pip: "",
      jenjang: "",

      Rules: [(v) => !!v || "Form ini wajib di isi"],
      numberRules: [(v) => v == 1 || v == 0 || "Form ini wajib di isi"],
    };
  },
  watch: {
    dialogLoading(val) {
      if (!val) return;

      setTimeout(() => (this.dialogLoading = false), 2000);
    },
  },

  created() {
    this.initialize();
  },

  computed: {
    isHome() {
      return this.$route.path === "/";
    },
    ...mapGetters({
      countCart: "cart/count",
      guest: "auth/guest",
      user: "auth/user",
      dialogStatus: "dialog/status",
      currentComponent: "dialog/component",
    }),
    dialog: {
      get() {
        return this.dialogStatus;
      },
      set(value) {
        this.setDialogStatus(value);
      },
    },
  },
  methods: {
    ...mapActions({
      setDialogStatus: "dialog/setStatus",
      setDialogComponent: "dialog/setComponent",
      setAuth: "auth/set", // <=
      setAlert: "alert/set", // <=
    }),

    initialize() {
      this.getBioSiswa();
      this.getKabkot();
      this.getKec();
      this.getJenjang();
      // this.getJalur();
      // this.dialogLoading = true;
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.dialogLoading = true;
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.token,
          },
        };
        let formData = {
          // nisn: this.nisn,
          npsn: this.npsn,
          // email: this.email,
          // dob: this.dob,
        };
        this.isSending = true;
        this.axios
          .post("/api/getBioSiswa", formData, config)
          .then((response) => {
            this.isSending = true;
            this.dialogLoading = false;
            //let { data } = response.data[0];
            // console.log(response.data[0].peserta_didik_id);
            //console.log(data);
            localStorage.peserta_didik_id =
              response.data.data[0].peserta_didik_id;
            localStorage.sekolah_id = response.data.data[0].sekolah_id;
            localStorage.kode_wilayah = response.data.data[0].kode_wilayah;
            localStorage.nama = response.data.data[0].nama;
            localStorage.tempat_lahir = response.data.data[0].tempat_lahir;
            localStorage.tanggal_lahir = response.data.data[0].tanggal_lahir;
            localStorage.jenis_kelamin = response.data.data[0].jenis_kelamin;
            localStorage.nik = response.data.data[0].nik;
            localStorage.nisn = response.data.data[0].nisn;
            localStorage.alamat_jalan = response.data.data[0].alamat_jalan;
            localStorage.desa_kelurahan = response.data.data[0].desa_kelurahan;
            localStorage.rt = response.data.data[0].rt;
            localStorage.rw = response.data.data[0].rw;
            localStorage.nama_dusun = response.data.data[0].nama_dusun;
            localStorage.nama_ibu_kandung =
              response.data.data[0].nama_ibu_kandung;
            localStorage.pekerjaan_ibu_kandung =
              response.data.data[0].pekerjaan_ibu_kandung;
            localStorage.penghasilan_ibu_kandung =
              response.data.data[0].penghasilan_ibu_kandung;
            localStorage.nama_ayah = response.data.data[0].nama_ayah;
            localStorage.pekerjaan_ayah = response.data.data[0].pekerjaan_ayah;
            localStorage.penghasilan_ayah =
              response.data.data[0].penghasilan_ayah;
            localStorage.nama_wali = response.data.data[0].nama_wali;
            localStorage.pekerjaan_wali = response.data.data[0].pekerjaan_wali;
            localStorage.penghasilan_wali =
              response.data.data[0].penghasilan_wali;
            localStorage.kebutuhan_khusus =
              response.data.data[0].kebutuhan_khusus;
            localStorage.no_kip = response.data.data[0].no_kip;
            localStorage.no_pkh = response.data.data[0].no_pkh;
            localStorage.lintang = response.data.data[0].lintang;
            localStorage.bujur = response.data.data[0].bujur;
            localStorage.aktif = response.data.data[0].aktif;
            localStorage.tingkat_pendidikan =
              response.data.data[0].tingkat_pendidikan;
            localStorage.npsn = this.npsn;
            localStorage.email = this.user.email;
            localStorage.nisn = this.user.username;
            this.setAlert({
              status: true,
              color: "success",
              text: "Cek data berhasil",
            });
            this.close();
            this.$router.replace({ name: "register" });
          })
          .catch((error) => {
            this.isSending = false;
            this.dialogLoading = false;
            // let { data } = error.response;
            console.log(error);
            // var pesan = "";
            // if (data.message === "The given data was invalid.") {
            //   pesan = "Data Siswa Sudah Terdaftar, Silahkan Melakukan Login.";
            // } else {
            //   pesan =
            //     "Data Siswa Tidak ditemukan / tidak tercatat pada sistem dapodik sebagai siswa tingkat akhir";
            // }
            //console.log(data.errors.notfound);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response,
            });
          });
      }
    },

    simpan() {
      if (this.$refs.form.validate()) {
        this.isSending = true;
        this.dialogLoading = true;
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.token,
          },
        };
        let formData = new FormData();
        // formData.set("EMAIL", this.email);
        // formData.set("NISN", this.nisn);
        formData.set("NIS", this.nis);
        formData.set("NPSN", this.npsnDB);
        formData.set("NIK", this.nik);
        formData.set("NOKK", this.nokk);
        formData.set("NOAKTA", this.noakta);
        // formData.set("ADMISSION", this.admission);
        formData.set("NAME", this.name);
        formData.set("BIRTHPLACE", this.birthplace);
        formData.set("DOB", this.dob);
        formData.set("SEX", this.sex);
        formData.set("RELIGION", this.religion);
        formData.set("ADDRESS", this.address);
        formData.set("RT", this.rt);
        formData.set("RW", this.rw);
        formData.set("DUSUN", this.dusun);
        formData.set("PROVINCE", this.province);
        formData.set("CITY", this.city);
        formData.set("DISTRICT", this.district);
        formData.set("SUBDISTRICT", this.subdistrict);
        formData.set("BOX", this.box);
        formData.set("LANG", this.lang);
        formData.set("LOT", this.lot);
        formData.set("PHONE", this.phone);
        formData.set("HP", this.hp);
        formData.set("PHOTO", this.file);
        formData.set("BLOOD", this.blood);
        formData.set("KIP", this.kip);
        formData.set("KIPSTATUS", this.kipstatus);
        formData.set("KIPNO", this.kipno);
        formData.set("CITIZEN", this.citizen);
        formData.set("SPECIALNEED", this.specialneed);
        formData.set("HOMESTATUS", this.homestatus);
        formData.set("TRANSPORT", this.transport);
        formData.set("CHILDNO", this.childno);
        formData.set("NOIJAZAH", this.noijazah);
        formData.set("SKHUN", this.skhun);
        formData.set("NOUN", this.noun);
        formData.set("KKS", this.kks);
        formData.set("KPS", this.kps);
        formData.set("PIP", this.pip);
        formData.set("LEVEL", this.jenjang);
        console.log(formData.getAll("PHOTO"));
        this.axios
          .post("/api/saveBio", formData, config)
          .then((response) => {
            this.isSending = false;
            this.dialogLoading = false;
            // let { data } = response.data;
            //this.setAuth(data);
            console.log(response.data);
            this.setAlert({
              status: true,
              color: "success",
              text: "Berhasil Mengupdate Biodata",
            });
            this.close();
            this.getBioSiswa();
          })
          .catch((error) => {
            this.isSending = false;
            this.dialogLoading = false;
            let responses = error;
            //console.log(responses.data.errors);
            //let { data } = error.response;
            this.setAlert({
              status: true,
              color: "error",
              // text: responses.data.message,
              text: responses,
            });
          });
      }
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },

    close() {
      this.$emit("closed", false);
    },

    clear() {
      this.getBioSiswa();
    },

    getBioSiswa() {
      this.dialogLoading = true;
      // console.log("getKabkot");
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post("/api/getBioSiswa", {}, config)
        .then((response) => {
          this.dialogLoading = false;
          // console.log(response.data.NISN);
          // console.log(response.data.data);
          this.email = this.user.email;
          this.nisn = this.user.username;
          this.nis = response.data.data.NIS;
          this.npsnDB = response.data.data.NPSN;
          this.nik = response.data.data.NIK;
          this.nokk = response.data.data.NOKK;
          this.noakta = response.data.data.NOAKTA;
          this.name = response.data.data.NAME;
          this.birthplace = response.data.data.BIRTHPLACE;
          this.dob = response.data.data.DOB;
          this.sex = response.data.data.SEX;
          this.religion = response.data.data.RELIGION;
          this.address = response.data.data.ADDRESS;
          this.rt = response.data.data.RT;
          this.rw = response.data.data.RW;
          this.dusun = response.data.data.DUSUN;
          this.province = response.data.data.PROVINCE;
          this.city = response.data.data.CITY + "  ";
          this.district = response.data.data.DISTRICT + "  ";
          this.subdistrict = response.data.data.SUBDISTRICT;
          this.box = response.data.data.BOX;
          this.lang = response.data.data.LANG;
          this.lot = response.data.data.LOT;
          this.phone = response.data.data.PHONE;
          this.hp = response.data.data.HP;
          this.photo = response.data.data.PHOTO;
          this.blood = response.data.data.BLOOD;
          this.kip = response.data.data.KIP;
          this.kipstatus = response.data.data.KIPSTATUS;
          this.kipno = response.data.data.KIPNO;
          this.citizen = response.data.data.CITIZEN;
          this.specialneed = response.data.data.SPECIALNEED;
          this.homestatus = response.data.data.HOMESTATUS;
          this.transport = response.data.data.TRANSPORT;
          this.childno = response.data.data.CHILDNO;
          this.noijazah = response.data.data.NOIJAZAH;
          this.skhun = response.data.data.SKHUN;
          this.noun = response.data.data.NOUN;
          this.kks = response.data.data.KKS;
          this.kps = response.data.data.KPS;
          this.pip = response.data.data.PIP;
          this.jenjang = response.data.data.LEVEL;

          // var data = response.data.data.ADMISSION;
          // this.admission = Array.from(data.split(","), Number);

          this.getKec(response.data.data.CITY);
          this.getKel(response.data.data.DISTRICT);
          // console.log(this.kabkotKode);
          // console.log(this.kecamatanKode);
        })
        .catch((error) => {
          this.dialogLoading = false;
          console.log("error" + error);
        });
    },

    getJenjang() {
      // console.log("getKabkot");
      this.dialogLoading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post("/api/jenjang", {}, config)
        .then((response) => {
          this.dialogLoading = false;
          // console.log(response.data);
          this.jenjangItems = response.data;
        })
        .catch((error) => {
          this.dialogLoading = false;
          console.log("error" + error);
        });
    },

    // getJalur() {
    //   // console.log("getKabkot");
    //   let config = {
    //     headers: {
    //       Authorization: "Bearer " + this.user.token,
    //     },
    //   };
    //   this.axios
    //     .post("/api/activeAdmission", {}, config)
    //     .then((response) => {
    //       // console.log(response.data);
    //       this.admissionItems = response.data.data;
    //     })
    //     .catch((error) => {
    //       console.log("error" + error);
    //     });
    // },

    kipChange: function (e) {
      // console.log("kip", e);
      if (e == 0) {
        this.kipno = "0";
        this.kipstatus = 0;
      }
    },

    kabkotChange: function (e) {
      // console.log("kode kabkot", e);
      this.kabkotKode = e;
      this.getKec(e);
      // localStorage.kabkot = kabkot;
    },

    kecChange: function (e) {
      // console.log("kode kecamatan", e);
      this.kecamatanKode = e;
      this.getKel(e);
    },

    kelChange: function (e) {
      console.log("kel", e);
    },

    getKabkot() {
      // console.log("getKabkot");
      this.dialogLoading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post("/api/getWilayah", {}, config)
        .then((response) => {
          this.dialogLoading = false;
          // console.log(response.data);
          this.cityItems = response.data;
        })
        .catch((error) => {
          this.dialogLoading = false;
          console.log("error" + error);
        });
    },

    getKec(kabkotKode) {
      // console.log("getKec");
      this.dialogLoading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("kode_wilayah", kabkotKode);
      // console.log(formData.getAll("kode_wilayah"));
      this.axios
        .post("/api/getWilayah", formData, config)
        .then((response) => {
          this.dialogLoading = false;
          // console.log("kecamatan", response.data);
          this.districtItems = response.data;
          // console.log(this.districtItems)
        })
        .catch((error) => {
          this.dialogLoading = false;
          console.log("error" + error);
        });
    },

    getKel(kecKode) {
      // console.log("getKec");
      this.dialogLoading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = {
        kode_wilayah: kecKode,
      };
      this.axios
        .post("/api/getWilayah", formData, config)
        .then((response) => {
          this.dialogLoading = false;
          // console.log("kelurahan", response.data);
          this.subdistrictItems = response.data;
        })
        .catch((error) => {
          this.dialogLoading = false;
          console.log("error" + error);
        });
    },
  },
};
</script>