<template>
  <div>
    <v-container class="ma-0 pa-1" grid-list-sm>
      <div class="text-left">
        <v-btn small text to="/regis-ulang" class="blue--text">
          <v-icon>mdi-chevron-left</v-icon> Kembali
        </v-btn>
      </div>
      <!-- <v-alert
        color="info"
        dark
        prominent
        width="100%"
      >
        Silahkan upload ulang foto di menu Biodata jika foto tidak tampil.
      </v-alert> -->
    </v-container>
    <div id="print">
      <center>
        <table
          border="0"
          width="100%"
          style="
            border-collapse: collapse;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 11px;
          "
          align="center"
          cellpadding="5px"
        >
          <tr>
            <td style="padding: 10px; width: 15%" align="center">
              <img height="60px" width="60px" src="/img/bg/kota.png" />
            </td>
            <td style="font-size: 12px">
              <strong>PANITIA PENERIMAAN PESERTA DIDIK BARU</strong><br />
              SEKOLAH DASAR – SEKOLAH MENENGAH PERTAMA<br />
              <strong>KOTA GORONTALO TP. 2024 – 2025</strong>
            </td>
            <td style="padding-right: 5px; color: gray" align="right">
              <small
                >Dokumen ini <br />resmi dicetak melalui<br />
                https://ppdb.gorontalokota.go.id</small
              >
            </td>
          </tr>
        </table>

        <table
          border="1"
          width="100%"
          style="
            border-collapse: collapse;
            border-color: black;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 11px;
          "
          align="center"
          cellpadding="5px"
        >
          <tr>
            <th style="font-size: 12px; padding: 5px">BUKTI DITERIMA</th>
          </tr>
        </table>

        <table
          border="1"
          width="100%"
          style="
            border-collapse: collapse;
            margin-top: 5px;
            border-color: black;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 11px;
          "
          align="center"
          cellpadding="5px"
        >
          <tr>
            <td colspan="2" style="padding: 5px">
              <strong>Info Verifikasi Pendaftaran</strong>
            </td>
          </tr>
          <tr>
            <td style="padding: 5px; background-color: #dddddd" width="30%">
              Nomor Pendaftaran
            </td>
            <td style="padding: 5px">{{ peserta.REGNUMBER }}</td>
          </tr>
          <!-- <tr>
            <td style="padding: 5px; background-color: #dddddd">Jalur Masuk</td>
            <td style="padding: 5px">{{ admissionNAME }}</td>
          </tr> -->
        </table>

        <table
          border="1"
          width="100%"
          style="
            border-collapse: collapse;
            margin-top: 5px;
            border-color: black;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 11px;
          "
          align="center"
          cellpadding="5px"
        >
          <tr>
            <td colspan="3" style="padding: 5px">
              <strong>Biodata Siswa</strong>
            </td>
          </tr>
          <tr>
            <td style="padding: 5px; background-color: #dddddd" width="30%">
              NISN
            </td>
            <td style="padding: 5px">{{ peserta.student[0].NISN }}</td>
            <td rowspan="6" width="20%" align="center">
              <img
                :src="baseUrl + urlFoto + nisnDetail"
                width="150"
                class="grey darken-4"
              />
            </td>
          </tr>
          <tr>
            <td style="padding: 5px; background-color: #dddddd">
              Nama Lengkap
            </td>
            <td style="padding: 5px">{{ peserta.student[0].NAME }}</td>
          </tr>
          <tr>
            <td style="padding: 5px; background-color: #dddddd">
              Jenis Kelamin
            </td>
            <td style="padding: 5px">
              <span v-if="peserta.student[0].SEX == 'F'"> Perempuan </span>
              <span v-else> Laki-laki </span>
            </td>
          </tr>
          <tr>
            <td style="padding: 5px; background-color: #dddddd">
              Tempat & Tgl Lahir
            </td>
            <td style="padding: 5px">
              {{ peserta.student[0].BIRTHPLACE }}, {{ peserta.student[0].DOB }}
            </td>
          </tr>
          <tr>
            <td style="padding: 5px; background-color: #dddddd">Email</td>
            <td style="padding: 5px">{{ this.user.email }}</td>
          </tr>
          <tr>
            <td style="padding: 5px; background-color: #dddddd">Alamat</td>
            <td style="padding: 5px">{{ peserta.student[0].ADDRESS }}</td>
          </tr>
        </table>

        <table
          border="1"
          width="100%"
          style="
            border-collapse: collapse;
            margin-top: 5px;
            border-color: black;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 11px;
          "
          align="center"
          cellpadding="5px"
        >
          <tr>
            <th style="padding: 10px">
              Dinyatakan diterima di <strong>{{ schoolAccept }}</strong>
            </th>
            <!-- <td style="padding: 5px 5px 5px 15px">
              <strong>Untuk diperhatikan :</strong><br>
              <ol>
                <li>asdasd</li>
                <li>asdasd</li>
                <li>asdasd</li>
              </ol>
            </td> -->
          </tr>
        </table>

        <!-- <table
          border="1"
          width="100%"
          style="
            border-collapse: collapse;
            margin-top: 5px;
            border-color: black;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 11px;
          "
          align="center"
          cellpadding="5px"
        >
          <tr>
            <td width="10%"></td>
            <td style="padding: 5px" align="center" width="20%">
              Menyetujui data diatas,<br />
              <strong>Kepala Sekolah {{ schoolAccept }}</strong>
              <br /><br /><br /><br /><br /><br />
              <strong>{{ headmaster }}</strong>
            </td>
            <td width="10%"></td>
            <td style="padding: 5px" align="center" width="20%">
              a/n <strong>{{ peserta.student[0].NAME }}</strong
              ><br />
              Menyetujui data diatas,<br />
              <strong>Ortu / Wali Siswa terdaftar **)</strong>
              <br /><br /><br /><br /><br /><br />
              (.................................)
            </td>
            <td width="10%"></td>
            <td style="padding: 5px" align="left" width="20%">
              <div align="right">
                Kota Gorontalo, {{ new Date().getDate() }}/{{
                  new Date().getMonth() + 1
                }}/{{ new Date().getFullYear() }}
              </div>
              Menyetujui data diatas,<br />
              <strong>Siswa terdaftar</strong>
              <br /><br /><br /><br /><br /><br />
              <strong>{{ peserta.student[0].NAME }}</strong>
            </td>
            <td width="10%"></td>
          </tr>
        </table> -->
      </center>
    </div>
    <br />
    <center>
      <v-btn medium color="success" dark @click="konfirmasi">
        <v-icon left>mdi-account-check</v-icon>
        Konfirmasi Peserta Didik
      </v-btn>
    </center>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
export default {
  name: "BuktiPendaftaran",
  data: () => ({
    baseUrl: process.env.VUE_APP_API_URL,
    urlFoto: "/api/getPhoto/",
    nisnDetail: "",
    peserta: [],
    noreg: localStorage.getItem("noreg"),
    USERID: localStorage.getItem("USERID"),
    email: localStorage.getItem("email"),
    schoolAccept: "",
    headmaster: "",
  }),
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
    }),

    initialize() {
      this.getPendaftaran();
    },

    getPendaftaran() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("noreg", this.noreg);
      this.axios
        .post("/api/registrationCheck", formData, config)
        .then((response) => {
          this.$vloading.hide();
          let data = response.data.data;
          //   item.samples = data;
          this.peserta = data;
          console.log(data);

          // var acceptData = response.data.data.student[0].school_selected;
          // var newAcceptData = acceptData.filter((e) => e.ACCEPTED == 1);
          this.schoolAccept =
            response.data.data.student[0].school_selected[0].school_data.NAME;
          this.headmaster =
            response.data.data.student[0].school_selected[0].school_data.HEADMASTER;
          // console.log(newAcceptData);

          this.nisnDetail = data.student[0].NISN;
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data,
          });
          this.close();
        });
    },

    konfirmasi() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("noreg", this.noreg);
      this.axios
        .post("/api/registrationSave", formData, config)
        .then(() => {
          this.$router.replace({ name: "Regis Ulang" });
          // this.$vloading.hide();
          // let { data } = response.data;
          //this.setAuth(data);
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Registrasi ulang berhasil.",
          });
          this.close();
          // this.getRegistrationList();
          // localStorage.noreg = this.noreg;
        })
        .catch((error) => {
          this.$vloading.hide();
          // let responses = error;
          // console.log(responses);
          // let { data } = error.response;
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    // makePDF() {
    //   var w = document.getElementById("print").offsetWidth;
    //   var h = document.getElementById("print").offsetHeight;
    //   html2canvas(document.getElementById("print"), {
    //     dpi: 300, // Set to 300 DPI
    //     scale: 3, // Adjusts your resolution
    //     onrendered: function () {
    //       var doc = new jsPDF("p", "px", [w, h]);
    //       doc.save("sample-file.pdf");
    //     },
    //   });
    // },
  },
};
</script>

<style scoped>
/* #print {
  font-family: "Arial";
  font-size: 11px;
  margin-top: 10px;
  margin-left: 15px;
  width: 100%;
} */
</style>
